import {
  Component, OnInit, Inject, OnDestroy, HostListener,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription, fromEvent } from 'rxjs';
import { KeyCode } from 'src/app/core/enums/keycode';

@Component({
  selector: 'app-dialog-ok-cancel',
  templateUrl: './dialog-ok-cancel.component.html',
  styleUrls: ['./dialog-ok-cancel.component.scss'],
})
export class DialogOkCancelComponent implements OnInit, OnDestroy {
  public justOkAction: boolean = false;

  subscription = Subscription.EMPTY;

  buttonOk = 'OK';

  buttonCancel = 'Cancel';

  constructor(
    public dialogRef: MatDialogRef<DialogOkCancelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataDialogOkCancel,
  ) {
    data.justOkAction && (this.justOkAction = data.justOkAction);
    if (data.textButtonOK) this.buttonOk = data.textButtonOK;
    if (data.textButtonCancel) this.buttonCancel = data.textButtonCancel;
  }

  ngOnInit() {
    this.subscription = fromEvent(document, 'keyup').subscribe((event: KeyboardEvent) => {
      if (event.key === KeyCode.escape) {
        event.stopPropagation();
        this.subscription.unsubscribe();
      } else if (event.key === KeyCode.enter) {
        event.stopPropagation();
        this.subscription.unsubscribe();
      } else if (event.key === KeyCode.delete) { // #5078
        event.stopPropagation();
      }
    });
    this.subscription = fromEvent(document, 'keydown').subscribe((event: KeyboardEvent) => {
      if (event.key === KeyCode.delete) { // #5078
        event.stopPropagation();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('keydown', ['$event'])
  keydown(event) {
    if (event.key === KeyCode.escape) {
      this.dialogRef.close();
    }
  }
}

export interface DataDialogOkCancel {
  textButtonOK?: string;
  textButtonCancel?: string;
  title?: string;
  message: string;
  subMessage?: string;
  justOkAction?: boolean;
}
