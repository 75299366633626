/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ModeChangeDetection } from 'src/app/core/common/main-viewer-common';
import { OnDestroyService } from 'src/app/core/interfaces/interfaces';
import { UtilExtend, LogType, LogTypeClass } from 'src/app/core/utils/util-extend';

@Injectable({
  providedIn: 'root',
})
export class ChangeDetectionService implements OnDestroyService {
  public eventChangeDetection$ = new Subject<ModeChangeDetection>();

  constructor() {
    UtilExtend.Log(LogType.Init, 'change detection', LogTypeClass.Service);
  }

  emitChangeDetection(value: ModeChangeDetection) {
    if (value) {
      this.eventChangeDetection$.next(value);
    }
  }

  onDestroyService(): void {
    this.eventChangeDetection$ = null;
  }
}
