// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
  height: 100%;
}

.overlay-dialog {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  background: transparent;
  justify-content: center;
  align-items: center;
  z-index: 13;
  padding: 15px;
}
.overlay-dialog .mainDialog {
  z-index: 14;
  background-color: white;
  width: 20rem;
  height: 10rem;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
}
.overlay-dialog .mainDialog .title-dialog {
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.overlay-dialog .mainDialog .actions-dialog {
  padding-top: 15px;
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.overlay-dialog .mainDialog .actions-dialog .cancel-div, .overlay-dialog .mainDialog .actions-dialog .ok-div {
  width: 45%;
}
.overlay-dialog .mainDialog .actions-dialog .cancel-div button, .overlay-dialog .mainDialog .actions-dialog .ok-div button {
  width: 100%;
}
.overlay-dialog .mainDialog .actions-dialog .cancel-div {
  margin-right: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/dialog/dialog-input-password/dialog-input-password.component.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,YAAA;AAAF;;AAGA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,aAAA;AAAF;AACE;EACI,WAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,kBAAA;EACA,sBAAA;AACN;AAAM;EACI,WAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AAEV;AAAM;EACE,iBAAA;EACE,WAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAEV;AADU;EACI,UAAA;AAGd;AAFc;EACI,WAAA;AAIlB;AADU;EACI,kBAAA;AAGd","sourcesContent":["\r\n:host {\r\n  width: 100%;\r\n  height: 100%;\r\n}\r\n\r\n.overlay-dialog {\r\n  position: absolute;\r\n  left: 0px;\r\n  top: 0px;\r\n  width: 100%;\r\n  height: 100%;\r\n  display: flex;\r\n  background: transparent;\r\n  justify-content: center;\r\n  align-items: center;\r\n  z-index: 13;\r\n  padding: 15px;\r\n  .mainDialog {\r\n      z-index: 14;\r\n      background-color: rgba($color: #ffffff, $alpha: 1.0);\r\n      width: 20rem;\r\n      height: 10rem;\r\n      display: flex;\r\n      border-radius: 4px;\r\n      flex-direction: column;\r\n      .title-dialog {\r\n          width: 100%;\r\n          height: 60%;\r\n          display: flex;\r\n          justify-content: center;\r\n          align-items: center;\r\n          text-align: center;\r\n      }\r\n      .actions-dialog {\r\n        padding-top: 15px;\r\n          width: 100%;\r\n          height: 40%;\r\n          display: flex;\r\n          justify-content: center;\r\n          align-items: center;\r\n          .cancel-div, .ok-div {\r\n              width: 45%;\r\n              button {\r\n                  width: 100%;\r\n              }\r\n          }\r\n          .cancel-div {\r\n              margin-right: 1rem;\r\n          }\r\n      }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
