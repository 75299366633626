/* eslint-disable import/no-extraneous-dependencies */
import {
  CustomBookmark, DataObjectEntity, PrintParam, OpenType, Point, Size, ViewProperty, Rectangle,
} from 'src/app/shared/class/global';
import { MarkupEntity } from 'src/app/core/common/markups';
import { BehaviorSubject, Subject } from 'rxjs';
import { cloneDeep } from 'lodash';
import Util from 'src/app/core/utils/util';
import { WaterMarkConfig } from 'src/app/core/common/permission';
import { AnnotRect, PdfDoc } from 'src/@pdf_typings/foxit';
import { PdfviewerComponent } from '../pdfviewer/pdfviewer.component';
import { PdfCommunicator } from './PdfCommunicator';
import { MathHelper } from './MathHelper';

export class PdfViewerManager {
  public static mapViewer = new Map<string, PdfviewerComponent>();

  public static currentActiveViewer: PdfviewerComponent;

  public static waterMarkConfig$ = new Subject<{ fileViewId: string, watermark: WaterMarkConfig }>();

  public static setViewer(viewId: string, data: PdfviewerComponent) {
    PdfViewerManager.mapViewer.set(viewId, data);
  }

  public static getViewer(viewId: string) {
    return PdfViewerManager.mapViewer.get(viewId);
  }

  public static getParentModelInfo(viewId: string) {
    return PdfViewerManager.getViewer(viewId)?.fileInfo;
  }

  public static redraw() {
    PdfViewerManager.mapViewer.forEach((p: PdfviewerComponent) => p?.redraw(true));
  }

  public static activeViewer(id: string) {
    PdfViewerManager.currentActiveViewer = PdfViewerManager.getViewer(id);
  }

  public static clearPDFMark() {
    PdfViewerManager.mapViewer.forEach((p: PdfviewerComponent) => {
      const searchWorker = p?.pdfController?.searchWorker;
      searchWorker && searchWorker.clearMap();
      p?.pdfController && p.pdfController.clearMark();
    });
  }

  static removeAnnotsByIds(viewId: string, srcEntities: MarkupEntity[], arr: string[]) {
    const markupManager = PdfViewerManager.getViewer(viewId)?.markupManager;
    markupManager && markupManager.removeAnnotsByIds(srcEntities, arr);
  }

  static getAnnotsActivated() {
    const { pdfController } = PdfViewerManager.currentActiveViewer;
    return pdfController?.annotsActivated().map((p) => p.id);
  }

  static deActiveMarkup(markup?: MarkupEntity) {
    const { pdfController } = PdfViewerManager.currentActiveViewer;
    const annotsActivated = pdfController.annotsActivated();
    if (pdfController) {
      if (!markup) pdfController.unActiveAnnotsActivated(annotsActivated);
      else pdfController.unActiveAnnot(+markup.uniqueViewId, markup.uniqueId);
    }
  }

  public static getMonoStatus(viewId: string) {
    const pdfComponent = PdfViewerManager.getViewer(viewId);
    return pdfComponent.isMonoChrome && pdfComponent.openType === OpenType.pdfDrawing;
  }

  public static async printPdfView(
    printParam: PrintParam,
    pdfController: PdfCommunicator = PdfViewerManager.currentActiveViewer.pdfController,
    rect?: Rectangle,
    curPage?: number,
    scale?: number,
    rotate?: any,
  ) {
    // const { pdfController } = PdfViewerManager.currentActiveViewer;
    const listDiv = await PdfViewerManager.printPdfViewExt(pdfController, printParam, rect, curPage, scale, rotate);
    return listDiv;
    // let listDiv = [];
    // if (pdfController) listDiv = await pdfController.printView(viewerId, size);
    // return listDiv;
  }

  public static async printPdfViewExt(
    pdfController: PdfCommunicator,
    printParam: PrintParam,
    rect?: Rectangle,
    curPage?: number,
    scale?: number,
    rotate?: number,
  ) {
    let listDiv = [];
    if (pdfController) listDiv = await pdfController.printView(printParam, rect, curPage, scale, rotate);
    return listDiv;
  }

  public static async getCurrentPdfViewById(viewId: string, name: string): Promise<ViewProperty> {
    const { pdfController } = PdfViewerManager.getViewer(viewId);
    const viewProperty = await pdfController?.getSaveView(name);
    return viewProperty;
  }

  public static async getCurrentBookmarkById(viewId: string, name: string): Promise<CustomBookmark> {
    const { pdfController } = PdfViewerManager.getViewer(viewId);
    const newBookmark = await pdfController.getBookmark(name);
    return cloneDeep(newBookmark);
  }

  public static async getCurrentZoomViewById(viewId: string, name: string): Promise<CustomBookmark> {
    const { pdfController } = PdfViewerManager.getViewer(viewId);
    const zoomView = await pdfController.getBookmark(name);
    return cloneDeep(zoomView);
  }

  public static gotoView(viewId: string, viewProperty: ViewProperty) {
    const { pdfController } = PdfViewerManager.getViewer(viewId);
    pdfController && pdfController.gotoView(viewProperty);
  }

  public static async gotoViewExt(pdfController, viewProperty: ViewProperty) {
    pdfController && await pdfController.gotoView(viewProperty, false);
  }

  public static destroyCurrentViewer() {
    const { pdfController } = PdfViewerManager.currentActiveViewer;
    pdfController && pdfController.destroyViewer();
  }

  public static destroyPDFById(viewId: string) {
    try {
      const pdfComponent = PdfViewerManager.getViewer(viewId);
      pdfComponent?.pdfController && pdfComponent.pdfController.destroyViewer();
    } catch (error) {
      // error
    }
  }

  public static async activeMultiMarkup(viewId: string, markups: DataObjectEntity[] | MarkupEntity[], isZoom?:boolean) {
    const pdfComponent = PdfViewerManager.getViewer(viewId);
    if (!pdfComponent) return;
    const { markupManager, pdfController } = pdfComponent;
    const { isMarkupLoaded } = markupManager;
    if (!isMarkupLoaded) await markupManager.loadMarkup();
    for (let i = 0; i < markups.length; i++) {
      const markup = markups[i];
      if (i === 0) {
        pdfComponent.pdfController.pdfViewer.isMultiSelectAnnot = false;
        // pdfController.activeAnnot(markup.originData);
        if (isZoom)pdfController.activeAnnot(markup.originData, isZoom);
        else pdfController.activeAnnot(markup.originData);
        // eslint-disable-next-line no-await-in-loop
        await Util.delay(50);
      } else {
        pdfComponent.pdfController.pdfViewer.isMultiSelectAnnot = true;
        // pdfController.activeAnnot(markup.originData);
        if (isZoom) pdfController.activeAnnot(markup.originData, isZoom);
        else pdfController.activeAnnot(markup.originData);
      }
      // Util.delay(1000).then(() => {
      //   console.log(pdfComponent.pdfController.pdfViewer.activationManager);
      // });
    }
    setTimeout(() => {
      pdfComponent.pdfController.pdfViewer.isMultiSelectAnnot = false;
    }, 200);
  }

  public static async activeMarkup(viewId: string, markup: DataObjectEntity | MarkupEntity) {
    const pdfComponent = PdfViewerManager.getViewer(viewId);
    if (!pdfComponent) return;
    const { markupManager, pdfController } = pdfComponent;
    const { isMarkupLoaded } = markupManager;
    if (!isMarkupLoaded) await markupManager.loadMarkup();
    await Util.delay(150);
    markup && pdfController.activeAnnot(markup.originData);
  }

  public static setMarkupsVisible(viewId:string, markups: MarkupEntity[], isVisible: boolean, activeAnnot = true) {
    const { pdfController } = PdfViewerManager.getViewer(viewId);
    try {
      pdfController && pdfController.setAnnotsVisibility(markups, isVisible, activeAnnot);
    } catch (error) {
      // ignore
    }
  }

  public static isChanges(viewId: string) {
    const viewer = PdfViewerManager.getViewer(viewId);
    if (viewer && viewer.pdfController) {
      return viewer.pdfController.isChanges();
    }
    return false;
  }

  public static clearAll() {
    PdfViewerManager.mapViewer.clear();
  }

  public static getAllPagesHasMarkupOfCurrentPDF() {
    const { markupManager } = PdfViewerManager.currentActiveViewer;
    return markupManager ? markupManager.getPrintAnnotData() : [];
  }

  public static getAllPagesHasMarkup(pdfViewer: PdfviewerComponent) {
    const { markupManager } = pdfViewer;
    return markupManager ? markupManager.getPrintAnnotData() : [];
  }

  public static zoomToGroup(viewId: string, pageIndex: number, markupIds: string[]) {
    const pdfComponent = PdfViewerManager.getViewer(viewId);
    if (pdfComponent) {
      const { pdfController } = pdfComponent;
      pdfController.zoomExtendToView(pageIndex, markupIds);
    }
  }

  public static async printCurrentPDF(printData: any[], size: Size) {
    const { markupManager } = PdfViewerManager.currentActiveViewer;
    let listDiv = [];
    if (markupManager) {
      if (!markupManager.isMarkupLoaded) {
        await markupManager.importAnnotFromArray(markupManager.annots);
        const pdfController = PdfViewerManager.currentActiveViewer?.pdfController;
        if (pdfController) listDiv = await pdfController.printThisPDF(printData, size);
        markupManager.isCancel = true;
        await markupManager.removeAllAnnots();
        markupManager.isCancel = false;
      } else {
        const pdfController = PdfViewerManager.currentActiveViewer?.pdfController;
        if (pdfController) listDiv = await pdfController.printThisPDF(printData, size);
      }
    }
    return listDiv;
  }

  public static redrawPdf(viewId: string, force: boolean) {
    const pdfComponent = PdfViewerManager.getViewer(viewId);
    pdfComponent && pdfComponent.redraw(force);
  }

  public static clearCurrent() {
    PdfViewerManager.currentActiveViewer = null;
  }

  public static existPdf() {
    return PdfViewerManager.mapViewer.size > 0;
  }

  public static async removeAnnotsByPage(pdfViewer: PdfviewerComponent, annots: MarkupEntity[] = null) {
    if (pdfViewer && pdfViewer.pdfController) {
      const { pdfController } = pdfViewer;
      try {
        await pdfController.removeAnnotsByPage(annots);
      } catch (error) {
        // ignore
      }
    }
  }

  public static async removeAnnotsByPageExt(pdfController, annots: MarkupEntity[] = null) {
    if (pdfController) {
      try {
        await pdfController.removeAnnotsByPage(annots);
      } catch (error) {
        // ignore
      }
    }
  }

  public static async addAnnotsByPage(pdfViewer: PdfviewerComponent, annots: MarkupEntity[]) {
    if (pdfViewer && pdfViewer.pdfController) {
      const { pdfController } = pdfViewer;
      await pdfController.addAnnotsByPage(annots);
    }
  }

  public static async addAnnotsByPageExt(pdfController: PdfCommunicator, annots: MarkupEntity[], scaleSizePrint: number = 1) {
    if (pdfController) {
      try {
        await pdfController.addAnnotsByPageExt(annots, scaleSizePrint);
      } catch (error) {
        // ignore
      }
    }
  }

  public static async addImageAnnotsByPage(pdfController: PdfCommunicator, annots: MarkupEntity[]) {
    if (pdfController) {
      try {
        await pdfController.addImageAnnotsByPage(annots);
      } catch (error) {
        // ignore
      }
    }
  }

  public static async disableEditAnnots(viewId: string, annots: MarkupEntity[]) {
    const { pdfController } = PdfViewerManager.getViewer(viewId);
    try {
      pdfController && await pdfController.disableEditAnnots(annots);
    } catch (error) {
      // ignore
    }
  }

  public static getAnnotBoundary(viewId: string, annot: any): AnnotRect {
    const pdfComponent = PdfViewerManager.getViewer(viewId);
    if (pdfComponent) {
      const annotRender = pdfComponent.pdfController.pdfViewer.getAnnotRender(+annot.uniqueViewId, annot.uniqueId);
      const { $ui } = annotRender;
      const annotHtml = $ui[0] as HTMLElement;
      const boudary = annotHtml.getBoundingClientRect();
      return {
        left: boudary.left, right: boudary.right, top: boudary.top, bottom: boudary.bottom,
      };
    }
    return null;
  }

  public static getAnnotsBoundary(viewId: string, annots: any[]): AnnotRect {
    const boudaries = annots.map((annot: any) => PdfViewerManager.getAnnotBoundary(viewId, annot));
    return MathHelper.combineRects(...boudaries);
  }

  public static getStartAnnotPoint(viewId: string, annot: any): Point {
    const pdfComponent = PdfViewerManager.getViewer(viewId);
    const annotRender = pdfComponent.pdfController.pdfViewer.getAnnotRender(annot.page, annot.name);
    const annotDiv = annotRender.$ui[0] as HTMLDivElement;
    const { offsetLeft, offsetTop } = annotDiv;
    return { x: offsetLeft, y: offsetTop };
  }

  public static pasteMarkup(viewId: string) {
    const pdfComponent = PdfViewerManager.getViewer(viewId);
    pdfComponent && pdfComponent.pasteAnnot(true);
  }

  public static getViewStatusObs(): BehaviorSubject<boolean> {
    return PdfViewerManager.currentActiveViewer?.pdfController.viewChangedObservable;
  }

  public static getViewStatusObsExtend(): boolean {
    return PdfViewerManager.currentActiveViewer?.pdfController.viewChangedObservable.value;
  }

  public static selectMode(viewId: string) {
    const pdfComponent = PdfViewerManager.getViewer(viewId);
    pdfComponent && pdfComponent.markupManager.drawOperator.selectTextAnnotation();
  }

  public static drawMode(viewId: string, state: StateManager) {
    const pdfComponent = PdfViewerManager.getViewer(viewId);
    pdfComponent && pdfComponent.markupManager.drawOperator.actionDraw(state);
  }

  public static setWaterMark(fileViewId: string, watermark: WaterMarkConfig) {
    PdfViewerManager.waterMarkConfig$.next({ fileViewId, watermark });
  }

  static reloadMarkup(viewId: string) {
    const markupManager = PdfViewerManager.getViewer(viewId)?.markupManager;
    markupManager && markupManager.reloadMarkup();
  }

  public static async insertPages(viewId: string, file: any, destIndex: number, startIndex: number, endIndex: number) {
    const pdfComponent = PdfViewerManager.getViewer(viewId);
    if (pdfComponent) {
      const pdfDoc = pdfComponent.pdfController.pdfViewer.getCurrentPDFDoc();
      await PdfViewerManager.insertPagesByDoc(pdfDoc, file, destIndex, startIndex, endIndex);
    }
  }

  public static async insertPagesByDoc(pdfDoc: PdfDoc, file: any, destIndex: number, startIndex: number, endIndex: number) {
    if (pdfDoc) {
      try {
        await pdfDoc.insertPages({
          file,
          destIndex,
          startIndex,
          endIndex,
        });
      } catch (error) {
        // console.log(error);
      }
    }
  }

  public static async removePageByDoc(pdfDoc: PdfDoc, index: number) {
    if (pdfDoc) {
      try {
        await pdfDoc.removePage(index);
      } catch (error) {
        // console.log(error);
      }
    }
  }

  public static async removePagesByDoc(pdfDoc: PdfDoc, index: number[][]) {
    if (pdfDoc) {
      try {
        await pdfDoc.removePages(index);
      } catch (error) {
        // console.log(error);
      }
    }
  }

  public static async insertBlankPage(viewId: string, pageIndex: number) {
    const pdfComponent = PdfViewerManager.getViewer(viewId);
    if (pdfComponent) {
      const pdfDoc = pdfComponent.pdfController.pdfViewer.getCurrentPDFDoc();
      if (pdfDoc) {
        const page1 = await pdfDoc.getPageByIndex(0);
        if (page1) {
          const width = page1.getWidth();
          const height = page1.getHeight();
          try {
            await pdfDoc.insertPage(pageIndex, width, height);
          } catch (error) {
            // error
          }
        }
      }
    }
  }
}
