/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import { Injectable, EventEmitter } from '@angular/core';
import {
  BehaviorSubject, Subscription,
} from 'rxjs';
import { ModelInfo } from '../common/ModelInfo';
import { MarkupSetingInfo } from '../common/MarkupSetingInfo';
import { MarkupFormatTabSetting } from '../common/MarkupFormatTabSetting';
import { TypeZoom } from '../common/main-viewer-common';
import { OnDestroyService } from '../interfaces/interfaces';
import { UtilExtend, LogType, LogTypeClass } from '../utils/util-extend';

@Injectable({
  providedIn: 'root',
})
export class Connect3dviewerService implements OnDestroyService {
  /** Property */
  public mapModelInfo = new Map<string, ModelInfo>();

  public arrWebViewerViewOnly: Set<Communicator.WebViewer> = new Set();

  private viewerLoadStatus = new BehaviorSubject<string>(null);

  public viewLoadStatus$ = this.viewerLoadStatus.asObservable();

  public viewer3DState$ = new EventEmitter<string>();

  public eventResize$ = new EventEmitter<number>();

  public markupSetingInfo = new BehaviorSubject<MarkupSetingInfo>(
    new MarkupSetingInfo(false, false, false, 'Avenir', false, false, 15),
  );

  public markupSetingInfo$ = this.markupSetingInfo.asObservable();

  private tempMarkupFormatTabSetting = new MarkupFormatTabSetting();

  _markupFormatTabSetting = new BehaviorSubject<MarkupFormatTabSetting>(
    new MarkupFormatTabSetting(),
  );

  public MarkupFormatTabSetting$ = this._markupFormatTabSetting.asObservable();

  /** Method * */
  public UpdateMarkupSetingInfo(value: MarkupSetingInfo) {
    this.markupSetingInfo.next(value);
  }

  public mapTimerLoadedViewer = new Map<string, number>();

  private subs = new Subscription();

  constructor(
  ) {
    UtilExtend.Log(LogType.Init, 'connect 3d', LogTypeClass.Service);
    this.handlerEventResize();
  }

  onDestroyService(): void {
    UtilExtend.Log(LogType.Destroy, 'connect 3d', LogTypeClass.Service);
    this.subs && this.subs.unsubscribe();
    if (this.mapModelInfo) {
      this.mapModelInfo.forEach((modelInfo) => {
        modelInfo && this.destroyModelInfo(modelInfo);
      });
      this.mapModelInfo.clear();
    }
    this.mapTimerLoadedViewer && this.mapTimerLoadedViewer.clear();
  }

  handlerEventResize() {
    const sub = this.eventResize$.pipe().subscribe((time) => {
      setTimeout(() => this.updateResize(), time ?? 10);
    });
    this.subs.add(sub);
  }

  setModelInfo(viewId: string, modelInfo: ModelInfo) {
    this.mapModelInfo.set(viewId, modelInfo);
  }

  getModelInfo(viewId: string): ModelInfo | undefined {
    return this.mapModelInfo.get(viewId);
  }

  updateResize() {
    try {
      this.mapModelInfo.forEach((value) => {
        const webViewer = value.webviewer;
        webViewer && webViewer.resizeCanvas();
      });
      this.arrWebViewerViewOnly.forEach((v) => v && v.resizeCanvas());
    } catch (error) {
      console.log('error resize canvas');
    }
  }

  emitLoadModelStatus(value: string) {
    this.viewerLoadStatus.next(value);
  }

  zoomAllView(zoomType: TypeZoom) {
    const type = zoomType === TypeZoom.In ? 1 : ((zoomType === TypeZoom.Out) ? 0 : null);
    if (type !== null) {
      this.mapModelInfo.forEach((modelInfo) => {
        modelInfo.operatorService.ZoomOperator(type);
      });
    }
  }

  /** Code Tempplate */

  /**
   *
   * @param viewID:
   */
  setPanOperator(viewID) {
    const modelInformation = this.getModelInfo(viewID);
    if (modelInformation && modelInformation.operatorService) {
      modelInformation.operatorService.PanOperator();
    }
  }

  /**
   *
   * @param viewID:
   */
  setCancelOperator(viewID) {
    const modelInformation = this.getModelInfo(viewID);
    if (modelInformation && modelInformation.operatorService) {
      modelInformation.operatorService.CancelOperator();
    }
  }

  close3DViewer(viewId: string) {
    const modelInfo = this.mapModelInfo.get(viewId);
    if (modelInfo) {
      this.closeConnection(modelInfo.webviewer);
      this.destroyModelInfo(modelInfo);
      this.mapModelInfo.delete(viewId);
    }
  }

  closeConnection(webViewer: Communicator.WebViewer) {
    if (webViewer) {
      webViewer.closeConnection();
      webViewer.shutdown();
    }
  }

  destroyModelInfo(modelInfo: ModelInfo) {
    Object.keys(modelInfo).forEach((key) => {
      if (typeof modelInfo[key] === 'object' && modelInfo[key]) {
        if (typeof modelInfo[key].onDestroyService === 'function') {
          modelInfo[key].onDestroyService();
        } else if (typeof modelInfo[key].onClean === 'function') {
          modelInfo[key].onClean();
        }

        Object.getOwnPropertyNames(modelInfo[key]).forEach((keySub) => {
          const isZoomPreviousService = modelInfo[key].isZoomPreviousService || false;
          if (!isZoomPreviousService) {
            modelInfo[key][keySub] = null;
          }
        });
      } else {
        modelInfo[key] = null;
      }
    });
  }
}
