/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable lines-between-class-members */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ButtonLayout } from 'src/app/shared/components/main-viewer/layout-menu/layout-menu-common';
import {
  TypeLayout, FileInfo, DirectionLayout, ViewActive,
} from '../common/main-viewer-common';
import { ViewerDisplayService } from './viewer-display.service';
import { OnDestroyService } from '../interfaces/interfaces';
import { UtilExtend, LogType, LogTypeClass } from '../utils/util-extend';

@Injectable({
  providedIn: 'root',
})
export class MultiLayoutService implements OnDestroyService {
  private readonly nameSettingLayout = 'SettingLayout';

  private currentLayout = new BehaviorSubject<TypeLayout>(TypeLayout.Full);

  public currentLayout$ = this.currentLayout.asObservable();

  public activeIcon: string = TypeLayout[TypeLayout.Full];
  public activeTooltip: string = 'Layout.full';
  public typeLayout: number = 4;
  private directionLayout = DirectionLayout.Horizontal;

  private listViewHide: FileInfo[] = [];

  public mapTypeLayout = new Map<number, TypeLayout[]>();

  private listItemsView: FileInfo[];
  private viewActive: ViewActive;
  private onDestroy$ = new Subject<any>();

  constructor(
    private viewerDisplayService: ViewerDisplayService,
  ) {
    UtilExtend.Log(LogType.Init, 'multi layout', LogTypeClass.Service);
    this.init();
  }

  private init() {
    this.mapTypeLayout.set(1, [TypeLayout.Full]);
    this.mapTypeLayout.set(2, [TypeLayout.OneAndOne, TypeLayout.OneOnOne]);
    this.mapTypeLayout.set(3, [TypeLayout.OneOnTwo, TypeLayout.TwoOnOne, TypeLayout.OneAndTwo, TypeLayout.TwoAndOne]);
    this.mapTypeLayout.set(4, [TypeLayout.TwoAndTwo]);
    this.mapTypeLayout.set(0, [TypeLayout.ViewOnly]);
    this.subscribeListViewDisplay();
    this.subscribeViewActive();
  }

  private subscribeListViewDisplay() {
    this.viewerDisplayService.listViewDisplay$.pipe(
      filter((value) => !!value && value.length > 0),
      takeUntil(this.onDestroy$),
    ).subscribe((listview) => {
      this.listItemsView = listview;
    });
  }

  private subscribeViewActive() {
    this.viewerDisplayService.viewActive$.pipe(takeUntil(this.onDestroy$)).subscribe((viewActive) => {
      this.viewActive = viewActive;
    });
  }

  getCurrentLayout(): TypeLayout {
    return this.currentLayout.value;
  }

  setCurrentLayout(layout: TypeLayout) {
    this.currentLayout.next(layout);
  }

  initLayout(length: number) {
    // const layoutCache = this.getSettingLayout();
    // layoutCache ? this.addLayoutInitWithCache(layoutCache) : this.addLayoutInit(length);
    this.addLayoutInit(length);
    // this.setCurrentLayout(TypeLayout.Full);
    // this.changeLayout(TypeLayout.Full);
  }

  setLayoutWhenActionClose(indexViewDeleted: number) {
    let re: TypeLayout = TypeLayout.Full;
    if (this.currentLayout.value === TypeLayout.TwoAndTwo) {
      if (this.directionLayout === DirectionLayout.Horizontal) {
        indexViewDeleted === 2 || indexViewDeleted === 3
          ? (re = TypeLayout.TwoOnOne)
          : (re = TypeLayout.OneOnTwo);
      } else {
        indexViewDeleted === 0 || indexViewDeleted === 2
          ? (re = TypeLayout.OneAndTwo)
          : (re = TypeLayout.TwoAndOne);
      }
    } else if (this.mapTypeLayout.get(3).includes(this.currentLayout.value)) {
      switch (this.currentLayout.value) {
        case TypeLayout.OneOnTwo:
          indexViewDeleted === 1 || indexViewDeleted === 2
            ? (re = TypeLayout.OneOnOne)
            : (re = TypeLayout.OneAndOne);
          break;
        case TypeLayout.TwoOnOne:
          indexViewDeleted === 0 || indexViewDeleted === 1
            ? (re = TypeLayout.OneOnOne)
            : (re = TypeLayout.OneAndOne);
          break;
        case TypeLayout.OneAndTwo:
          indexViewDeleted === 1 || indexViewDeleted === 2
            ? (re = TypeLayout.OneAndOne)
            : (re = TypeLayout.OneOnOne);
          break;
        case TypeLayout.TwoAndOne:
          indexViewDeleted === 0 || indexViewDeleted === 2
            ? (re = TypeLayout.OneAndOne)
            : (re = TypeLayout.OneOnOne);
          break;
        default:
          re = TypeLayout.Full;
      }
    } else if (this.mapTypeLayout.get(2).includes(this.currentLayout.value)) {
      re = TypeLayout.Full;
    } else {
      re = TypeLayout.Full;
    }
    this.setCurrentLayout(re);
  }

  refreshLayout() {
    const numberFileShow = this.viewerDisplayService.getListViewDisplay().filter((item) => !item.isHide).length;
    const numberCurrentLayout = this.getNumberLayout(this.currentLayout.getValue());
    if (numberCurrentLayout !== numberFileShow) {
      const [newTypeLayout, ...temp1] = this.mapTypeLayout.get(numberFileShow);
      this.setCurrentLayout(newTypeLayout);
    }
  }

  addLayoutInitWithCache(layoutCache: TypeLayout) {
    this.setCurrentLayout(layoutCache);
    this.changeLayout(layoutCache);
  }

  addLayoutInit(length: number) {
    setTimeout(() => {
      switch (length) {
        case 1:
          this.setCurrentLayout(TypeLayout.Full);
          break;
        case 2:
          this.setCurrentLayout(TypeLayout.OneAndOne);
          break;
        case 3:
          this.setCurrentLayout(TypeLayout.OneAndTwo);
          break;
        default:
          this.setCurrentLayout(TypeLayout.TwoAndTwo);
      }
    }, 200);
  }

  setClassCss(): string {
    let re = '';
    switch (this.currentLayout.value) {
      case TypeLayout.Full:
        re = 'layout-grid-full';
        break;
      case TypeLayout.OneAndOne:
        re = 'layout-grid-1-and-1';
        break;
      case TypeLayout.OneOnOne:
        re = 'layout-grid-1-on-1';
        break;
      case TypeLayout.OneAndTwo:
        re = 'layout-grid-1-and-2';
        break;
      case TypeLayout.OneOnTwo:
        re = 'layout-grid-1-on-2';
        break;
      case TypeLayout.TwoOnOne:
        re = 'layout-grid-2-on-1';
        break;
      case TypeLayout.TwoAndOne:
        re = 'layout-grid-2-and-1';
        break;
      case TypeLayout.TwoAndTwo:
        re = 'layout-grid-2-and-2';
        break;
      default:
        re = '';
    }
    return re;
  }

  /**
   * Get number of layout selected (number of view)
   * @param layout
   */
  getNumberOfLayout(layout: TypeLayout): number {
    let re = 4;
    if (this.mapTypeLayout.get(1).includes(layout)) {
      re = 1;
    } else if (this.mapTypeLayout.get(2).includes(layout)) {
      re = 2;
    } else if (this.mapTypeLayout.get(3).includes(layout)) {
      re = 3;
    }
    return re;
  }

  clearListViewHide() {
    this.listViewHide = [];
  }

  /**
   * Get an array diffence between array file display and array file hided
   */
  getDiffenceFileSelectedAndHide(): FileInfo[] {
    const arrFileSelected = this.listItemsView;
    const arrDiff = arrFileSelected.reduce((re, curr) => {
      const temp = this.listViewHide.includes(curr);
      !temp && re.push(curr);
      return re;
    }, []);
    return arrDiff;
  }

  refreshShowHideListView() {
    this.listItemsView.forEach((item) => {
      this.listViewHide.includes(item) ? item.isHide = true : item.isHide = false;
    });
  }

  /**
   * Action change layout
   * @param layout
   */
  changeLayout(layout: TypeLayout): void {
    const numberOfLayout = this.getNumberOfLayout(layout);
    const arrDiff = this.getDiffenceFileSelectedAndHide();
    const numberOfFileDisplay = arrDiff.length;

    const numberTemp = numberOfFileDisplay - numberOfLayout;
    if (numberTemp === 0) {
      return;
    }
    numberTemp > 0
      ? this.changeLayoutToLess(arrDiff, numberTemp)
      : this.changeLayoutToMore(arrDiff, numberOfLayout);
    this.refreshShowHideListView();
  }

  /**
   * Change layout to layout has less view than layout source
   * @param arr
   * @param numberDiff
   */
  changeLayoutToLess(arr: FileInfo[], numberDiff: number) {
    const fileActive = this.viewActive.data;
    let tempHide: FileInfo[] = arr;
    if (fileActive) {
      tempHide = arr.reduce((re, curr) => {
        if (curr !== fileActive) {
          re.push(curr);
        }
        return re;
      }, []);
    }
    tempHide = tempHide.slice(-numberDiff);
    this.listViewHide = [...this.listViewHide, ...tempHide];
  }

  /**
   * Change layout to layout has more view than layout source
   * @param arr
   * @param numberOfLayout
   */
  changeLayoutToMore(arr: FileInfo[], numberOfLayout: number) {
    const sum = arr.length + this.listViewHide.length;
    if (sum <= numberOfLayout) {
      this.listViewHide = []; // remove item hide show all
      const numberDiff = numberOfLayout - sum;
      this.addBlankViewToDisplay(numberDiff);
    } else {
      const temp = numberOfLayout - arr.length;
      this.listViewHide.splice(0, temp);
    }
  }

  addBlankViewToDisplay(numberBlankView: number) {
    if (numberBlankView > 0) {
      for (let i = 0; i < numberBlankView; i++) {
        const blankName = 'No Document Displayed';
        const uniqueId = Communicator.UUID.create();
        const blankId = `blank-id-${uniqueId}`;
        const viewBlank: FileInfo = {
          baseFileId: null,
          baseMajorRev: 0,
          baseMinorRev: 0,
          viewId: blankId,
          filename: blankName,
          isBlank: true,
          isHide: false,
        };
        this.listItemsView.push(viewBlank);
      }
      this.listItemsView.length > 4 && (this.listItemsView.length = 4);
      this.viewerDisplayService.updateListViewDisplay(this.listItemsView);
    }
  }

  numberOfLayout(currentLayout: TypeLayout): number {
    let re = 0;
    this.mapTypeLayout.forEach((value, key) => {
      if (value.includes(currentLayout)) {
        re = key;
      }
    });
    return re;
  }

  getNumberLayout(typeLayout: TypeLayout = this.currentLayout.getValue()): number {
    const arrTypeLayout = Array.from(this.mapTypeLayout);
    let re: number;
    arrTypeLayout.some((type) => {
      const [num, arrTypeTemp] = type;
      if (arrTypeTemp.includes(typeLayout)) {
        re = num;
        return true;
      }
      return false;
    });
    return re;
  }
  onDestroyService(): void {
    UtilExtend.onDestroy(this.onDestroy$);
  }
  getListItemsView() {
    return this.listItemsView;
  }
}
