import { fromEvent, Subscription } from 'rxjs';
import { NgZone } from '@angular/core';
import { KeyCode } from '../enums/keycode';
import { OnClean } from '../interfaces/interfaces';

export class PreventDefaultEvent implements OnClean {
  private subs = new Subscription();

  private mapPreventKeyCode = [
    KeyCode.F1,
    // KeyCode.F5,
  ];

  private mapPreventKeycodeWithModifiers = [
    KeyCode.equals,
    KeyCode.add,
    KeyCode.p,
    KeyCode.P,
    KeyCode.f,
    KeyCode.F,
    KeyCode.s, // lock save of browser
    KeyCode.S,
    KeyCode.g,
    KeyCode.G,
    KeyCode.subtract,
    KeyCode.minus,
  ];

  private mapExceptKey = [
    KeyCode.F12,
  ];

  constructor(private ngZone: NgZone) {
  }

  public init() {
    this.subscribeEvent();
    this.preventMouseDefaultEvent();
  }

  private subscribeEvent() {
    const subKeydown = fromEvent(window, 'keydown').subscribe((event: KeyboardEvent) => this.preventKeyEvent(event));
    const subContextmenu = fromEvent(document, 'contextmenu').subscribe((event: MouseEvent) => this.preventMouseEvent(event));
    this.subs.add(subKeydown);
    this.subs.add(subContextmenu);
  }

  private preventKeyEvent(event: KeyboardEvent) {
    const inKeyCodePrevent = this.mapPreventKeycodeWithModifiers.includes(event.key as KeyCode);
    const withModifiers = event.ctrlKey && inKeyCodePrevent;
    const noModifiers = this.mapPreventKeyCode.includes(event.key as KeyCode);
    if (withModifiers || noModifiers) {
      event.preventDefault();
    }
  }

  private preventDefaultKeyDown(event: KeyboardEvent) {
    const notIn = this.mapExceptKey.includes(event.key as KeyCode);
    if (!notIn) {
      event.preventDefault();
    }
  }

  private preventMouseDefaultEvent() {
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('wheel', (event: WheelEvent) => {
        if (event.ctrlKey) {
          event.preventDefault();
        }
      }, { passive: false });
    });
  }

  private preventMouseEvent(event: MouseEvent) {
    event.preventDefault();
  }

  onClean(): void {
    this.subs.unsubscribe();
  }
}
