import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fromEvent, Subscription } from 'rxjs';
import { KeyCode } from 'src/app/core/enums/keycode';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss'],
})
export class NotificationDialogComponent implements OnInit {
  public justOkAction: boolean = false;

  subscription = Subscription.EMPTY;

  buttonOk = 'OK';

  constructor(
    public dialogRef: MatDialogRef<NotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataDialog,
  ) {
    data.justOkAction && (this.justOkAction = data.justOkAction);
    if (data.textButtonOK) this.buttonOk = data.textButtonOK;
  }

  ngOnInit() {
    this.subscription = fromEvent(document, 'keyup').subscribe((event: KeyboardEvent) => {
      if (event.key === KeyCode.escape) {
        event.stopPropagation();
        this.subscription.unsubscribe();
      } else if (event.key === KeyCode.enter) {
        event.stopPropagation();
        this.subscription.unsubscribe();
      } else if (event.key === KeyCode.delete) { // #5078
        event.stopPropagation();
      }
    });
    this.subscription = fromEvent(document, 'keydown').subscribe((event: KeyboardEvent) => {
      if (event.key === KeyCode.delete) { // #5078
        event.stopPropagation();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

export interface DataDialog {
  textButtonOK?: string;
  title?: string;
  message: string;
  justOkAction?: boolean;
}
