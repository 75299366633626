export class ViewerEventsService {
  constructor() {}

    private eventStorage: Map<string, any[]> = new Map<string, any[]>();

    public emit(eventName: string, data: any) {
      if (!this.eventStorage.has(eventName)) {
        // ignore
      } else {
        // console.log(eventName);
        const callbacks = this.eventStorage.get(eventName);
        callbacks.forEach((x) => x(data));
      }
    }

    public on(eventName: string, callback: any) {
      if (!this.eventStorage.has(eventName)) {
        this.eventStorage.set(eventName, [callback]);
      } else {
        const event = this.eventStorage.get(eventName);
        event.push(callback);
      }

      return {
        eventName,
        callback,
      };
    }

    public unRegisterEvent(emitObject) {
      if (emitObject && this.eventStorage.has(emitObject.eventName)) {
        let event = this.eventStorage.get(emitObject.eventName);
        event = event.filter((x) => x === emitObject.calback);
        this.eventStorage.set(emitObject.eventName, event);
      }
    }
}
