/* eslint-disable max-len */
import BaseOperator from 'src/app/viewer3d/operator/BaseOperator';
import { BehaviorSubject, Subject } from 'rxjs';
import { LanguageManagerService } from 'src/app/main-viewer/services/language-manager.service';
import { UnitMultiplier, eModifineType, eViewMode, iModifineColorNode } from 'src/app/shared/class/global';
import { ViewerEventsService } from '../../viewer3d/services/viewer-event.service';
import { MarkupMultiselectService } from '../../viewer3d/services/markup-multiselect-service';
import { MarkupsService } from '../services/markups.service';
import Util from '../utils/util';
import { MarkupEntity } from './markups';
import ADSettingViewer3D from './ad.setting.viewer3d';
import { ModelInfo } from './ModelInfo';

export default class ADWebViewer extends Communicator.WebViewer {
  private unitMultiplier: UnitMultiplier = UnitMultiplier.Millimeter;

  public measureUnitModel: number = 1.0;

  public isOrbitHandle : boolean = false;

  public viewerEvents: ViewerEventsService;

  public markupMultiselectService: MarkupMultiselectService;

  public markupsService: MarkupsService;

  public originCameraSize: number = 0;

  public originCameraScale: number = 0;

  public originCamera: Communicator.Camera = null;

  private markupDefaulFormat = null;

  public isShowContext: boolean = true; // nghia_td edit: bugfix/10244

  public isZoom: boolean = false; // nghia_td edit: bugfix/10244

  private curOperator: BaseOperator = null;

  public handlingTheActionHome: boolean = false;

  public selectedItems = new Subject<any>();

  public selectedItems$ = this.selectedItems.asObservable();

  public languageManagerService: LanguageManagerService;

  public setting: ADSettingViewer3D;

  private ignoreADSelection: boolean = false;

  private unitMultiplierModel: number = 1;

  private viewModeCustom: eViewMode = eViewMode.normal;

  public ignoreTreeSync: boolean= false;

  /**
   * [05/09/2024][phuong_td][ADV-7766] Json information of the file
   */
  public jsonSetting: any = {};
  private stackModifineColorNode: iModifineColorNode[] = [];  

  constructor(inputParams: Communicator.WebViewerConfig) {
    super(inputParams);
    this.viewerEvents = new ViewerEventsService();
    // this.markupMultiselectService = new MarkupMultiselectService(this.viewerEvents);
  }

  setMultiselectService(multiselectService: MarkupMultiselectService) {
    if (multiselectService) {
      this.markupMultiselectService = multiselectService;
    }
  }

  setMarkupsService(markupsService: MarkupsService) {
    if (markupsService) {
      this.markupsService = markupsService;
    }
  }

  setLanguageManagerService(languageManagerService: LanguageManagerService) {
    if (languageManagerService) {
      this.languageManagerService = languageManagerService;
    }
  }

  getCurOperator() {
    return this.curOperator;
  }

  setCurOperator(operator) {
    if (operator) {
      this.curOperator = operator;
    }
  }

  getMarkupDefaulFormat() {
    return this.markupDefaulFormat;
  }

  setMarkupDefaulFormat(format) {
    if (format) { this.markupDefaulFormat = format; }
  }

  getIgnoreADSelection() {
    return this.ignoreADSelection;
  }

  setIgnoreADSelection(ignore: boolean = false) {
    this.ignoreADSelection = ignore;
  }

  getMeasureUnit() {
    return this.unitMultiplier;
  }

  setMeasureUnit(unit: UnitMultiplier, isSetDataMarkup = true) {
    if (unit) {
      this.unitMultiplier = unit;
      if (!isSetDataMarkup) {
        return;
      }
      const markups = this.markupsService.getMarkupsSelected()?.filter((v: MarkupEntity) => Util.DimentionType.includes(v.type));
      let isTrigger = false;
      if (markups.length === 1) isTrigger = true;
      const allMeasurements = this.measureManager.getAllMeasurements();
      if (allMeasurements && allMeasurements.length > 0 && markups.length) {
        const listUpdate = [];
        const listMarkupDifFile = [];
        markups.forEach((item: any) => {
          const markup: any = allMeasurements.find((m: any) => item.originData.originId === m.getOriginId());
          if (markup && markup.updateSettingUnit) {
            markup.updateSettingUnit(unit, isTrigger);
            listUpdate.push(markup.toJson());
            this.redraw();
          } else {
            const { measurementValue, unitMultiplier, text } = item.originData;
            const millimeter = measurementValue * unitMultiplier;
            const newValue = millimeter / unit;
            const newtext = Util.formatWithUnit(newValue, unit);
            const temp = item;
            temp.originData.measurementValue = newValue;
            temp.originData.text = newtext;
            temp.originData.unitMultiplier = unit;
            listMarkupDifFile.push(temp);
          }
        });
        if (!isTrigger) {
          this.markupsService.updateMarkupEntitiesOriginData(this.markupsService.viewIdActive, listUpdate, listMarkupDifFile);
        }
      }
    }
  }

  getDomElements() {
    return this._domElements;
  }

  getMeasureUnitModel() {
    if (!this.unitMultiplierModel) return 1;
    return this.unitMultiplierModel;
  }

  setMeasureUnitModel(unit: number) {
    if (unit) {
      this.unitMultiplierModel = unit;
    }
  }

  getViewModeCustom () {
    return this.viewModeCustom;
  }

  async exitMonochromeMode(colorNative: any) {
    this.viewModeCustom = eViewMode.normal;
    try {
      // [07/03/2024][ADV-7485][phuong_td] DWG Monochrome - The Reset Model performed when the Exit Monochrome Mode is clicked
      // unset node color instead of resetting the model
      const root = this.model.getAbsoluteRootNode();
      this.model.unsetNodesFaceColor([root]);
      this.model.unsetNodesLineColor([root]);
      let { sheet, background, shadowSheet } = this.setting.drawingColor;
      if (sheet as any === 'none') sheet = colorNative.sheet;
      if (background as any === 'none') background = colorNative.background;
      if (shadowSheet as any === 'none') shadowSheet = colorNative.sheetShadow;
      const sheetColor = Communicator.Color.fromJson(sheet);
      const backgroundColor = Communicator.Color.fromJson(background);
      const sheetShadowColor = Communicator.Color.fromJson(shadowSheet);
      await this.sheetManager.setSheetColors(backgroundColor, sheetColor, sheetShadowColor);
      // [27/09/2024][ADV-7710][phuong_td] Change the color for the list of nodes so that it does not coincide with the background color before recovering from the stack the change 
      const modelInfo: ModelInfo = this.markupsService.getModelInfo();
      if (modelInfo) {
        modelInfo.viewerService.changeColorBlackWhite(this);
      }
      Util.delay(200).then(() => {
        this.restoreColorByStack();
      })
    } catch (error) {
      console.log(error);
    }
  }

  monochromeMode(colorNative: any) {
    this.viewModeCustom = eViewMode.monochrome;
    const colorWhite = new Communicator.Color(255, 255, 255);
    const colorBlack = new Communicator.Color(0, 0, 0);
    const mapColorBlack = new Map();
    const mapColorWhite = new Map();
    const root = this.model.getAbsoluteRootNode();
    mapColorWhite.set(root, colorWhite);
    mapColorBlack.set(root, colorBlack);
    // const sheetColor = this.sheetManager.getSheetColor();
    // const backgroundColor = this.sheetManager.getSheetBackgroundColor();
    // const sheetShadowColor = this.sheetManager.getSheetShadowColor();

    let { sheet, background, shadowSheet } = this.setting.drawingColor;
    if (sheet as any === 'none') sheet = colorNative.sheet;
    if (background as any === 'none') background = colorNative.background;
    if (shadowSheet as any === 'none') shadowSheet = colorNative.sheetShadow;
    const sheetColor = sheet;
    const backgroundColor = background;
    const sheetShadowColor = shadowSheet;

    const lumSheetColor = Util.calLumen(sheetColor);
    const lumBackgroundColor = Util.calLumen(backgroundColor);
    const lumSheetShadowColor = Util.calLumen(sheetShadowColor);

    let newSheetColor = new Communicator.Color(255, 255, 255);
    let newbackgroundColor = new Communicator.Color(255, 255, 255);
    let newsheetShadowColor = new Communicator.Color(255, 255, 255);

    // [28/10/2024][ADV-7800][phuong_td] Allows to set color for point
    if (lumSheetColor > 0.5) {
      this.model.setNodesColors(mapColorBlack, true, true);
      newSheetColor = new Communicator.Color(255, 255, 255);
    } else {
      this.model.setNodesColors(mapColorWhite, true, true);
      newSheetColor = new Communicator.Color(0, 0, 0);
    }

    // [05/09/2024][phuong_td][ADV-7766] Set color for nodes of the same color as the color of the sheet
    if (this.jsonSetting) {
      this.setNodesColor(this.jsonSetting.listNodeSheetBackground, sheetColor);
    }

    if (lumBackgroundColor > 0.5) {
      newbackgroundColor = new Communicator.Color(255, 255, 255);
    } else {
      newbackgroundColor = new Communicator.Color(0, 0, 0);
    }

    if (lumSheetShadowColor > 0.5) {
      newsheetShadowColor = new Communicator.Color(255, 255, 255);
    } else {
      newsheetShadowColor = new Communicator.Color(0, 0, 0);
    }

    this.sheetManager.setSheetColors(newbackgroundColor, newSheetColor, newsheetShadowColor);
  }


  /**
   * [05/09/2024][phuong_td][ADV-7766] Set color for Nodes
   */
  setNodesColor(nodeIds: number[], color: Communicator.Color) {
    if (nodeIds && nodeIds.length && color) {
      const mapColor = new Map();
      nodeIds.forEach((nodeId=> {
        mapColor.set(nodeId, color);
      }))
      // [28/10/2024][ADV-7800][phuong_td] Allows to set color for point
      this.model.setNodesColors(mapColor, true, true);
    }
  }  

  private optimizationModifineColorNode (dataModifine: iModifineColorNode) {
    const result: iModifineColorNode[] = [];
    this.stackModifineColorNode.forEach((stackItem) => { // browse the stack
      const temp = Util.cloneObj(stackItem); // clone stackItem
      const { nodes } = dataModifine; // nodes are modified
      const tempNodes = []; // temporary list of nodes
      temp.nodes.forEach((id)=> { // browse the nodes of the duplicated stackItem
        const check = nodes.includes(id);
        if (!check) { // If the node is in the list of changed nodes, it is not included in the temporary node list
          tempNodes.push(id);
        }
      })
      if (tempNodes.length) { // If the temporary button list has no buttons, do not include stackItem in the result list
        temp.nodes = tempNodes;
        result.push(temp);
      }
    })
    // this.stackModifineColorNode.push(dataModifine);
    result.push(dataModifine);
    this.stackModifineColorNode = result;
  }

  addModifineColorNode(data: iModifineColorNode) {
    this.optimizationModifineColorNode(data);
    // this.stackModifineColorNode.push(data);
  }

  restoreColorByStack() {
    this.stackModifineColorNode.forEach((item: iModifineColorNode) => {
      if (item.type === eModifineType.setColor) {
        const mapColor = new Map();
        item.nodes.forEach((node) => {
          mapColor.set(node, item.color);
        })
        // [28/10/2024][ADV-7800][phuong_td] Allows to set color for point
        this.model.setNodesColors(mapColor, true, true);
      } else {
        this.model.unsetNodesFaceColor(item.nodes);
        this.model.unsetNodesLineColor(item.nodes);
      }
    })
  }

  getStackModifineColorNode() {
    return this.stackModifineColorNode;
  }

  clearStackModifineColorNode () {
    this.stackModifineColorNode = [];
  }
}
