import {
  BehaviorSubject, Observable, Subject, Subscription,
} from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { CommonConstant } from 'src/app/core/constant/constant';
import Util from 'src/app/core/utils/util';
import { ViewSectionService } from 'src/app/dashboard/service/view-section.service';
import { UtilExtend } from 'src/app/core/utils/util-extend';
import { takeUntil } from 'rxjs/operators';

enum eFileLanguage {
  de = 'de.json',
  enUS = 'en-US.json',
  vi = 'vi.json',
  es = 'es.json',
  frCA = 'fr-CA.json',
  it = 'it.json',
  ptBR = 'pt-BR.json'
}
export interface iLanguage {
  code: string;
  name: string;
}
@Injectable({
  providedIn: 'root',
})
export class LanguageManagerService implements OnDestroy {
  private _curentTranslations = new BehaviorSubject<any>('');

  curentTranslations$ = this._curentTranslations.asObservable();

  path = './assets/i18n/';

  defaultLang = 'en-US';

  curLanguage: iLanguage = { code: 'en-US', name: 'English (United States)-English (United States)' };

  getTranslationSub: Subscription;

  languages: iLanguage[] = [];

  cleanObservables = new BehaviorSubject<boolean>(false);

  cleanObservables$ = this.cleanObservables.asObservable();

  Observables: Subscription[] = [];

  public static arrCodeLanguage = ['en-US', 'de', 'es', 'fr-CA', 'it', 'pt-BR', 'vi'];

  private onDestroy$ = new Subject<any>();

  constructor(
    private translateService: TranslateService,
    private http: HttpClient,
  ) {
  }

  ngOnDestroy(): void {
    UtilExtend.onDestroy(this.onDestroy$);
  }

  getCurentTranslations() {
    let translations = this._curentTranslations.value;
    if (!translations) {
      translations = this.translateService.getTranslation('en-US');
    }
    return translations;
  }

  init() {
    this.initListLanguage();
    this.updateLanguage();
    this.registerEvent();

  }

  public getJSON(path: string): Observable<any> {
    return this.http.get(path);
  }

  initListLanguage() {
    const langs = this.translateService.getLangs();
    if (langs.length === 0) {
      this.translateService.addLangs(['en-US']);
    }
    const lang = Util.getValueLocalStorage(CommonConstant.Language) || 'en-US';
    this.translateService.setDefaultLang(lang);
    this.cleanObservables$.subscribe((val) => {
      if (val) {
        this.updateLanguage();
        this.Observables.forEach((obs) => {
          obs.unsubscribe();
        });
        this.Observables = [];
      }
    });
    const { length } = Object.keys(eFileLanguage);
    Object.keys(eFileLanguage).forEach((key) => {
      const path = this.path + eFileLanguage[key];
      const obs = this.getJSON(path).subscribe((data) => {
        this.languages.push({ code: data.LANGUAGE_NAME.key, name: `${data.LANGUAGE_NAME.NATIVE}-${data.LANGUAGE_NAME.ENGLISH}` });
        if (this.languages.length === length) {
          const keys = this.languages.map((item) => item.code);
          this.translateService.addLangs(keys);
          this.cleanObservables.next(true);
        }
      });
      this.Observables.push(obs);
    });
  }

  registerEvent() {
    this.translateService.onDefaultLangChange.subscribe((g, e, c) => {
      this.updateLanguage();
    });
  }

  updateLanguage() {
    this.defaultLang = this.translateService.defaultLang;
    this.curLanguage = this.languages.find((lang) => lang.code === this.defaultLang);
    if (!this.curLanguage) this.curLanguage = { code: 'en-US', name: 'English (United States)-English (United States)' };
    const getTranslationObs = this.translateService.getTranslation(this.defaultLang);
    if (this.getTranslationSub) this.getTranslationSub.unsubscribe();
    this.getTranslationSub = getTranslationObs.pipe(takeUntil(this.onDestroy$)).subscribe((v) => {
      if (v) {
        this._curentTranslations.next(v);
      }
    });
  }

  changeLanguage(lang: string) {
    let l = lang;
    if (!LanguageManagerService.arrCodeLanguage.includes(lang)) {
      l = 'en-US';
    }
    this.translateService.setDefaultLang(l);
    Util.setLocalStorage(CommonConstant.Language, l);
  }
}
