export enum CommonConstant {
    MaxLengthText = 64,
    LoginSuccess = 'LOGIN_SUCCESS',
    NameUserSetting = 'USER_SETTING',
    UserNameKey = 'USER_NAME',
    UrlInit = 'URL_INIT',
    SettingData = 'SETTING_DATA',
    Language = 'LANGUAGE',
    AddSession = 'ADD_TO_SESSION',
    FontPath = 'FONT_PATH',
    PresetColors = 'PRESET_COLORS',
    ExtendParameters = 'EXTEND_PARAMETERS',
}
