import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { ViewActive, FileInfo } from '../common/main-viewer-common';

@Injectable({
  providedIn: 'root',
})
export class ViewerDisplayService {
  constructor() {}

  private listViewDisplay = new BehaviorSubject<FileInfo[]>([]);

  public listViewDisplay$ = this.listViewDisplay.asObservable();

  public listHideItem: FileInfo[] = null; // temp info, using forupdate status of list file on FileTree

  public viewActive: ViewActive = {
    index: null,
    baseFileId: null,
    baseMajorRev: 0,
    baseMinorRev: 0,
    data: null,
    isBlank: true,
    viewId: null,
  };

  private viewActiveObservable = new BehaviorSubject<ViewActive>(this.viewActive);

  public viewActive$ = this.viewActiveObservable.asObservable();

  updateListViewDisplay(value: FileInfo[], listHide: FileInfo[] = null) {
    this.listHideItem = listHide;
    if (value) {
      this.listViewDisplay.next(value);
    }
  }

  getListViewDisplay(): FileInfo[] {
    return this.listViewDisplay.value;
  }

  setViewActive(viewActive: ViewActive) {
    this.viewActiveObservable.next(viewActive);
  }
}
