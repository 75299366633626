export class MarkupFormatTabSetting {
    public static TabInfo: number = 0;

   public static TabFormat: number = 1;

    TabActive: number;

    isOvalFormat: boolean;

    isSquareFormat: boolean;

    isTextFormat: boolean;

    isLineFormat: boolean;

    constructor() {
      this.TabActive = 0;
      this.isLineFormat = false;
      this.isOvalFormat = false;
      this.isSquareFormat = false;
      this.isTextFormat = false;
    }

    public SetTabActive(tab: number) {
      if (tab === MarkupFormatTabSetting.TabInfo || tab === MarkupFormatTabSetting.TabFormat) {
        this.TabActive = tab;
      }
    }

    resetStatus() {
      this.isLineFormat = false;
      this.isOvalFormat = false;
      this.isSquareFormat = false;
      this.isTextFormat = false;
    }

    public AciveLineFormat() {
      this.resetStatus();
      this.isLineFormat = true;
    }

    public AciveOvalFormat() {
      this.resetStatus();
      this.isOvalFormat = true;
    }

    public AciveSquareFormat() {
      this.resetStatus();
      this.isSquareFormat = true;
    }

    public AciveTextFormat() {
      this.resetStatus();
      this.isTextFormat = true;
    }
}
