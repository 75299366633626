import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginData, RespondeLogin, RegisterData } from 'src/app/login/interfaces/interfaces';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { APIService } from './base/APIBase.service';
import { SystemConstants } from '../../common/system.constants';
import { Identity } from '../../common/identity';

@Injectable({
  providedIn: 'root',
})
export class IdentityService extends APIService {
  // da khai bao trong base
  // public BaseApi = `${SystemConstants.PROTOCOL}://${SystemConstants.SERVER_IDENTITY.HOST}`;

  constructor(
    public http: HttpClient,
    public router: Router,
  ) { super(http, router); }

  login(data: LoginData) {
    if (!this.isNeedIdentity) Promise.resolve({ errorCode: 200, info: '', msg: '', setting: null, token: '' });
    return this.postExtend<RespondeLogin>(SystemConstants.SERVER_IDENTITY.API.IDENTITY.LOGIN, data);
  }

  register(data: RegisterData) {
    if (!this.isNeedIdentity) Promise.resolve({ errorCode: 200, info: '', msg: '', setting: null, token: '' });
    return this.postExtend<RespondeLogin>(SystemConstants.SERVER_IDENTITY.API.IDENTITY.REGISTER, data);
  }

  logout() {
    if (!this.isNeedIdentity) Promise.resolve('');
    return this.postResText(SystemConstants.SERVER_IDENTITY.API.IDENTITY.LOGOUT, null);
  }

  getSettingData() {
    if (!this.isNeedIdentity) return of(null);
    return this.get(SystemConstants.SERVER_IDENTITY.API.IDENTITY.SETTING);
  }

  putSettingData(settingdata: string) {
    if (!this.isNeedIdentity) Promise.resolve(null);
    return this.putText(SystemConstants.SERVER_IDENTITY.API.IDENTITY.SETTING, settingdata);
  }

  getAllUser(): Observable<Identity[]> {
    if (!this.isNeedIdentity) Promise.resolve({ id: '', userName: '', email: '', phoneNumber: '', lockoutEnabled: true, roles: [] });
    return this.get(SystemConstants.SERVER_IDENTITY.API.IDENTITY.GET_ALL_USER)
      .pipe(
        map((data) => data as Identity[]),
      );
  }
}
