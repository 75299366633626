export class MarkupSetingInfo {
  // eslint-disable-next-line max-len
  constructor(Bold: boolean, Underline: boolean, Italic: boolean, Font: string, OrderedLists: boolean, UnorderedLists: boolean, Size: number) {
    this.Bold = Bold;
    this.Underline = Underline;

    this.Italic = Italic;

    this.Font = Font;

    this.OrderedLists = OrderedLists;

    this.UnorderedLists = UnorderedLists;

    this.Size = Size;
  }

    Bold: boolean;

    Underline: boolean;

    Italic: boolean;

    Font: string;

    OrderedLists: boolean;

    UnorderedLists: boolean;

    Size: number;
}
