// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
  height: 100%;
}

.container-notify {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white !important;
}
.container-notify span {
  font-size: 1.2em;
  text-align: center;
}

.notify-success {
  background-color: #080808;
}

.notify-warn {
  background-color: #F7DF64;
}

.notify-error {
  background-color: #F24125;
}

.color-warn-custom {
  color: #F7DF64 !important;
}

.color-error-custom {
  color: #F24125 !important;
}

.color-accent {
  color: #080808 !important;
}

.color-copy-custom {
  color: #080808 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/common/notify/notify.component.scss"],"names":[],"mappings":"AACA;EACI,cAAA;EACA,WAAA;EACA,YAAA;AAAJ;;AAWA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,uBAAA;AARJ;AASI;EACI,gBAAA;EACA,kBAAA;AAPR;;AAWA;EAdI,yBAekB;AARtB;;AAUA;EAjBI,yBAkBkB;AAPtB;;AASA;EApBI,yBAqBkB;AANtB;;AAQA;EACI,yBAAA;AALJ;;AAOA;EACI,yBAAA;AAJJ;;AAMA;EACI,yBAAA;AAHJ;;AAKA;EACI,yBAAA;AAFJ","sourcesContent":["\r\n:host {\r\n    display: block;\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n\r\n$color-success: #67F764;\r\n$color-warn: #F7DF64;\r\n$color-error: #F24125;\r\n$color-success: #080808;\r\n\r\n@mixin setColor($color) {\r\n    background-color: $color;\r\n}\r\n.container-notify {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: center;\r\n    align-items: center;\r\n    color: white !important;\r\n    span {\r\n        font-size: 1.2em;\r\n        text-align: center;\r\n    }\r\n}\r\n\r\n.notify-success {\r\n    @include setColor(($color-success));\r\n}\r\n.notify-warn {\r\n    @include setColor(($color-warn));\r\n}\r\n.notify-error {\r\n    @include setColor(($color-error));\r\n}\r\n.color-warn-custom{\r\n    color: $color-warn!important;\r\n}\r\n.color-error-custom {\r\n    color: $color-error!important;\r\n}\r\n.color-accent{\r\n    color:$color-success!important;\r\n}\r\n.color-copy-custom{\r\n    color:$color-success!important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
