/* eslint-disable no-console */
import { FileInfo, StateViewer, TreeSheetViewTab } from '../../common/main-viewer-common';

export default class StateMultiViewer {
  private listItemsView: FileInfo[];

  private mapState = new Map<string, StateViewer>();

  public currentTabNameLeftPanel: TreeSheetViewTab = TreeSheetViewTab.Tree;

  private defaultStateViewer: StateViewer = {
    leftTabName: TreeSheetViewTab.Tree,
    statePropertyPanel: true,
  };

  constructor(listItems: FileInfo[]) {
    this.listItemsView = listItems;
  }

  getViewerState(viewId: string): StateViewer {
    return this.mapState.get(viewId);
  }

  setViewerState(viewId: string, state: StateViewer = null): void {
    const stateView = state ?? this.defaultStateViewer;
    this.mapState.set(viewId, stateView);
  }

  updatePropertiesState(viewId: string, state: StateViewer): void {
    const currentViewerState = this.getViewerState(viewId);
    const newState = currentViewerState ? { ...currentViewerState, ...state } : state;
    this.mapState.set(viewId, newState);
  }

  deleteViewerState(viewId: string): void {
    this.mapState.delete(viewId);
  }

  log() {
    this.mapState.forEach((value, key) => {
      console.log([key, value]);
    });
  }
}
