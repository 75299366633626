import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fromEvent } from 'rxjs';
import { FileIdRev, FileInfo } from 'src/app/core/common/main-viewer-common';
import { KeyCode } from 'src/app/core/enums/keycode';
import { DataFileList } from 'src/app/shared/services/filelist.service';

@Component({
  selector: 'app-dialog-close-file-misscache',
  templateUrl: './dialog-close-file-misscache.component.html',
  styleUrls: ['./dialog-close-file-misscache.component.scss'],
})
export class DialogCloseFileMisscacheComponent implements OnInit {

  public fileList: DataFileList[];

  buttonOk = 'OK';

  constructor(
    public dialogRef: MatDialogRef<DialogCloseFileMisscacheComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataDialogCloseFileMisscacheComponent,

) { }

  ngOnInit(): void {
  }

  handleCloseFile(event : MouseEvent) {
    event.preventDefault();
  }
}
export interface DataDialogCloseFileMisscacheComponent {
  viewActive : any ;
  dataListFile: any;
  fileListsService: any
}
