/* eslint-disable prefer-destructuring */
import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IMarkupGroup, MarkupEntity } from 'src/app/core/common/markups';
import { EventsService } from 'src/app/core/services/events.service';
import { UtilExtend } from 'src/app/core/utils/util-extend';
import { DataObjectEntity, ObjectType } from 'src/app/shared/class/global';
import { DialogExtendService } from 'src/app/shared/services/dialog-extend.service';

export enum ModeUpdate {
  EntitiesActive = 1,
  EntitiesDisable,
  EntitiesSelected,
  GroupActive,
  GroupSelected,
  DeSelectGroup,
  DeSelectEntities,
  EntitiesRotate
}

@Injectable({
  providedIn: 'root',
})
export class MarkupsUiService {
  public showMarkupGroupDetail$ = new EventEmitter<boolean>();

  public showMarkupGroupIssue$ = new EventEmitter<IMarkupGroup>();

  public showMarkupGroupEdit$ = new EventEmitter<IMarkupGroup>();

  public eventShowMarkupGroupDetail = new BehaviorSubject<IMarkupGroup>(undefined);

  public markupEntitiesActive$ = new BehaviorSubject<string[]>([]);

  public mapEntitiesActive = new Map<string, string>();

  public markupEntitiesSelected$ = new BehaviorSubject<string[]>([]);

  public mapEntitiesSelected = new Map<string, string>();

  public markupGroupsSelected$ = new BehaviorSubject<string[]>([]);

  public mapGroupsSelected = new Map<string, string>();

  public markupGroupActive$ = new BehaviorSubject<string[]>([]);

  public mapGroupActive = new Map<string, string>();

  public markupEntitiesDisable$ = new BehaviorSubject<string[]>([]);

  public mapEntitiesDisable = new Map<string, string>();

  public markupEntitiesRotate$ = new BehaviorSubject<string[]>([]);

  public mapEntitiesRotate = new Map<string, string>();

  public currentMarkupDataHover: DataObjectEntity; // markup or group

  // public lastMarkupSelected: MarkupEntity;

  public firstMarkupSelected: MarkupEntity;

  // public lastMarkupGroupSeledted: IMarkupGroup;

  public firstMarkupGroupSeledted: IMarkupGroup;

  public itemStartMultiSelectGroup: IMarkupGroup = null;

  public itemStartMultiSelectEntity: MarkupEntity = null;

  canDeSelectOthors: boolean = true;

  isDeselect: any = true;

  public mapEntitiesSelectedWithView = new Map<string, string[]>();

  public mapEntitySelectedFirstWithView = new Map<string, string>();

  constructor(
    private dialogService: DialogExtendService,
  ) {
    this.init();
  }

  private init() {
    EventsService.selectedMarkupItemEvent.subscribe((markupId) => {
      this.activedMarkup(markupId);
    });
    EventsService.deselectedMarkupItemEvent.subscribe((markupId) => {
      this.deselectedMarkup(markupId);
    });
    EventsService.on('hoopsClearSelected', () => {
      this.clearSelected();
    });
  }

  clearSelected() {
    this.clearMap(ModeUpdate.EntitiesSelected);
    this.canDeSelectOthors = true;
  }

  setIsDeselect(value: boolean) {
    this.isDeselect = value;
  }

  updateMap(itemId: string | string[], mode: ModeUpdate, force: boolean = false, isDeselect: boolean = true) {
    if (force) return;
    const { map, obser } = this.createMap(mode);
    switch (mode) {
      case ModeUpdate.EntitiesSelected:
      case ModeUpdate.GroupSelected:
        if (Array.isArray(itemId)) {
          if (itemId.length) {
            itemId.forEach((id) => {
              !map.has(id) && map.set(id, id);
            });
          } else {
            map.clear();
          }
        } else {
          !map.has(itemId as string) && map.set(itemId as string, itemId as string);
        }
        break;
      case ModeUpdate.DeSelectGroup:
      case ModeUpdate.DeSelectEntities:
        if (Array.isArray(itemId)) {
          if (itemId.length) {
            itemId.forEach((id) => {
              if (map.has(id)) {
                map.delete(id as string);
              }
            });
          }
        } else if (map.has(itemId as string)) {
          map.delete(itemId as string);
        }

        break;
      case ModeUpdate.EntitiesActive:
        {
          const isActive = map.has(itemId as string);
          if (isActive) {
            map.delete(itemId as string);
          } else {
            isDeselect && this.deSelectOthors(itemId as string, ModeUpdate.EntitiesSelected);
            const items = Array.isArray(itemId) ? itemId : [itemId];
            items.forEach((id) => map.set(id, id));
          }
        }
        break;
      default:
      {
        map.clear();
        const items = Array.isArray(itemId) ? itemId : [itemId];
        items.forEach((id) => map.set(id, id));
      }
    }
    const arrResult = Array.from(map.values());
    obser.next(arrResult);
  }

  deSelectOthors(itemId: string, mode: ModeUpdate) {
    const { map, obser } = this.createMap(mode);
    if (mode === ModeUpdate.EntitiesSelected || mode === ModeUpdate.GroupSelected) {
      map.clear();
      map.set(itemId, itemId);
      const arrResult = Array.from(map.values());
      obser.next(arrResult);
    }
  }

  updateCheckbox(itemId: string, isChecked: boolean, mode: ModeUpdate) {
    if (!isChecked) {
      const { map, obser } = this.createMap(mode);
      if (mode === ModeUpdate.EntitiesSelected || mode === ModeUpdate.GroupSelected) {
        map.delete(itemId);
        const arrResult = Array.from(map.values());
        obser.next(arrResult);
      }
    }
  }

  disabledEntities(itemsId: string[], isDisable: boolean) {
    if (itemsId) {
      itemsId.forEach((item) => {
        if (isDisable) {
          this.mapEntitiesDisable.set(item, item);
        } else if (this.mapEntitiesDisable.has(item)) {
          this.mapEntitiesDisable.delete(item);
        }
      });
      const val = Array.from(this.mapEntitiesDisable.values());
      this.markupEntitiesDisable$.next(val);
    }
  }

  rotatedEntities(itemsId: string[], isRotate: boolean) {
    itemsId.forEach((item) => {
      if (isRotate) {
        this.mapEntitiesRotate.set(item, item);
      } else if (this.mapEntitiesRotate.has(item)) {
        this.mapEntitiesRotate.delete(item);
      }
    });
    const val = Array.from(this.mapEntitiesRotate.values());
    this.markupEntitiesRotate$.next(val);
  }

  checkIsHideAll(itemsId: MarkupEntity[]) {
    if (itemsId) {
      const { length } = itemsId;
      for (let i = 0; i < length; i += 1) {
        const item = this.mapEntitiesDisable.get(itemsId[i].uniqueId);
        if (!item) return false;
      }
    }
    return true;
  }

  clearMap(mode: ModeUpdate, isCleanLastMarkup = true, isDeselectAllMeasure? : boolean, listIdMeasureSelect?: string | string[]) {
    const { map, obser } = this.createMap(mode);
    if (isDeselectAllMeasure) {
      const obMeasure = [];
      const keysToDelete = new Set();
      map.forEach((value, key) => {
        if (listIdMeasureSelect.includes(value)) {
          keysToDelete.add(key);
        } else {
          obMeasure.push(value);
        }
      });
      keysToDelete.forEach((key : string) => {
        map.delete(key);
      });
      obser.next(obMeasure);
    } else {
      map.clear();
      // this.lastMarkupEntitySelected = null;
      if (isCleanLastMarkup) {
        this.firstMarkupSelected = null;
      }
      this.firstMarkupGroupSeledted = null;
      obser.next([]);
    }
  }

  /**
   * get map & obser of 'ModeUpdate'
   * @param mode
   */
  private createMap(mode: ModeUpdate) {
    let map: Map<string, string>;
    let obser: BehaviorSubject<string[]>;
    switch (mode) {
      case ModeUpdate.EntitiesActive:
        map = this.mapEntitiesActive;
        obser = this.markupEntitiesActive$;
        break;
      case ModeUpdate.DeSelectEntities:
      case ModeUpdate.EntitiesSelected:
        map = this.mapEntitiesSelected;
        obser = this.markupEntitiesSelected$;
        break;
      case ModeUpdate.GroupActive:
        map = this.mapGroupActive;
        obser = this.markupGroupActive$;
        break;
      case ModeUpdate.DeSelectGroup:
      case ModeUpdate.GroupSelected:
        map = this.mapGroupsSelected;
        obser = this.markupGroupsSelected$;
        break;
      case ModeUpdate.EntitiesDisable:
        map = this.mapEntitiesDisable;
        obser = this.markupEntitiesDisable$;
        break;
      case ModeUpdate.EntitiesRotate:
        map = this.mapEntitiesRotate;
        obser = this.markupEntitiesRotate$;
        break;
      default:
        break;
    }
    return { map, obser };
  }

  activedMarkup(itemId: string) {
    // this.mapEntitiesActive.clear();
    // this.mapEntitiesActive.set(itemId, itemId);
    // const val = Array.from(this.mapEntitiesActive.values());
    // this.markupEntitiesActive$.next(val);
    if (this.canDeSelectOthors) {
      this.updateMap(itemId, ModeUpdate.EntitiesActive, false, false);
      this.updateMap(itemId, ModeUpdate.EntitiesSelected);
    }
    this.canDeSelectOthors = true;
    // remove auto scroll
    // UtilExtend.scrollToElementById(`markup-entity-${itemId}`);
  }

  deselectedMarkup(itemId: string) {
    const listItemSelected = [];
    if (itemId) {
      this.dialogService.closeCurrentDialog();
      this.mapEntitiesSelected.delete(itemId);
      this.mapEntitiesSelected.forEach((item) => {
        listItemSelected.push(item);
      });
      this.markupEntitiesSelected$.next(listItemSelected);
      return;
    }
    const newListItemSelected = listItemSelected;
    this.mapEntitiesSelected.clear();
    this.markupEntitiesSelected$.next(newListItemSelected);
  }

  getCurrentMarkupGroup(): IMarkupGroup {
    return this.eventShowMarkupGroupDetail.value;
  }

  /**
   * Lấy danh sach các MarkupEntityID được select
   * @param entities danh sach markupEntity
   * @param isPDF
   */
  multiSelectedMarkupEntities(entities: MarkupEntity[], curentMarkupEntities: DataObjectEntity, firstMarkupSelected?: MarkupEntity) {
    if (!this.firstMarkupSelected) {
      // eslint-disable-next-line prefer-destructuring
      if (firstMarkupSelected) {
        // this.firstMarkupSelected = firstMarkupSelected;
        this.updateFistSelect(firstMarkupSelected, ObjectType.markup);
      } else {
        // this.firstMarkupSelected = entities[0];
        this.updateFistSelect(entities[0], ObjectType.markup);
      }
    }
    if (this.itemStartMultiSelectEntity === null) {
      this.itemStartMultiSelectEntity = this.firstMarkupSelected;
    }
    if (this.itemStartMultiSelectEntity && this.currentMarkupDataHover) {
      const { length } = entities;
      // const indexStart = entities.findIndex((e) => e.uniqueId === this.itemStartMultiSelectEntity.uniqueId);
      // const indexCurrentHover = entities.findIndex((e) => e.uniqueId === this.currentMarkupDataHover.uniqueId);
      // const arr = [indexCurrentHover, indexStart];
      const arrId = this.getListMarkupSelectId(entities, curentMarkupEntities, this.firstMarkupSelected);
      const minIndex = Math.min(...arrId);
      const maxIndex = Math.max(...arrId);
      for (let index = 0; index < length; index++) {
        if (index >= minIndex && index <= maxIndex) {
          const entitySelectId = entities[index].uniqueId;
          const isDisable = this.mapEntitiesDisable.get(entitySelectId);
          if (!isDisable) {
            this.mapEntitiesSelected.set(entitySelectId, entitySelectId);
          }
        } else {
          this.mapEntitiesSelected.delete(entities[index].uniqueId);
        }
      }
      return Array.from(this.mapEntitiesSelected.values());
    }
    return null;
  }

  updateMarkupActive(markupid: string) {
    const temp = this.mapEntitiesActive.get(markupid);
    if (!temp) {
      this.mapEntitiesActive.clear();
      this.mapEntitiesActive.set(markupid, markupid);
      this.markupEntitiesActive$.next([markupid]);
    }
  }

  multiSelectedMarkupGroups(groups: IMarkupGroup[], currentGroup: IMarkupGroup) {
    if (!this.firstMarkupGroupSeledted) {
      this.firstMarkupGroupSeledted = groups[0];
      this.updateFistSelect(groups[0], ObjectType.group);
    }
    if (this.itemStartMultiSelectGroup === null) {
      this.itemStartMultiSelectGroup = this.firstMarkupGroupSeledted;
    }
    if (this.itemStartMultiSelectGroup && groups) {
      const { length } = groups;
      const indexStartSelected = groups.findIndex((e) => e.uniqueId === this.firstMarkupGroupSeledted.uniqueId);
      const indexCurrentHover = groups.findIndex((e) => e.uniqueId === this.currentMarkupDataHover.uniqueId);
      const arr = [indexCurrentHover, indexStartSelected];
      const minIndex = Math.min(...arr);
      const maxIndex = Math.max(...arr);
      for (let index = 0; index < length; index++) {
        const entitySelectId = groups[index].uniqueId;
        if (index >= minIndex && index <= maxIndex) {
          this.updateMap(entitySelectId, ModeUpdate.GroupSelected);
        } else {
          this.updateMap(entitySelectId, ModeUpdate.DeSelectGroup);
        }
      }
    } else {
      this.updateMap(currentGroup.uniqueId, ModeUpdate.GroupSelected);
    }
  }

  getListMarkupSelectId(listMarkup: MarkupEntity[], curentMarkupSelected: DataObjectEntity, lastMarkupSelected: MarkupEntity) {
    const listId = listMarkup.map((item) => item.uniqueId);
    const arrIndex = [];
    const indexCurent = listId.indexOf(curentMarkupSelected.uniqueId);
    const indexLast = listId.indexOf(lastMarkupSelected.uniqueId);
    arrIndex.push(indexCurent, indexLast);
    return arrIndex;
  }

  updateFistSelect(data:any, type: ObjectType, isClear = false) {
    switch (type) {
      case ObjectType.markup:
        if (isClear) {
          this.firstMarkupSelected = null;
        } else {
          this.firstMarkupSelected = data;
        }
        break;
      case ObjectType.group:
        if (isClear) {
          this.firstMarkupGroupSeledted = null;
        } else {
          this.firstMarkupGroupSeledted = data;
        }
        break;
      default:
        break;
    }
  }
}
