/* eslint-disable max-classes-per-file */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { SystemConstants } from '../../common/system.constants';
import { APIService } from './base/APIBase.service';
import { FileInfo } from '../../common/main-viewer-common';
import Util from '../../utils/util';

@Injectable({
  providedIn: 'root',
})
export class WebServerService extends APIService {
  // da khai bao trong base
  // public BaseApi = `${SystemConstants.PROTOCOL}://${SystemConstants.SERVER_WEB.HOST}`;

  constructor(public http: HttpClient, public router: Router) {
    super(http, router);
  }

  public getURLFileOnly(file: FileInfo) {
    if (file.streamLocation) {
      let path = file.streamLocation.trim();
      path = path.replaceAll('\\', '/');
      const baseFileId = file.baseFileId.includes(Util.PRE_PRINT_ID) ? file.baseFileId.replace(Util.PRE_PRINT_ID, '') : file.baseFileId;
      const keySearch = `.${baseFileId}.${file.baseMajorRev}.${file.baseMinorRev}`.toLowerCase();
      const arr = path.split('/');
      let subpath = '';
      if (arr && arr.length > 0) {
        for (let i = 0; i < arr.length; i += 1) {
          if (arr[i].toLowerCase().endsWith(keySearch) || arr[i].toLowerCase().endsWith(`${keySearch}.pdf`)) {
            subpath = arr[i];
            for (let j = i + 1; j < arr.length; j += 1) subpath += `/${arr[j]}`;
            i = arr.length;
          }
        }
      }
      if (subpath !== '') {
        return `${this.BaseApi}/${subpath}/${Util.getFileName(file.filename)}`;
      }
    }
    return `${this.BaseApi}/${Util.getFileName(file.filename)}`;
  }
}
