// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainDialog {
  display: flex;
  flex-direction: column;
}
.mainDialog .title-dialog {
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.mainDialog .title-dialog h3 {
  font-weight: bold;
}
.mainDialog .actions-dialog {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainDialog .actions-dialog .left-div, .mainDialog .actions-dialog .right-div {
  width: 45%;
}
.mainDialog .actions-dialog .left-div button, .mainDialog .actions-dialog .right-div button {
  width: 100%;
}
.mainDialog .actions-dialog .left-div {
  margin-right: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/dialog/notification-dialog/notification-dialog.component.scss"],"names":[],"mappings":"AACA;EAGI,aAAA;EACA,sBAAA;AAFJ;AAGI;EACI,WAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,sBAAA;AADR;AAEQ;EACI,iBAAA;AAAZ;AAGI;EACI,WAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AADR;AAEQ;EACI,UAAA;AAAZ;AACY;EACI,WAAA;AAChB;AAEQ;EACI,kBAAA;AAAZ","sourcesContent":["\r\n.mainDialog {\r\n    // width: 20rem;\r\n    // height: 8rem;\r\n    display: flex;\r\n    flex-direction: column;\r\n    .title-dialog {\r\n        width: 100%;\r\n        height: 60%;\r\n        display: flex;\r\n        justify-content: center;\r\n        align-items: center;\r\n        text-align: center;\r\n        flex-direction: column;\r\n        h3{\r\n            font-weight: bold;\r\n        }\r\n    }\r\n    .actions-dialog {\r\n        width: 100%;\r\n        height: 40%;\r\n        display: flex;\r\n        justify-content: center;\r\n        align-items: center;\r\n        .left-div, .right-div {\r\n            width: 45%;\r\n            button {\r\n                width: 100%;\r\n            }\r\n        }\r\n        .left-div {\r\n            margin-right: 1rem;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
