export class SystemConstants {
  public static PROTOCOL: string = 'http';

  public static HOST_DEV: string = ''; // default send data to synergis service

  public static HOST_TYPE: string = ''; // default host type is anybim

  public static SERVER_WEB = {
    HOST: 'localhost:11180',
  };

  public static SERVER_STREAM = {
    HOST: 'localhost:11182',
    API: {
      HELP: {
        GET: 'api/help',
      },
      SHUTDOWN: {
        POST: 'api/shutdown',
      },
      STATUS: {
        GET: 'api/status',
      },
      SPAWNS: {
        GET: 'api/spawns',
      },
      SPAWN: {
        POST: 'api/spawn',
      },
      GC: {
        POST: 'api/gc',
      },
      CACHE: {
        POST: 'api/cache',
      },
      FILES: {
        GET: 'api/files',
        DOWNLOAD: 'api/download',
        CHECK_PATH_EXIST: 'api/checkpathexist',
      },
      INFORMATIONS: {
        GET: 'api/getinformations',
      },
    },
  };

  public static SERVER_ADEPT = {
    HOST: 'adept.anybim.vn/Synergis.WebApi',
    API: {
      login: {
        endpoint: 'Login',
        'http-command': 'POST',
        data: {
          username: 'cong',
          password: 'cong',
          cultureName: 'en-US',
          grant_type: 'password',
          client_id: 'Adept',
          forceLogin: false,
          connectionId: 'f14e31a8-1b1c-4dc2-8423-bf40b9d74cf7',
          timeZoneOffset: -420,
        },
      },
      refreshToken: {
        endpoint: 'Login',
        'http-command': 'POST',
        data: {
          loginName: 'cong',
          grant_type: 'refresh_token',
          refresh_token: '69d776c5ff994ebcacb2512272a7aac6', // decode base64
          client_id: 'Adept',
          connectionId: 'f14e31a8-1b1c-4dc2-8423-bf40b9d74cf7',
          autoLogin: null,
        },
      },
      logout: {
        endpoint: 'Api/Account/Logout',
        'http-command': 'GET',
      },
      userinfo: {
        endpoint: 'api/account/userinfo',
        'http-command': 'GET',
      },
      getInfoFile: {
        endpoint: 'api/View/AccessPath/',
        'http-command': 'GET',
      },
      saveMarkup: {
        endpoint: 'api/View/ViewList',
        'http-command': 'PUT',
      },
      publishPDFOpenInBrowser: {
        endpoint: 'api/Document/publishPDFOpenInBrowser',
        'http-command': 'POST',
      },
      publishPDFSave: {
        endpoint: 'api/Document/publishPDFSave',
        'http-command': 'PUT',
      },
      unlockMarkup: {
        endpoint: 'api/View/MarkupUnlock',
        'http-command': 'get',
      },
      getSection: {
        endpoint: 'api/View/ViewList',
        'http-command': 'GET',
      },
      saveUserSettings: {
        endpoint: 'api/account/viewersettings',
        'http-command': 'PUT',
      },
    },
  };

  public static SERVER_CONVERSION = {
    HOST: 'localhost:3001',
    API: {
      CACHE: {
        GET_CHECK: 'api/StreamFile/file',
        POST: 'api/StreamFile',
        GET_ALL: 'api/StreamFile',
        GET_ALL_PATH: 'api/StreamFile/folder',
        GET: 'api/StreamFile',
        DELETE: 'api/StreamFile',
        DELETES: 'api/StreamFile/ClearCaches',
        GET_FILE: 'api/StreamFile/adeptfileid',
        GET_LIST_FILE: 'api/StreamFile/arrayfile',
        GET_CACHE_BY_MODELID: 'api/StreamFile/cachebymodelid',
        GET_LIST_FILE_SUPPORT: 'api/StreamFile/ListFileSupport',
        CHANGE_FILE_VERSION: 'api/StreamFile/changeFileVersion',
        COMPARISON_2_FILES: 'api/StreamFile/Compare2Files',
        CHECK_FILE_CACHE_EXIST: 'api/StreamFile/checkFileCacheExist',
        TEXT_SEARCH: 'api/StreamFile/textsearch',
      },
      ATTACHMENT: {
        UPLOAD_ATTACHMENT: 'api/StreamFile/UpLoadAttachment',
        DOWNLOAD_ATTACHMENT: 'api/StreamFile/DownloadAttachment',
        REPLACE_ATTACHMENT: 'api/StreamFile/ReplaceAttachment',
        DELETE_ATTACHMENT: 'api/StreamFile/DeleteAttachment',
        DELETE_SUBDIR_ATTACHMENT: 'api/StreamFile/DeleteSubDirAttachment',
      },
      SECTION: {
        CREATE_VIEW_SECTION: 'api/view',
        UPDATE_VIEW_SECTION: 'api/view/updateView',
        GET_VIEW_SECTION: 'api/view/viewlist',
        IMPORT_RDL: 'api/StreamFile/rdl',
      },
      ZIP: {
        ZIP_FILE_INFO: 'api/StreamFile/readZipFileInfo',
        ZIP_ENTRY: 'api/StreamFile/openZipEntry',
      },
      PRINT: {
        PRINT_POST: 'api/StreamFile/printPdfCreation',
        PRINT_POST_NEW: 'api/StreamFile/printPdfCreationNew',
        GET_PRINT_PARAM: 'api/StreamFile/getPrintParams',
        UPLOAD_POST: 'api/StreamFile/upLoadBlobFile',
        MERGEPDFFILE_POST: 'api/StreamFile/mergepdffiles',
        RESIZE_PDF: 'api/StreamFile/resizePDF',
      },
      SYMBOL: {
        GET: 'api/StreamFile/Symbols',
        DOWNLOAD_SYMBOL: 'api/StreamFile/DownloadSymbol',
      },
      PEN: {
        GET: 'api/StreamFile/Pens',
      },
      FILE: {
        GET: 'api/StreamFile/getListFile',
        CheckListFilePrint: 'api/StreamFile/checkListFilePrint'
      },
    },
  };

  public static SERVER_BIM = {
    HOST: '',
    API: {
      MODEL_FILE: {
        GET_ALL: 'api/BIM/modelfile',
        GET_MODEL: (modelFileId: string) => `api/BIM/modeltree/${modelFileId}`,
        GET_PROPERTY: (prdOccId: string) => `api/BIM/po/property/${prdOccId}`,
        GET_CADVIEWS: (modelFileId: string) => `api/BIM/cadview/${modelFileId}`,
        GET_LAYERS: (modelFileId: string) => `api/BIM/layers/${modelFileId}`,
        GET_VIEWSAVED: (modelFileId: string) => `api/Markups/modelfile/views/${modelFileId}`,
        GET_ALL_MODEL: 'api/BIM/modelfile/getbyfileid',
      },
      MARKUP_VIEW: {
        POST: (modelFileId: string) => `api/Markups/markups/${modelFileId}`,
        POST_ARRAY: (modelFileId: string) => `api/Markups/markups/${modelFileId}/views`,
        GET_ALL: (modelFileId: string) => `api/Markups/modelfile/markups/${modelFileId}`,
        UPDATE: 'api/Markups/markups',
        UPDATE_ARRAY: (modelFileId: string) => `api/Markups/markups/${modelFileId}/views`,
        UPDATE_ELEMENT_INT: (uniqueId: string, elementName: string) => `api/Markups/markups/${uniqueId}/${elementName}/Int32`,
        UPDATE_ELEMENT_STR: (uniqueId: string, elementName: string) => `api/Markups/markups/${uniqueId}/${elementName}/string`,
        DELETE: (markupViewGuid: string) => `api/Markups/markups/${markupViewGuid}`,
      },
      MARKUP_3DS: {
        POST: (modelFileId: string) => `api/Markups/3ds/${modelFileId}`,
        GET_ALL: (modelFileId: string) => `api/Markups/3ds/${modelFileId}`,
        UPDATE: (modelFileId: string) => `api/Markups/3ds/${modelFileId}`,
        DELETE: (modelFileId: string) => `api/Markups/3ds/${modelFileId}`,
      },
      NODE_SETTING: {
        GET_ALL: (modelFileId: string) => `api/NodeSetting/model/${modelFileId}`,
        POST: (modelFileId: string) => `api/NodeSetting/model/${modelFileId}`,
        DELETE: (markupViewGuid: string) => `api/NodeSetting/model/${markupViewGuid}`,
      },
      MARKUP_ENTITIES: {
        GET_ALL: (modelFileId: string) => `api/MarkupEntity/${modelFileId}/0`,
      },
      MARKUP_GROUPS: {
        GET_ALL: (modelFileId: string) => `api/MarkupGroup/${modelFileId}`,
        POST: (modelFileId: string) => `api/MarkupGroup/${modelFileId}`,
        UPADTE: (groupId: string) => `api/MarkupGroup/${groupId}`,
        DELETE: (groupId: string) => `api/MarkupGroup/${groupId}`,
      },
      VIEWS: {
        POST_UPDATE: (modelFileId) => `api/View/${modelFileId}`,
        DELETE: (uniqueId) => `api/View/${uniqueId}`,
        GET_ALL: 'api/View/getbyfileidrev',
        POST_MODELVIEWS: (modelFileId) => `api/View/model/${modelFileId}`,
      },
      MARKUPS: {
        GET_ALL: (modelFileId) => `api/MarkupGroup/all/${modelFileId}`,
        GET_ALL_MERGER: 'api/MarkupGroup/all/getbyfileidrev',
        GET_MARKUP_COUNT: 'api/MarkupEntity/count',
        SAVE_ALL: (modelFileId) => `api/MarkupGroup/all/${modelFileId}`,
        SAVE_ALL_MERGER: 'api/MarkupGroup/all',
      },
      BOOKMARKS: {
        GET_ALL: (modelFileId: string) => `/api/SaveBookmark/model/${modelFileId}`,
        POST: (modelFileId: string) => `/api/SaveBookmark/model/${modelFileId}`,
        DELETE: (modelFileId: string) => `/api/SaveBookmark/model/${modelFileId}`,
      },
      ISSUE: {
        POST: 'api/MarkupGroup/issue',
        GET: (markupGroupId) => `api/MarkupGroup/issue/${markupGroupId}`,
      },
      FILESETTING: {
        GET: 'api/FileSetting/getbyfileidrev',
        DELETE: 'api/FileSetting/deletebyfileidrev',
        SAVE: 'api/FileSetting',
        SAVE_ALL: 'api/FileSetting/list',
      },
    },
  };

  public static SERVER_IDENTITY = {
    HOST: '',
    API: {
      IDENTITY: {
        LOGIN: 'api/Account/login',
        REGISTER: 'api/Account/register',
        LOGOUT: 'api/Account/logout',
        SETTING: 'api/Settings',
        GET_ALL_USER: 'api/Account/available',
      },
    },
  };

  public static RENDER_TYPE = {
    csr: 'csr_session',
    ssr: 'ssr_session',
    scs: 'scs_session',
  };

  public static RENDER: string = SystemConstants.RENDER_TYPE.csr;

  public static Feature = {
    Tree: 'TreeView',
    Thumbnail: 'ThumbnailIndexPage',
  };

  // public static MODEL_INFO = {
  //   NAME: '',
  //   MODEL_ID: '',
  // };
  public static SHOWED_LIST_FILE = [
    'pdf', 'docx', 'xlsx', 'pptx', 'hsf', 'obj', 'prc', 'ptx', 'stl', 'vrml', 'wrl',
    'sat', 'dwg', 'dxf', 'model', 'catdrawing', 'cgr', 'dae', 'neu', 'fbx', 'ifczip', 'igs',
    'iges', 'jt', 'prt', 'x_b', 'x_t', 'rfa', 'par', 'sldasm', 'sldprt', 'stp', 'step', 'stp.z',
    'skp', 'xyz', 'idw', 'iam', 'session', 'catpart', 'catproduct', '3dxml', 'asm', 'mi', 'drw',
    'frm', 'pic', 'ifc', 'rvt', '3dm', 'slddrw', 'vue', 'dwf', 'dgn', 'dft', 'cgm', 'ai', 'cg4',
    'gif', 'jpeg', 'jpg', 'png', 'tif', 'tiff', 'wmf', 'bmp', 'emf', 'emz', 'pcl', 'zip', 'doc',
    'xls', 'xlt', 'xlw', 'xlts', 'rtf', 'vsd', 'msg', 'ext', 'dwfx',
  ];

  public static VIEWER_MODE = {
    PDF: 'Foxit',
    HOOP: 'Hoops',
    ZIP: 'Zip',
  };

  public static EPSILON = 0.000001;

  public static PRINT_SCALE = 1;

  public static DPI = { x: 96, y: 96 };

  public static POINT_SIZE_MAX = 20;

  public static CUTTING_SECTION_OPACITY = 0.3;

  public static TIME_DELAY_DIALOG_CLOSE = 100;

  public static TIME_DELAY_TO_SHOW_WAITING = 200;

  public static POINT_SIZE_DEFAULT = 1; // old 5

  // The hamburger menu is always shown now. This setting now determines
  // if idividual menu items are displayed on the hamburger menu.
  public static HIDE_HAMBURGER_MENU = false;

  public static IGNORE_LINE_WEIGHT = 0;

  // [ADV-7726][phuong_td] [19/08/2024] Delete localhost from the list
  /***
   * SystemConstants.HOST_TYPE: Check value
   * The HOST_TYPE will not send the data to the ADEPT server
   */
  public static PREVENT_SEND_DATA = [
    'ANYBIM',
  ]

  /***
   * SystemConstants.HOST_TYPE: Check value
   * List of HOST_TYPE does not send socket data
   */
  public static PREVENT_SEND_SOCKET = [
    'ANYBIM', 'LOCALHOST', 'CONSTRUXIV', 'ELECTRON',
  ]

  public static MAX_STREAMER = 64;

  public static MIN_STREAMER = 2;

  public static MAX_STREAMER_INACTIVE = SystemConstants.MIN_STREAMER; // The maximum number of viewers allowed to hide is 2.

  public static MAX_NODE = 0; // Determine the export option is Full detail level or block reference level

  public static BOUNDING_PREVIEW_MODE = {
    all: Communicator.BoundingPreviewMode.All,
    ejected: Communicator.BoundingPreviewMode.Ejected,
    Instance: Communicator.BoundingPreviewMode.Instance,
    model: Communicator.BoundingPreviewMode.Model,
    none: Communicator.BoundingPreviewMode.None,
  };

  public static PDF_EXPORT = {
    RENDER: 'scs',
    STREAM_CUTOFF_SCALE: 0.0125,
    BOUNDING_PREVIEW: Communicator.BoundingPreviewMode.None,
    MEMORY_LIMIT: 0,
    MAX_TASK: 3,
    EXP_DIRECTLY: true,
    EXP_3DAS2D: true,
    PAGE_SIZE_TYPE: -1,
    PUBLIC_MODEL_2D: 0,
    PUBLIC_PAPERSPACE: 1,
    PUBLIC_MODEL: 2
  }

  public static MIN_CANVAS_DPI = 96;

  public static CANVAS_DPI = 288;

  public static PEN_OVERRIDE = null;

  public static MARGIN_BOUNDING_BOX = 10;

  public static Metric = [];

  public static Imperial = [];

  public static SCALE_VALUE = { // % unit
    FONT_WATERMARK: 2.8,
    FONTS_HEADER_FOOTER: 1.2, // 1.2
    FONT_HOOP_DIM: 1.6, // 1.6
    FONT_FOXIT_DIM: 2, // 2
    LINEWEIGHT_DIM: 0.15, // 0.15
  };

  // John Lee _ 04/04/2024 _ Used to allow editing of HeaderFooterWatermark in publishPDF dialog
  public static ALLOW_OVERRIDE_HEADER_FOOTER_WATERMARK: boolean = false;
}
