import { Injectable, EventEmitter } from '@angular/core';
import {
  debounceTime, tap,
} from 'rxjs/operators';
import { PanelStatusService } from 'src/app/shared/services/panel-status.service';
import {
  Subscription, fromEvent, BehaviorSubject,
} from 'rxjs';
import { Connect3dviewerService } from './connect-3dviewer.service';
import {
  TypeZoom, TypeLayout, ViewActive, FileInfo,
} from '../common/main-viewer-common';
import { PdfViewerManager } from '../../pdf/service/PdfViewerManager';
import { ModeLeftPanel } from '../common/3dviewer-common';
import { SystemConstants } from '../common/system.constants';
import { OnDestroyService } from '../interfaces/interfaces';
import { UtilExtend, LogType, LogTypeClass } from '../utils/util-extend';
import { MarkupsService } from './markups.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectMultiviewService implements OnDestroyService {
  public eventModelLoadSuccess$ = new EventEmitter<FileInfo>();

  public modelLoadSuccess$ = new EventEmitter<FileInfo>();

  public mapModelLoadSuccess = new Map<string, BehaviorSubject<FileInfo>>();

  public setViewLoadSuccess = new Set<string>();

  public eventSavedView$ = new EventEmitter<string>();

  public markupsService: MarkupsService;

  constructor(
    public connect3DViewerService: Connect3dviewerService,
    private panelStatusService: PanelStatusService,
  ) {
    UtilExtend.Log(LogType.Init, 'connect multi', LogTypeClass.Service);
    this.initEvent();
  }

  onDestroyService(): void {
    UtilExtend.Log(LogType.Destroy, 'connect 3d', LogTypeClass.Service);
    this.subWindowResizeEvent && this.subWindowResizeEvent.unsubscribe();
    this.connect3DViewerService.onDestroyService();
  }

  zoomAllView(option: TypeZoom) {
    this.connect3DViewerService.zoomAllView(option);
  }

  zoomOneView(option: TypeZoom) {
  }

  disposeViewer(viewId: string) {
    this.connect3DViewerService.close3DViewer(viewId);
    PdfViewerManager.destroyPDFById(viewId);
  }

  updateStatusPanel(viewId: string, layout: TypeLayout): boolean {
    const re = layout === TypeLayout.Full;
    // this.showPanelActive(viewId);
    return re;
  }

  showPanelActive(viewId: string) {
    // PdfViewerManager.activeViewer(viewId);
    // this.connect3DViewerService.showPanelActive(viewId);
  }

  refreshViewerCanvas() {
    setTimeout(() => {
      this.connect3DViewerService.eventResize$.emit();
      PdfViewerManager.redraw();
    }, 100);
  }

  updateLeftPanelState(mode: ModeLeftPanel = ModeLeftPanel.Content) {
    if (!this.panelStatusService.getLeftPanelStatus()) {
      this.panelStatusService.updateStatusLeftPanel(true);
      this.panelStatusService.setModeLeftPanel(mode);
    }
  }

  emitEventSavedView(viewSavedId: string) {
    this.eventSavedView$.emit(viewSavedId);
  }

  getTimeLoaded(viewActive: ViewActive): number {
    let re = 0;
    if (viewActive) {
      switch (viewActive.data?.converter) {
        case SystemConstants.VIEWER_MODE.HOOP:
          re = this.connect3DViewerService.mapTimerLoadedViewer.get(viewActive.viewId) ?? 0;
          break;
        case SystemConstants.VIEWER_MODE.PDF:
          re = PdfViewerManager.getViewer(viewActive.viewId)?.loadingTime ?? 0;
          break;
        default:
          return 0;
      }
    }
    return re;
  }

  getAllTimeLoaded(fileInfos:FileInfo[]): number {
    let re = 0;
    if (fileInfos) {
      fileInfos.forEach((file) => {
        switch (file?.converter) {
          case SystemConstants.VIEWER_MODE.HOOP:
            re += this.connect3DViewerService.mapTimerLoadedViewer.get(file.viewId) ?? 0;
            break;
          case SystemConstants.VIEWER_MODE.PDF:
            re += PdfViewerManager.getViewer(file.viewId)?.loadingTime ?? 0;
            break;
          default:
        }
      });
    }
    return re;
  }

  deleteSetViewIdSuccess(viewId: string) {
    this.setViewLoadSuccess.delete(viewId);
  }

  // checkMarkupStatus(viewId: string): boolean {
  //   const viewIdCurr = this.markupsService.getViewIdMap(viewId);
  //   const obserStatus = this.markupsService.mapObservableStatus.get(viewIdCurr);
  //   return obserStatus?.getValue();
  // }

  /** private */
  private initEvent() {
    this.subWindowResizeEvent = fromEvent(window, 'resize').pipe(debounceTime(200)).subscribe(() => {
      this.connect3DViewerService.updateResize();
    });
    this.addViewIdSuccess();
  }

  private addViewIdSuccess() {
    this.eventModelLoadSuccess$.pipe(
      tap((fileInfo) => {
        fileInfo && this.setViewLoadSuccess.add(fileInfo.viewId);
      }),
    ).subscribe();
  }

  public RequestCloseRightPanel(newStatus: boolean) {
    return this.markupsService.RequestCloseRightPanel(newStatus);
  }

  private subWindowResizeEvent: Subscription;
}
