import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { APIService } from './base/APIBase.service';
import { SystemConstants } from '../../common/system.constants';
import {
  CadViewData, CustomView, FileIdRev, ResponseModelFiles, ResponseViews,
} from '../../common/main-viewer-common';
import { Property3DViewer } from '../../common/3dviewer-common';
import { PermissionService } from '../permission.service';
import { Permission } from '../../common/permission';
import { MarkupGroupIssue, ResponseMarkups } from '../../common/markups';

@Injectable({
  providedIn: 'root',
})
export class BimService extends APIService {
  // da khai bao trong base
  // public BaseApi = `${SystemConstants.PROTOCOL}://${SystemConstants.SERVER_BIM.HOST}`;

  constructor(
    public http: HttpClient,
    private permissionService: PermissionService,
    public router: Router,
  ) { super(http, router); }

  getAllModelFile() {
    return this.get(SystemConstants.SERVER_BIM.API.MODEL_FILE.GET_ALL);
  }

  getModelTree(modelId: string) {
    return this.get(SystemConstants.SERVER_BIM.API.MODEL_FILE.GET_MODEL(modelId));
  }

  getProperty(prdOccId: string) {
    return this.getText(SystemConstants.SERVER_BIM.API.MODEL_FILE.GET_PROPERTY(prdOccId))
      .pipe(
        map((value) => JSON.parse(value)),
        map((arr: PropertyRespond[]) => arr[0]),
        map((value: PropertyRespond) => this.filterResponseProperty(value)),
      );
  }

  getAllModel(fileIdRev: FileIdRev): Observable<[ResponseModelFiles]> {
    return this.postResText(SystemConstants.SERVER_BIM.API.MODEL_FILE.GET_ALL_MODEL, fileIdRev)
      .pipe(map((res) => JSON.parse(res)));
  }

  handlerResponseText(value: string) {
    if (!value) return null;
    // eslint-disable-next-line no-useless-escape
    const re = /ObjectId\(\"(\w+)\"\)/gi;
    // eslint-disable-next-line no-useless-escape
    const re2 = /\"\w+\"\s+:\s+ObjectId\(\"(\w+)\"\)/gi;
    const newValue = value.replace(re2, '"dbId" : "$1"');
    try {
      const result = JSON.parse(newValue);
      return result;
    } catch (error) {
      return [];
    }
  }

  filterResponseProperty(value: PropertyRespond): Property3DViewer[] {
    let temp: any = value.Attributes.Attr;
    !value.Attributes.Attr.length && (temp = new Array(value.Attributes.Attr));
    return [{ Name: 'Name', Value: value.Name }, ...temp];
  }

  getCadViews(modelId: string) {
    return this.getText(SystemConstants.SERVER_BIM.API.MODEL_FILE.GET_CADVIEWS(modelId))
      .pipe(
        map((value) => JSON.parse(value)),
        map((value: CadViewData[]) => value),
      );
  }

  postMarkupView(modelId: string, data: any) {
    if (this.permissionService.hasPermissions([Permission.SAVE_NEW_MARKUP, Permission.SAVE_EXISTING_MARKUP], 'some')) {
      return this.postResText(SystemConstants.SERVER_BIM.API.MARKUP_VIEW.POST(modelId), data);
    }
    return of('');
  }

  postMarkupArrayView(modelId: string, data: any) {
    if (this.permissionService.hasPermissions([Permission.SAVE_NEW_MARKUP, Permission.SAVE_EXISTING_MARKUP], 'some')) {
      return this.postResText(SystemConstants.SERVER_BIM.API.MARKUP_VIEW.POST_ARRAY(modelId), data);
    }
    return of('');
  }

  putMarkupArrayView(modelId: string, data: any) {
    if (this.permissionService.hasPermissions(Permission.EDIT_MARKUP)) {
      return this.putText(
        SystemConstants.SERVER_BIM.API.MARKUP_VIEW.UPDATE_ARRAY(modelId),
        JSON.stringify(JSON.stringify(data)),
      );
    }
    return of('');
  }

  getAllMarkupView(modelId: string) {
    if (this.permissionService.hasPermissions(Permission.LOAD_MARKUP)) {
      return this.getText(SystemConstants.SERVER_BIM.API.MARKUP_VIEW.GET_ALL(modelId)).pipe(
        map((value) => this.handlerResponseText(value)),
      );
    }
    return of('');
  }

  updateMarkupView(data: any) {
    if (this.permissionService.hasPermissions(Permission.EDIT_MARKUP)) {
      return this.putText(SystemConstants.SERVER_BIM.API.MARKUP_VIEW.UPDATE, JSON.stringify(data));
    }
    return of('');
  }

  updateMarkupViewElementInt(uniqueId: string, elementName: string, value: number) {
    return this.putText(SystemConstants.SERVER_BIM.API.MARKUP_VIEW.UPDATE_ELEMENT_INT(uniqueId, elementName), value);
  }

  updateMarkupViewElementString(uniqueId: string, elementName: string, value: string) {
    return this.putText(SystemConstants.SERVER_BIM.API.MARKUP_VIEW.UPDATE_ELEMENT_STR(uniqueId, elementName), value);
  }

  deleteMarkupView(guid: string) {
    if (this.permissionService.hasPermissions(Permission.DELETE_MARKUP)) {
      return this.deleteResText(SystemConstants.SERVER_BIM.API.MARKUP_VIEW.DELETE(guid));
    }
    return of('');
  }

  // #region Markup3ds
  postMarkup3Ds(modelId: string, data: any) {
    if (this.permissionService.hasPermissions(Permission.CAN_SAVE_REDLINE)) {
      return this.postResText(SystemConstants.SERVER_BIM.API.MARKUP_3DS.POST(modelId), data);
    }
    return of('');
  }

  getAllMarkup3Ds(modelId: string) {
    if (this.permissionService.hasPermissions(Permission.LOAD_MARKUP)) {
      return this.getText(SystemConstants.SERVER_BIM.API.MARKUP_3DS.GET_ALL(modelId)).pipe(
        map((value) => {
          if (value) {
            this.handlerResponseText(value);
          }
          return value;
        }),
      );
    }
    return of('');
  }

  updateMarkup3Ds(modelId: string, data: any) {
    if (this.permissionService.hasPermissions(Permission.EDIT_MARKUP)) {
      return this.putText(SystemConstants.SERVER_BIM.API.MARKUP_3DS.UPDATE(modelId), data);
    }
    return of('');
  }

  deleteMarkup3Ds(guid: string) {
    if (this.permissionService.hasPermissions(Permission.DELETE_MARKUP)) {
      return this.deleteResText(SystemConstants.SERVER_BIM.API.MARKUP_3DS.DELETE(guid));
    }
    return of('');
  }
  // #endregion Markup3ds

  getBookmarks(modelId: string) {
    return this.getText(SystemConstants.SERVER_BIM.API.BOOKMARKS.GET_ALL(modelId)).pipe(
      map((value) => this.handlerResponseText(value)),
    );
  }

  postBookmarks(modelId: string, data: any) {
    return this.postResText(SystemConstants.SERVER_BIM.API.BOOKMARKS.POST(modelId), data);
  }

  getNodeSetting(modelId: string) {
    return this.getText(SystemConstants.SERVER_BIM.API.NODE_SETTING.GET_ALL(modelId)).pipe(
      map((value) => this.handlerResponseText(value)),
    );
  }

  postNodeSetting(modelId: string, data: any) {
    return this.postResText(SystemConstants.SERVER_BIM.API.NODE_SETTING.POST(modelId), data);
  }

  deleteNodeSetting(modelId: string) {
    return this.deleteResText(SystemConstants.SERVER_BIM.API.NODE_SETTING.DELETE(modelId));
  }

  /** Markup entities - group api */
  getAllMarkups<T>(modelId: string): Observable<T> {
    if (this.permissionService.hasPermissions(Permission.LOAD_MARKUP)) {
      return this.getText(SystemConstants.SERVER_BIM.API.MARKUPS.GET_ALL(modelId))
        .pipe(map((res) => JSON.parse(res)));
    }
    return of({} as T);
  }

  saveAllMarkups(modelId: string, data: any) {
    if (this.permissionService.hasPermissions(Permission.LOAD_MARKUP)) {
      return this.postResText(SystemConstants.SERVER_BIM.API.MARKUPS.SAVE_ALL(modelId), data);
    }
    return of('');
  }

  saveAllMarkupsMerger(data: any) {
    if (this.permissionService.hasPermissions(Permission.LOAD_MARKUP)) {
      return this.postResText(SystemConstants.SERVER_BIM.API.MARKUPS.SAVE_ALL_MERGER, data);
    }
    return of('');
  }

  getAllMarkupMerger(data: FileIdRev): Observable<ResponseMarkups> {
    if (this.permissionService.hasPermissions(Permission.LOAD_MARKUP)) {
      return this.postResText(SystemConstants.SERVER_BIM.API.MARKUPS.GET_ALL_MERGER, data)
        .pipe(map((res) => JSON.parse(res)));
    }
    return of({} as ResponseMarkups);
  }

  getNumberOfMarkupInFile(data: FileIdRev): Observable<number> {
    return this.postResText(SystemConstants.SERVER_BIM.API.MARKUPS.GET_MARKUP_COUNT, data)
      .pipe(map((value) => parseInt(value, 10)));
  }
  /** end markup - group */

  /** Issue */
  postMarkupGroupIssue(data: MarkupGroupIssue) {
    return this.post(SystemConstants.SERVER_BIM.API.ISSUE.POST, data);
  }

  getMarkupGroupIssue(markupGroupId: string): Observable<MarkupGroupIssue> {
    return this.get(SystemConstants.SERVER_BIM.API.ISSUE.GET(markupGroupId));
  }
  /** end issue */

  /** Views */
  getAllViews(fileIdRev: FileIdRev): Observable<ResponseViews> {
    return this.postResText(SystemConstants.SERVER_BIM.API.VIEWS.GET_ALL, fileIdRev)
      .pipe(map((res) => JSON.parse(res)));
  }

  postAndUpdateViews(modeFileId: string, data: any) {
    return this.postResText(SystemConstants.SERVER_BIM.API.VIEWS.POST_UPDATE(modeFileId), data);
  }

  postAndUpdateModelViews(modeFileId: string, data: any) {
    return this.postResText(SystemConstants.SERVER_BIM.API.VIEWS.POST_MODELVIEWS(modeFileId), data);
  }

  deleteSavedView(uniqueId: string) {
    return this.deleteResText(SystemConstants.SERVER_BIM.API.VIEWS.DELETE(uniqueId));
  }
  /** end views */

  // #region File setting
  getFileSetting(fileIdRev: FileIdRev): Observable<any> {
    return this.postResText(SystemConstants.SERVER_BIM.API.FILESETTING.GET, fileIdRev)
      .pipe(map((value) => this.handlerResponseText(value)));
  }

  getLayers(modeFileId: string): Observable<any> {
    return this.getText(SystemConstants.SERVER_BIM.API.MODEL_FILE.GET_LAYERS(modeFileId))
      .pipe(
        map((value) => JSON.parse(value)),
      );
  }

  async deleteFileSetting(fileIdRev: FileIdRev) {
    const res = await this.postOnly(SystemConstants.SERVER_BIM.API.FILESETTING.DELETE, fileIdRev).toPromise();
    return res;
  }

  saveFileSetting(data: any) {
    return this.postResText(SystemConstants.SERVER_BIM.API.FILESETTING.SAVE, data);
  }

  saveFileSettings(data: any) {
    return this.postResText(SystemConstants.SERVER_BIM.API.FILESETTING.SAVE_ALL, data);
  }
  // #endregion
}

interface PropertyRespond {
  Attributes: {
    Attr: PropertyNode[]
  };
  Name: string;
  CADView?: {};
}

export interface PropertyNode {
  Name: string;
  Value: string;
}
