import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Connect3dviewerService } from 'src/app/core/services/connect-3dviewer.service';
import { ModeLeftPanel } from 'src/app/core/common/3dviewer-common';
import { UtilExtend, LogType, LogTypeClass } from 'src/app/core/utils/util-extend';
import { PdfViewerManager } from '../../pdf/service/PdfViewerManager';

export enum PanelName{
  'DocumentPanel',
  'ContentPanel',
    'PageIndex',
    'Object',
    'ViewPanel',
    'Setting'
}

@Injectable({
  providedIn: 'root',
})
export class PanelStatusService {
  constructor(
    private connectViewerService: Connect3dviewerService,
  ) {
    UtilExtend.Log(LogType.Init, 'panel status', LogTypeClass.Service);
  }

  private showLeftPanel = new BehaviorSubject<boolean>(false);

  private showRightPanel = new BehaviorSubject<boolean>(true);

  public showRightPanel$ = this.showRightPanel.asObservable();

  private showBottomPanel = new BehaviorSubject<boolean>(false);

  public showLeftPanel$ = this.showLeftPanel.asObservable();

  public showBottomPanel$ = this.showBottomPanel.asObservable();

  private showTab = new BehaviorSubject<ModeLeftPanel>(ModeLeftPanel.Content);

  public showTab$ = this.showTab.asObservable();

  public modeLeftPanel$ = new BehaviorSubject<ModeLeftPanel>(undefined);

  public modeLeftPanelObs$ = this.modeLeftPanel$.asObservable();

  public modeShowHoopOrPdf$ = new BehaviorSubject<number>(undefined); // 1: hoop, 2: pdf. Su dung cho Content & View panel

  public mapValueResize = new Map<string, number>();

  private showTabSearching = new BehaviorSubject<boolean>(false);

  public showTabSearching$ = this.showTabSearching.asObservable();

  private mapPanelWithIndexTab = new Map<PanelName, number>();

  public disableOpenPanel: boolean = false;

  updateStatusLeftPanel(value: boolean) {
    this.updateViewerResize();
    this.setShowLeftPanel(value);
  }

  updateStatusRightPanel(value: boolean) {
    this.updateViewerResize();
    this.showRightPanel.next(value);
  }

  updateStatusBottomPanel(value: boolean) {
    this.updateViewerResize();
    this.showBottomPanel.next(value);
  }

  toggleLeftPanelStatus() {
    const curr = this.showLeftPanel.value;
    this.updateViewerResize();
    this.setShowLeftPanel(!curr);
  }

  showLeftPanelStatus(isShow: boolean) {
    this.updateViewerResize();
    this.setShowLeftPanel(isShow);
  }

  setShowLeftPanel(isShow: boolean) {
    if (this.disableOpenPanel) {
      this.showLeftPanel.next(false);
    } else {
      this.showLeftPanel.next(isShow);
    }
  }

  toggleRightPanelStatus() {
    const curr = this.showRightPanel.value;
    this.updateViewerResize();
    this.showRightPanel.next(!curr);
  }

  toggleBottomPanelStatus() {
    const curr = this.showBottomPanel.value;
    this.updateViewerResize();
    this.showBottomPanel.next(!curr);
  }

  getBottomPanelStatus() {
    return this.showBottomPanel.value;
  }

  getLeftPanelStatus() {
    return this.showLeftPanel.value;
  }

  getRightPanelStatus() {
    return this.showRightPanel.value;
  }

  setModeLeftPanel(mode: ModeLeftPanel) {
    this.showTab.next(mode);
    this.modeLeftPanel$.next(mode);
  }

  getModeLeftPanel(): ModeLeftPanel {
    return this.showTab.value;
  }

  getValueFeature() {
    return this.showTab.value;
  }

  updateViewerResize() {
    PdfViewerManager.redraw();
    this.connectViewerService.eventResize$.emit();
  }

  getShowTabSearching() {
    return this.showTabSearching.value;
  }

  setShowTabSearching(status: boolean) {
    this.showTabSearching.next(status);
  }

  setMapPanelWithIndexTab(panel:PanelName, index:number) {
    this.mapPanelWithIndexTab.set(panel, index);
  }

  getMapPanelWithIndexTab(panel:PanelName) {
    return this.mapPanelWithIndexTab.get(panel);
  }
}
