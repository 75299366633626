// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainDialog {
  height: 8rem;
  display: flex;
  flex-direction: column;
}
.mainDialog .title-dialog {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.mainDialog .title-dialog h3 {
  font-weight: bold;
}
.mainDialog .actions-dialog {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainDialog .actions-dialog .left-div, .mainDialog .actions-dialog .right-div {
  width: 45%;
}
.mainDialog .actions-dialog .left-div button, .mainDialog .actions-dialog .right-div button {
  width: 100%;
}
.mainDialog .actions-dialog .left-div {
  margin-right: 1rem;
}
.mainDialog .block-ellipsis {
  display: -webkit-box;
  height: 100%;
  margin: 0 auto;
  line-height: 1;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/dialog/dialog-ok-cancel/dialog-ok-cancel.component.scss"],"names":[],"mappings":"AACA;EAEI,YAAA;EACA,aAAA;EACA,sBAAA;AADJ;AAEI;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,sBAAA;AAAR;AACQ;EACI,iBAAA;AACZ;AAEI;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAR;AACQ;EACI,UAAA;AACZ;AAAY;EACI,WAAA;AAEhB;AACQ;EACI,kBAAA;AACZ;AAEI;EAEI,oBAAA;EAEA,YAAA;EACA,cAAA;EAEA,cAAA;EACA,qBAAA;EAEA,gBAAA;EACA,uBAAA;AAJR","sourcesContent":["\r\n.mainDialog {\r\n    // width: 20rem;\r\n    height: 8rem;\r\n    display: flex;\r\n    flex-direction: column;\r\n    .title-dialog {\r\n        width: 100%;\r\n        height: 100%;\r\n        display: flex;\r\n        justify-content: center;\r\n        align-items: center;\r\n        text-align: center;\r\n        flex-direction: column;\r\n        h3{\r\n            font-weight: bold;\r\n        }\r\n    }\r\n    .actions-dialog {\r\n        width: 100%;\r\n        height: 50px;\r\n        display: flex;\r\n        justify-content: center;\r\n        align-items: center;\r\n        .left-div, .right-div {\r\n            width: 45%;\r\n            button {\r\n                width: 100%;\r\n            }\r\n        }\r\n        .left-div {\r\n            margin-right: 1rem;\r\n        }\r\n    }\r\n    .block-ellipsis {\r\n        // display: block;\r\n        display: -webkit-box;\r\n        // max-width: 100%;\r\n        height: 100%;\r\n        margin: 0 auto;\r\n        // font-size: 14px;\r\n        line-height: 1;\r\n        -webkit-line-clamp: 3;\r\n        // -webkit-box-orient: vertical;\r\n        overflow: hidden;\r\n        text-overflow: ellipsis;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
