/* eslint-disable import/no-extraneous-dependencies */
// eslint-disable-next-line import/no-extraneous-dependencies
import { OptionData } from 'src/app/shared/common/shared.common';
import cloneDeep from 'lodash/cloneDeep'; // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import
import { TextCharacteristics } from 'src/app/shared/components/right-toolbar/markup-format-flyout/markup-format-flyout.component';
import { MarkupFillType } from './markups';
import { iFont } from '../services/setting-data-manager.service';
import { UnitMultiplier } from 'src/app/shared/class/global';

export enum LineStyle{
  continous = 1,
  largeDash,
  smallDash,
  center,
  phantom,
  cloud,
  zigzag,
}

export enum ArrowStyle{
  none = 1,
  start,
  end,
  both,
}
export enum eOpenModel {
  model2D = 1,
  model
}
export default class ADSettingViewer3D {
  isSet = false;

  // General
  showAxis: boolean; // 3D Axis

  showCube: boolean; // 3D View Controller

  showDwgDgnLineWeight: boolean;

  projection: Communicator.Projection;

  isUseGradient: boolean = false;

  background3D: {top: Communicator.Color, topColor: string, bottom: Communicator.Color, bottomColor: string};

  drawingColor: {background: Communicator.Color, backgroundColor: string,
    sheet: Communicator.Color, sheetColor: string,
    shadowSheet: Communicator.Color, shadowSheetColor: string,
    showSheetBackground: boolean};

  monoChrome: boolean = false; // Color or Black White

  documentMonoChrome: boolean = false; // Color or Black White

  documentBackgroundColor: string;

  fontManager: iFont;

  displayValues: {xrayOpacity: number, ghostingOpacity: number};

  keyBoardNavihation: {left: string, right: string, up: string, down: string
                        , forward: string, backward: string
                        , forwardRun: string, backwardRun: string
                        , runMode: boolean, speech: number
                        , increaseSpeech: string, decreaseSpeech: string};

  markupDefaultSetting: {
    textSize: number,
    textColor: string,
    lineThickness: number,
    lineColor: string,
    arrowStyle: ArrowStyle,
    lineStyle: LineStyle,
    fillColor: string,
    fillType: MarkupFillType,
    fillOpacity: number,
    opacity: number,
    markupFontFamily: OptionData<string>,
    autoOpenMarkupMode: boolean,
    textStyle: TextCharacteristics,
  };

  // Effects
  showBackfaces: boolean;

  showCappingGeometry: boolean;

  enableFaceLineSelection: boolean;

  enableNodeSelection: boolean;

  showSelectionHonor: boolean;

  isRotateAroundCameraCenter: boolean;

  frameRate: number; // 0 - 240

  hiddenLineOpacity: number; // 0 - 1

  // Color
  ambientOccusion: boolean;

  ambientOccusionRadius: number;

  antiAliasing: boolean;

  bloom: boolean;

  bloomIntensityScale: number;

  bloomThresHold: number; // 0 - 100

  silhouetteEdges: boolean;

  reflectionPlanes: boolean;

  shadows: boolean;

  visibleDuringInteraction: boolean;

  blurSamples: number; // 0 - 100

  // Point cloud
  splats: boolean;

  splatSize: number;

  enableEyeDomeLight: boolean;

  splatMode: Communicator.PointSizeUnit;

  isCancle: boolean;

  openModel: eOpenModel;

  measureUnits: UnitMultiplier;

  constructor() {
    this.Init();
  }

  Init() {
    // General
    this.showAxis = true;

    this.showCube = true;

    this.showDwgDgnLineWeight = true;

    this.projection = Communicator.Projection.Orthographic;

    this.background3D = {
      top: Communicator.Color.white(),
      topColor: '#ffffff',
      bottom: Communicator.Color.white(),
      bottomColor: '#ffffff',
    };

    this.documentBackgroundColor = '#ffffff'; // Phuong: adjusted to sync with the hoop viewer

    this.drawingColor = {
      background: Communicator.Color.white(),
      backgroundColor: '#ffffff',
      sheet: Communicator.Color.white(),
      sheetColor: '#ffffff',
      shadowSheet: Communicator.Color.white(),
      shadowSheetColor: '#ffffff',
      showSheetBackground: false,
    };

    this.monoChrome = false;

    this.documentMonoChrome = false;

    this.fontManager = {
      fontFamily: { name: 'Arial', value: 'arial' },
      fontSize: 12,
      isBold: false,
      isItalic: false,
    };

    this.displayValues = { xrayOpacity: 15.0, ghostingOpacity: 5.0 }; // 0 - 100%: issues 1311

    this.keyBoardNavihation = {
      left: 'ArrowLeft',
      right: 'ArrowRight',
      up: 'ArrowUp',
      down: 'ArrowDown',
      forward: 'Q',
      backward: 'E',
      forwardRun: 'Q',
      backwardRun: 'E',
      runMode: false,
      speech: 9.0,
      increaseSpeech: '+',
      decreaseSpeech: '-',
    };

    // Effects
    this.showBackfaces = true;

    this.showCappingGeometry = true;

    this.enableFaceLineSelection = true;

    this.enableNodeSelection = true;

    this.showSelectionHonor = true;

    this.isRotateAroundCameraCenter = false;

    this.frameRate = 13; // 0 - 60: issues 1311

    this.hiddenLineOpacity = 0.2; // 0 - 1

    this.isUseGradient = false;

    // Color
    this.ambientOccusion = false;

    this.ambientOccusionRadius = 0.01;

    this.antiAliasing = true;

    this.bloom = false;

    this.bloomIntensityScale = 0.1;

    this.bloomThresHold = 60; // 0 - 100

    this.silhouetteEdges = false;

    this.reflectionPlanes = false;

    this.shadows = false;

    this.visibleDuringInteraction = false;

    this.blurSamples = 5; // 0 - inf

    // Point cloud
    this.splats = false;

    this.splatSize = 0.03;

    this.enableEyeDomeLight = true;

    this.splatMode = Communicator.PointSizeUnit.ProportionOfBoundingDiagonal;

    this.markupDefaultSetting = {
      markupFontFamily: { name: 'Arial', value: 'arial' },
      textSize: 12,
      textColor: '#ff0000',
      lineThickness: 1,
      lineColor: '#ff0000',
      autoOpenMarkupMode: true,
      arrowStyle: ArrowStyle.none,
      lineStyle: LineStyle.continous,
      fillColor: '#ff0000',
      fillType: MarkupFillType.None,
      fillOpacity: 50,
      opacity: 1,
      textStyle: {
        bold: false,
        italic: false,
        underline: false,
      },
    };
    this.isCancle = false;
    this.openModel = eOpenModel.model2D;
    this.measureUnits = UnitMultiplier.Millimeter;
  }

  clone() {
    return cloneDeep(this);
  }
}
