import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { SystemConstants } from '../../common/system.constants';
import { APIService } from './base/APIBase.service';
import Util from '../../utils/util';

@Injectable({
  providedIn: 'root',
})

/**
 * Connect Conversion Service of server
 */
export class ConversionService extends APIService {
  /**
   * BaseApi to config url
   * @type {string}
   */
  // da khai bao trong base
  // public BaseApi = `${SystemConstants.PROTOCOL}://${SystemConstants.SERVER_CONVERSION.HOST}`;

  /**
   * constructor ConversionService
   * @param {HttpClient} http
   * @param router
   */
  constructor(public http: HttpClient, public router: Router) {
    super(http, router);
  }

  /**
   * Get all info of Streaming File
   * @returns {Observable<any>}
   */
  public getAllCache() {
    return this.get(SystemConstants.SERVER_CONVERSION.API.CACHE.GET_ALL);
  }

  /**
   * Get caching info of Streaming File by path
   * @returns {Observable<any>}
   */
  public getAllCacheByPath(path: string) {
    return this.get(`${SystemConstants.SERVER_CONVERSION.API.CACHE.GET_ALL_PATH}?${Util.parseUrl({ path })}`);
  }

  /**
   * get info of Streaming file by name file
   * @param filename
   * @returns {Observable<any>}
   */
  public getCacheByName(filename) {
    return this.get(`${SystemConstants.SERVER_CONVERSION.API.CACHE.GET_CHECK}/${filename}`);
  }

  /**
   * get info of Streaming file by id file
   * @param id
   * @returns {Observable<any>}
   */
  public getCacheById(id) {
    return this.get(`${SystemConstants.SERVER_CONVERSION.API.CACHE.GET_CHECK}/${id}`);
  }

  /**
   * create Streaming file of a file
   * @param data
   * @param option
   * @returns {Observable<any>}
   */
  public createStreamFile(data, option) {
    return this.post(`${SystemConstants.SERVER_CONVERSION.API.CACHE.POST}?${Util.parseUrl(option)}`, data);
  }

  /**
   * change Streaming file version
   * @param data
   * @param newVersion
   * @returns {Observable<any>}
   */
  public changeVersionStreamFile(data, newVersion) {
    return this.post(`${SystemConstants.SERVER_CONVERSION.API.CACHE.CHANGE_FILE_VERSION}?${Util.parseUrl(newVersion)}`, data);
  }

  /**
   * clear Streaming file of a file
   * @param id
   * @returns {Observable<any>}
   */
  public clearStreamFile(id) {
    return this.delete(`${SystemConstants.SERVER_CONVERSION.API.CACHE.DELETE}/${id}`);
  }

  /**
   * clear Streaming files of a file
   * @param id
   * @returns {Observable<any>}
   */
  public clearStreamFiles(ids: string[]) {
    return this.post(`${SystemConstants.SERVER_CONVERSION.API.CACHE.DELETES}`, ids);
  }

  /**
   * get cache by file
   * @param file
   * @returns {Observable<any>}
   */
  getCacheByFile(file) {
    return this.post(`${SystemConstants.SERVER_CONVERSION.API.CACHE.GET_FILE}`, file);
  }

  /**
   * get cache by modelId
   * @param modelId
   * @returns {Observable<any>}
   */
  getCacheByModelId(modelId) {
    return this.get(`${SystemConstants.SERVER_CONVERSION.API.CACHE.GET_CACHE_BY_MODELID}?modelid=${modelId}`);
  }

  /**
   * get cache by list file
   * @param lstFile
   * @returns {Observable<any>}
   */
  getCacheByListFile(lstFile, isPdf: boolean = false) {
    return this.post(`${SystemConstants.SERVER_CONVERSION.API.CACHE.GET_LIST_FILE}?is_pdf=${isPdf}`, lstFile);
  }

  getRdlMarkups(data) {
    return this.post('/api/StreamFile/rdl', data);
  }

  createViewSection(data) {
    return this.postResText(`${SystemConstants.SERVER_CONVERSION.API.SECTION.CREATE_VIEW_SECTION}`, data);
  }

  getViewSection(id: string) {
    return this.get(`${SystemConstants.SERVER_CONVERSION.API.SECTION.GET_VIEW_SECTION}?${Util.parseUrl({ id })}`);
  }

  updateViewSection(id, metadata) {
    return this.post(`${SystemConstants.SERVER_CONVERSION.API.SECTION.UPDATE_VIEW_SECTION}?${Util.parseUrl({ id })}`, metadata);
  }

  deleteViewSection(id: string) {
    return this.delete(`${SystemConstants.SERVER_CONVERSION.API.SECTION.CREATE_VIEW_SECTION}/${id}`);
  }

  importRDL(data: string, overwrite: boolean = false) {
    return this.post(`${SystemConstants.SERVER_CONVERSION.API.SECTION.IMPORT_RDL}?${Util.parseUrl({ overwrite })}`, data);
  }

  postPrintParam(data) {
    return this.postResText(SystemConstants.SERVER_CONVERSION.API.PRINT.PRINT_POST, data);
  }

  // eslint-disable-next-line camelcase
  postPrintParamNew(data, exportDirect, export3dAs2d, overwrite, outputPngResolution) {
    return this.postResText(`${SystemConstants.SERVER_CONVERSION.API.PRINT.PRINT_POST_NEW}?${Util.parseUrl({
      exportDirect, export3dAs2d, overwrite, output_png_resolution: outputPngResolution,
    })}`, data);
  }

  async uploadBlob(blob: Blob, path, name) {
    const formData = new FormData();
    formData.append('blob', blob);
    const request = this.postBlob(
      `${SystemConstants.SERVER_CONVERSION.API.PRINT.UPLOAD_POST}?${Util.parseUrl({ path, name })}`, formData,
    );
    return request;
  }

  getPrintParam(id: string) {
    return this.get(`${SystemConstants.SERVER_CONVERSION.API.PRINT.GET_PRINT_PARAM}?${Util.parseUrl({ id })}`);
  }

  /**
   * read zip information
   * @param data
   * @param option
   * @returns {Observable<any>}
   */
  public readZipFileInfo(data) {
    return this.post(`${SystemConstants.SERVER_CONVERSION.API.ZIP.ZIP_FILE_INFO}`, data);
  }

  /**
   * create Streaming file of a file
   * @param data
   * @param option
   * @returns {Observable<any>}
   */
  public openZipEntry(data, option) { // createStreamFile
    return this.post(`${SystemConstants.SERVER_CONVERSION.API.ZIP.ZIP_ENTRY}?${Util.parseUrl(option)}`, data);
  }

  getListFileSupport() { // nghia_td ADV-5663 add new
    return this.get(`${SystemConstants.SERVER_CONVERSION.API.CACHE.GET_LIST_FILE_SUPPORT}`);
  }

  public Compare2Files(comparisonRequest: string) {
    return this.post(`${SystemConstants.SERVER_CONVERSION.API.CACHE.COMPARISON_2_FILES}`, comparisonRequest);
  }

  UpLoadAttachment(blob: Blob, subdir: string, name: string) {
    const formData = new FormData();
    formData.append('blob', blob);
    const request = this.postBlob(
      `${SystemConstants.SERVER_CONVERSION.API.ATTACHMENT.UPLOAD_ATTACHMENT}?${Util.parseUrl({ subdir, name })}`, formData,
    );
    return request;
  }

  ReplaceAttachment(blob: Blob, fullfilename: string) {
    const formData = new FormData();
    formData.append('blob', blob);
    const request = this.postBlob(
      `${SystemConstants.SERVER_CONVERSION.API.ATTACHMENT.REPLACE_ATTACHMENT}?${Util.parseUrl({ fullfilename })}`, formData,
    );
    return request;
  }

  DeleteAttachment(fullfilename: string) {
    return this.delete(`${SystemConstants.SERVER_CONVERSION.API.ATTACHMENT.DELETE_ATTACHMENT}?${Util.parseUrl({ fullfilename })}`);
  }

  DeleteSubDirAttachment(subdir: string) {
    return this.delete(`${SystemConstants.SERVER_CONVERSION.API.ATTACHMENT.DELETE_SUBDIR_ATTACHMENT}?${Util.parseUrl({ subdir })}`);
  }

  DownloadAttachment(fullfilename: string) {
    return this.getFile(`${SystemConstants.SERVER_CONVERSION.API.ATTACHMENT.DOWNLOAD_ATTACHMENT}?${Util.parseUrl({ fullfilename })}`);
  }

  getListSymbol() {
    return this.get(`${SystemConstants.SERVER_CONVERSION.API.SYMBOL.GET}`);
  }

  DownloadSymbol(fullfilename: string) {
    return this.getFile(`${SystemConstants.SERVER_CONVERSION.API.SYMBOL.DOWNLOAD_SYMBOL}?${Util.parseUrl({ fullfilename })}`);
  }

  getSymbolUrl(fullfilename: string) {
    return this.getUrl(`${SystemConstants.SERVER_CONVERSION.API.SYMBOL.DOWNLOAD_SYMBOL}?fullfilename=${fullfilename}`);
  }

  getListPens(type: string) {
    return this.get(`${SystemConstants.SERVER_CONVERSION.API.PEN.GET}?type=${type}`);
  }

  getListFile(path: string, type: string) {
    return this.get(`${SystemConstants.SERVER_CONVERSION.API.FILE.GET}?${Util.parseUrl({ path, type })}`);
  }

  checkFileCacheExist(data: any) {
    return this.post(`${SystemConstants.SERVER_CONVERSION.API.CACHE.CHECK_FILE_CACHE_EXIST}`, data);
  }

  textSearch(data: any) {
    const {
      newStream,
      keysearch,
      matchCase,
      matchWholeWord,
    } = data;
    return this.post(`${SystemConstants.SERVER_CONVERSION.API.CACHE.TEXT_SEARCH}?${Util.parseUrl(
      {
        keysearch,
        matchCase,
        matchWholeWord,
      },
    )}`, newStream);
  }

  mergePdfFile(data: string[]) {
    return this.post(`${SystemConstants.SERVER_CONVERSION.API.PRINT.MERGEPDFFILE_POST}`, data);
  }

  async resizePDF(blob: Blob, name: string, pageSize: string, layout: number) {
    const formData = new FormData();
    formData.append('blob', blob);
    const request = this.postBlob(
      `${SystemConstants.SERVER_CONVERSION.API.PRINT.RESIZE_PDF}?${Util.parseUrl({ name, pageSize, layout })}`, formData,
    );
    return request.toPromise();
  }

  checkFileSupportPrint(data: any) {
    return this.post(`${SystemConstants.SERVER_CONVERSION.API.FILE.CheckListFilePrint}`, data);
  }
}
