export enum KeyCode {
    num0 = '0',
    num1 = '1',
    num2 = '2',
    num3 = '3',
    num4 = '4',
    num5 = '5',
    num6 = '6',
    num7 = '7',
    num8 = '8',
    num9 = '9',
    F1 = 'F1',
    F5 = 'F5',
    F12 = 'F12',
    escape = 'Escape',
    enter = 'Enter',
    equals = '=',
    add = '+',
    p = 'p',
    P = 'P',
    f = 'f',
    F = 'F',
    g = 'g',
    G = 'G',
    a = 'a',
    A = 'A',
    z = 'z',
    Z = 'Z',
    y = 'y',
    Y = 'Y',
    s = 's',
    S = 'S',
    m = 'm',
    M = 'M',
    n = 'n',
    N = 'N',
    h = 'h',
    H = 'H',
    o = 'o',
    O = 'O',
    c = 'c',
    C = 'C',
    w = 'w',
    W = 'W',
    v = 'v',
    V = 'V',
    i = 'i',
    I = 'I',
    digit0 = '0',
    subtract = '-',
    minus = '_',
    pageUp = 'PageUp',
    pageDown = 'PageDown',
    arrowUp = 'ArrowUp',
    arrowDown = 'ArrowDown',
    arrowLeft = 'ArrowLeft',
    arrowRight = 'ArrowRight',
    home = 'Home',
    end = 'End',
    delete = 'Delete',
    BackQuote = 'Backquote',
    backQuote = '`',
    shift = 'Shift'
}
