import { IPermissions, IPrintHeader, IWatermark } from 'src/app/shared/class/global';
import { FileIdRev, FileInfo, UserSetting } from './main-viewer-common';
import { Permission, PermissionExtend, WaterMarkConfig } from './permission';
import { UtilExtend } from '../utils/util-extend';
import { SystemConstants } from './system.constants';

export interface AdeptInfor { // ViewParamsModel
  userDisplayName?: string;
  userId?: string;
  isDev?: boolean; // to detect Adept call or Local call
  userLoginName?: string;
  baseFileId: string;
  baseMajorRev: number;
  baseMinorRev: number;
  converter: string;
  fileName: string;
  originalFilePath: string;
  viewItemParameters: ViewItemParameters;
  userSettings?: UserSettings;
  viewId?: string;
  isCopy?: boolean;
}

export interface UserSettings {
  userId: string;
  language?: string;
  settings?: string;
  publish?: string;
}
export interface ViewItemParameters {
  baseFileId: string;
  baseMajorRev: number;
  baseMinorRev: number;
  fileName: string;
  originalFilePath: string;
  operation: any;
  autoLoadMarkup: boolean | any;
  loadFit: boolean | any;
  renderingColor: any;
  permissions: IPermissions;
  printHeader: IPrintHeader;
  waterMark: IWatermark;
  markupData: string;
  autovueRedlineConversionData: string;
  pageSizeType?: number;
  serverSideConvert: boolean;
}

export function createAdeptInfo(
  file: FileInfo,
  permiss: PermissionExtend[],
  waterMark: WaterMarkConfig,
  autovueRedlineConversionData = '',
  markupData = '',
  userName: string = '',
  userSettings: UserSettings = null,
): AdeptInfor {
  return {
    isDev: true,
    userLoginName: userName,
    baseFileId: file.baseFileId,
    baseMajorRev: file.baseMajorRev,
    baseMinorRev: file.baseMinorRev,
    converter: file.converter,
    fileName: file.filename,
    originalFilePath: file.originalFilePath,
    viewItemParameters: {
      baseFileId: file.baseFileId,
      baseMajorRev: file.baseMajorRev,
      baseMinorRev: file.baseMinorRev,
      fileName: file.filename,
      originalFilePath: file.originalFile,
      operation: UtilExtend.getValuePermission(permiss, Permission.OPERATION, true),
      autoLoadMarkup: UtilExtend.getValuePermission(permiss, Permission.AUTO_OPEN_MARKUP, true),
      loadFit: UtilExtend.getValuePermission(permiss, Permission.LOAD_FIT, true),
      renderingColor: UtilExtend.getValuePermission(permiss, Permission.RENDERING_COLOR, false),
      permissions: {
        canConvert: UtilExtend.getValuePermission(permiss, Permission.CAN_CONVERT, true),
        canPrint: UtilExtend.getValuePermission(permiss, Permission.CAN_PRINT, true),
        canRedline: UtilExtend.getValuePermission(permiss, Permission.CAN_REDLINE, true),
        canOpenRedline: UtilExtend.getValuePermission(permiss, Permission.OPEN_MARKUP, true),
        canEditRedline: UtilExtend.getValuePermission(permiss, Permission.EDIT_MARKUP, true),
        canDeleteRedline: UtilExtend.getValuePermission(permiss, Permission.DELETE_MARKUP, true),
        canSaveExistingRedline: UtilExtend.getValuePermission(permiss, Permission.SAVE_EXISTING_MARKUP, true),
        canSaveNewRedline: UtilExtend.getValuePermission(permiss, Permission.SAVE_NEW_MARKUP, true),
        canSaveToPDF: UtilExtend.getValuePermission(permiss, Permission.SAVE_TO_PDF, true),
        canCompare: UtilExtend.getValuePermission(permiss, Permission.CAN_COMPARE, true),
      },
      printHeader: waterMark.HEADER,
      waterMark: waterMark.WATERMARK,
      markupData,
      autovueRedlineConversionData,
      pageSizeType: SystemConstants.PDF_EXPORT.PAGE_SIZE_TYPE,
      serverSideConvert: file.serverSideConvert,
    },
    userSettings,
  };
}
