import { FlatTreeControl } from '@angular/cdk/tree';
import { FilesTreeFlatNode } from './web-worker.common';
import { FlatTreeDataSource } from '../class/class-3d-viewer/flattree-datasource';

export enum ModeLeftPanel {
  Viewer3D = 'viewer3d',
  Pdf = 'pdf',
  Files = 'files',
  Object = 'object',
  View = 'View',
  Content = 'Content',
}

export enum ModeShowNode {
  All = 1,
  Group = 2,
}

export enum ModeData {
  Custom = 'Custom',
  CadView = 'CadView',
}

export interface Property3DViewer {
  Name: string;
  Value: string | number;
  Type?: string;
  isDate?: boolean;
}

export interface DataControl {
  dataSource?: FlatTreeDataSource<FilesTreeFlatNode>;
  treeControl?: FlatTreeControl<FilesTreeFlatNode>;
  nodeIdMap?: Map<string, FilesTreeFlatNode>;
}

export enum ETypeView {
  MarkupView,
  CadView,
  CustormView,
  Group,
  Sheet, // 3D model & 2D sheet
}

export enum EModelType {
  Undefine = 0,
  DGN = 1,
  CAD = 2,
  INVENTOR = 3,
  SOLIDWORK = 4,
  DWF = 5,
}
