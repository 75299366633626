// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-panel-print {
  width: 600px;
}
.sub-panel-print .header {
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
  padding-top: 15px;
}
.sub-panel-print .header .mat-svg-icon-custom {
  margin-right: 15px;
}
.sub-panel-print .mat-svg-icon-custom {
  margin-left: auto;
}
.sub-panel-print .content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px 10px 0 15px;
}
.sub-panel-print .col-name {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.sub-panel-print .sub-account-col-name {
  margin-left: 20px;
}
.sub-panel-print .bold-title {
  color: #000000;
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
}
.sub-panel-print p {
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}
.sub-panel-print .actions-dialog {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 20px 10px 0;
  margin-top: 5px;
}
.sub-panel-print .actions-dialog .right-div {
  width: 10%;
}
.sub-panel-print .actions-dialog .right-div button {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/dialog/dialog-close-file-misscahe/dialog-close-file-misscache.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AAAE;EACE,aAAA;EACA,8BAAA;EACA,iBAAA;EACA,iBAAA;AAEJ;AADI;EACE,kBAAA;AAGN;AACE;EACE,iBAAA;AACJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,yBAAA;AAAJ;AAGE;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,mBAAA;AADJ;AAKE;EACE,iBAAA;AAHJ;AAME;EACE,cAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;AAJJ;AAME;EACE,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;AAJJ;AAOE;EACE,WAAA;EACA,WAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,wBAAA;EACA,eAAA;AALJ;AAMI;EACI,UAAA;AAJR;AAKQ;EACI,WAAA;AAHZ","sourcesContent":[".sub-panel-print {\r\n  width: 600px;\r\n  .header {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    margin-left: 15px;\r\n    padding-top: 15px;\r\n    .mat-svg-icon-custom {\r\n      margin-right: 15px;\r\n    }\r\n  }\r\n  \r\n  .mat-svg-icon-custom {\r\n    margin-left: auto;\r\n  }\r\n  \r\n  .content {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: flex-start;\r\n    padding: 10px 10px 0 15px;\r\n  }\r\n  \r\n  .col-name {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: flex-start;\r\n    align-items: center;\r\n  }\r\n  \r\n  \r\n  .sub-account-col-name {\r\n    margin-left: 20px;\r\n  }\r\n  \r\n  .bold-title{\r\n    color: #000000;\r\n    font-family: Avenir;\r\n    font-style: normal;\r\n    font-weight: 800;\r\n    font-size: 16px;\r\n  }\r\n  p{\r\n    font-family: Avenir;\r\n    font-style: normal;\r\n    font-weight: normal;\r\n    font-size: 16px;\r\n  }\r\n  \r\n  .actions-dialog {\r\n    width: 100%;\r\n    height: 40%;\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    align-items: center;\r\n    padding: 0px 20px 10px 0;\r\n    margin-top: 5px;\r\n    .right-div {\r\n        width: 10%;\r\n        button {\r\n            width: 100%;\r\n        }\r\n    }\r\n}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
