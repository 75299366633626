/* eslint-disable no-underscore-dangle */
/* eslint-disable no-loop-func */
import { MarkupMultiselectService } from '../services/markup-multiselect-service';

/* eslint-disable no-param-reassign */
export enum GripPoint {
  top = 0,
  topRight = 1,
  right = 2,
  bottomRight = 3,
  bottom = 4,
  bottomLeft = 5,
  left = 6,
  topLeft = 7,
}

export enum ExtendsType {
  top = 0,
  topRight = 1,
  right = 2,
  bottomRight = 3,
  bottom = 4,
  bottomLeft = 5,
  left = 6,
  topLeft = 7,
}

export interface IHtmlElementGripPoint {
  name: string;
  index: number;
  htmlElement: HTMLDivElement;
}

export class ADBaseRedlineBounding {
  private viewer: Communicator.WebViewer;

  public boundingBox: HTMLCanvasElement;

  private mapGripPoints: Map<string, IHtmlElementGripPoint> = new Map<string, IHtmlElementGripPoint>();

  private customGrippoints: HTMLDivElement[] = [];

  private currentPossition: Communicator.Point2 = null;

  private currentSize: Communicator.Point2 = null;

  public polygonPoint: Communicator.Point2[] = [];

  private gripPointDragMoveCallback;

  private gripPointDragStartCallback;

  private gripPointDragEndCallback;

  private boundingBoxDragStartCallback;

  private boundingBoxDragMoveCallback;

  private boundingBoxDragEndCallback;

  private boundingBoxDoubleClickCallback;

  private boundingBoxMouseWheelCallback;

  private boundingBoxMouseMoveCallback;

  private multiSelectService: MarkupMultiselectService = null;

  private dragging: boolean = false;

  private isHasGrippoints: boolean = true;

  private isCallOut:boolean=false;

  private deselectCallback;

  private rotate: number = 0;

  private centerPoint: Communicator.Point2 = null;

  private isChildBounding: boolean = false;

  pointDown: Communicator.Point2 = null;

  isDoubleClick: boolean = false;

  isMouseup: boolean = false;

  constructor(
    viewer: Communicator.WebViewer,
  ) {
    this.viewer = viewer;
    const _viewer: any = viewer;
    if (_viewer) {
      this.multiSelectService = _viewer.markupMultiselectService;
    }
  }

  createBoundingBox() {
    this.boundingBox = document.createElement('canvas');
    this.boundingBox.style.position = 'absolute';
    this.boundingBox.style.zIndex = '5';
    this.boundingBox.style.outline = '1px solid #5aa';
    this.boundingBox.style.pointerEvents = 'auto';
    this.boundingBox.style.cursor = 'move';
    this.centerPoint = new Communicator.Point2(0, 0);
    const redlineBounding = this;

    // Bounding box callback
    const bdBox = this.boundingBox;
    bdBox.onmousedown = (event) => {
      if (event && event.button === 0) {
        event.preventDefault();
        event.stopPropagation();
        let isMoving = false;
        const _viewer: any = this.viewer;
        if (_viewer) {
          this.multiSelectService = _viewer.markupMultiselectService;
        }
        redlineBounding.dragging = true;
        const parent = bdBox.parentElement;
        parent.style.pointerEvents = 'auto';
        let point = new Communicator.Point2(parseInt(bdBox.style.left, 10) + event.offsetX,
          parseInt(bdBox.style.top, 10) + event.offsetY);
        if (this.rotate) {
          point = this.updatePosition(new Communicator.Point2(parseInt(bdBox.style.left, 10) + event.offsetX,
            parseInt(bdBox.style.top, 10) + event.offsetY));
        }
        // if (redlineBounding.boundingBoxDragStartCallback) redlineBounding.boundingBoxDragStartCallback(point);
        if (this.multiSelectService) this.multiSelectService.draggingStart(point);

        parent.onmousemove = ((ev) => {
          if (ev && redlineBounding.dragging) {
            ev.stopPropagation();
            isMoving = true;
            if (this.isMouseup) this.isMouseup = false;
            // if (redlineBounding.boundingBoxDragMoveCallback) {
            //   redlineBounding.boundingBoxDragMoveCallback(redlineBounding.getMousePostion(parent, ev));
            // }
            if (this.multiSelectService) {
              this.multiSelectService.draggingMove(redlineBounding.getMousePostion(parent, ev), this.isChildBounding);
            }
          }
        });
        parent.onmouseup = (ev) => {
          if (ev && redlineBounding.dragging) {
            ev.stopPropagation();
            redlineBounding.dragging = false;
            parent.style.pointerEvents = 'none';
            // if (redlineBounding.boundingBoxDragEndCallback) {
            //   redlineBounding.boundingBoxDragEndCallback(redlineBounding.getMousePostion(parent, ev));
            // }
            const pnt = redlineBounding.getMousePostion(parent, ev);
            if (this.multiSelectService) this.multiSelectService.draggingEnd(pnt);
            if (!isMoving) {
              this.detectDoubleClick(pnt);
            }
            // Deselect markup
            if (!isMoving && this.deselectCallback) this.deselectCallback(pnt, ev.ctrlKey);
          }
        };
      }
    };
    bdBox.onmouseup = (event) => {
      if (event && event.button === 2) {
        this.viewer.trigger('contextMenu', this.updatePosition(new Communicator.Point2(parseInt(bdBox.style.left, 10) + event.offsetX,
          parseInt(bdBox.style.top, 10) + event.offsetY)), Communicator.KeyModifiers.None);
      }
    };

    // Double click & mouse wheel
    if (redlineBounding.boundingBoxDoubleClickCallback) {
      bdBox.ondblclick = (ev) => {
        ev.stopPropagation();
        this.isDoubleClick = true;
        // bdBox.style.pointerEvents = 'none';
        redlineBounding.boundingBoxDoubleClickCallback();
      };
    }

    bdBox.onwheel = (ev) => {
      const parent = bdBox.parentElement;
      const pnt = redlineBounding.getMousePostion(parent, ev);
      const mWEvent = new Communicator.Event.MouseWheelInputEvent(pnt.x, pnt.y, ev.deltaY > 0 ? -1 : 1,
        Communicator.Buttons.Middle, Communicator.KeyModifiers.None, Communicator.MouseInputType.Wheel);
      this.viewer.operatorManager.injectEvent(mWEvent, Communicator.EventType.Mousewheel);
    };
    bdBox.onmousemove = (event) => {
      // move this feature to ad-measure-point-point-markup-item.ts
      // const canvasRect = bdBox.getBoundingClientRect();
      // const x = event.clientX - canvasRect.left;
      // const y = event.clientY - canvasRect.top;

      // if (this.polygonPoint.length >= 2) {
      //   // this.boundingBox.style.cursor = 'default';
      //   this.boundingBox.style.cursor = 'move';
      //   for (let i = 0; i < this.polygonPoint.length; i += 1) {
      //     const p1 = this.polygonPoint[i];
      //     const p2 = this.polygonPoint[(i + 1) % this.polygonPoint.length];
      //     if (this.distanceToLineSegment(x, y, p1.x, p1.y, p2.x, p2.y) < 9) {
      //       this.boundingBox.style.cursor = 'grab';
      //       break;
      //     }
      //   }
      //   // this.draw();
      // }
      redlineBounding.boundingBoxMouseMoveCallback && redlineBounding.boundingBoxMouseMoveCallback(event);
    };
  }

  distanceToLineSegment(x: number, y: number, x1: number, y1: number, x2: number, y2: number): number {
    const APx = x - x1;
    const APy = y - y1;
    const ABx = x2 - x1;
    const ABy = y2 - y1;

    const dotProduct = APx * ABx + APy * ABy;
    const lengthSquared = ABx * ABx + ABy * ABy;

    let t = dotProduct / lengthSquared;

    if (t < 0) {
      t = 0;
    } else if (t > 1) {
      t = 1;
    }

    const closestX = x1 + t * ABx;
    const closestY = y1 + t * ABy;

    const distance = Math.abs(x - closestX) + Math.abs(y - closestY);
    return distance;
  }

  createHtmlElementGripPoint(redlineBounding: ADBaseRedlineBounding, index: number) {
    const gripPoint = document.createElement('div');

    gripPoint.style.position = 'absolute';
    gripPoint.style.zIndex = '6';
    gripPoint.style.width = '8px';
    gripPoint.style.height = '8px';
    if (index % 2 === 1 && this.isCallOut) {
      gripPoint.style.display = 'none';
    }
    gripPoint.style.backgroundColor = '#5aa';
    gripPoint.style.pointerEvents = 'auto';
    this.setGripPointCursor(gripPoint, index);
    const option: AddEventListenerOptions = { once: false, passive: false };
    document.addEventListener('mouseup', (ev) => {
      this.isMouseup = true;
      if (ev && redlineBounding.dragging) {
        ev.stopPropagation();
        redlineBounding.dragging = false;
        const parent = gripPoint.parentElement;
        parent.style.pointerEvents = 'none';
        if (redlineBounding.gripPointDragEndCallback) {
          const p = redlineBounding.getMousePostion(parent, ev);
          let isMove = true;
          if (this.pointDown.equals(p)) {
            isMove = false;
          }
          redlineBounding.gripPointDragEndCallback(p, isMove);
        }
      }
    }, option);

    gripPoint.onmousedown = (event) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
        this.isMouseup = false;
        redlineBounding.dragging = true;
        const parent = gripPoint.parentElement;
        parent.style.pointerEvents = 'auto';
        const point = new Communicator.Point2(parseInt(gripPoint.style.left, 10) + event.offsetX,
          parseInt(gripPoint.style.top, 10) + event.offsetY);
        if (redlineBounding.gripPointDragStartCallback) {
          this.pointDown = point.copy();
          redlineBounding.gripPointDragStartCallback(point, index);
        }

        parent.onmousemove = ((ev) => {
          ev.stopPropagation();
          if (redlineBounding.gripPointDragMoveCallback) {
            redlineBounding.gripPointDragMoveCallback(redlineBounding.getMousePostion(parent, ev), ev.shiftKey);
          }
        });

        parent.onmouseup = (ev) => {
          if (ev && redlineBounding.dragging) {
            ev.stopPropagation();
            redlineBounding.dragging = false;
            parent.style.pointerEvents = 'none';
            if (redlineBounding.gripPointDragEndCallback) {
              const p = redlineBounding.getMousePostion(parent, ev);
              let isMove = true;
              if (this.pointDown.equals(p)) {
                isMove = false;
              }
              redlineBounding.gripPointDragEndCallback(p, isMove);
            }
          }
        };
      }
    };
    return gripPoint;
  }

  setGrippointEnable(value: boolean, value2?:boolean) {
    this.isHasGrippoints = value;
    this.isCallOut = value2;
  }

  setGripPointCallback(dragStartCallback?: (point: Communicator.Point2, type: GripPoint) => void,
    dragMoveCallback?: (point: Communicator.Point2, isForcing?: boolean) => void,
    dragEndCallback?: (point: Communicator.Point2, isMove:boolean) => void) {
    this.gripPointDragStartCallback = dragStartCallback;
    this.gripPointDragMoveCallback = dragMoveCallback;
    this.gripPointDragEndCallback = dragEndCallback;
  }

  setBoudingboxCallback(dragStartCallback?: (point: Communicator.Point2, isMove: boolean) => void,
    dragMoveCallback?: (point: Communicator.Point2) => void,
    dragEndCallback?: (point: Communicator.Point2, isMove: boolean) => void) {
    this.boundingBoxDragStartCallback = dragStartCallback;
    this.boundingBoxDragMoveCallback = dragMoveCallback;
    this.boundingBoxDragEndCallback = dragEndCallback;
  }

  setBoundingboxDoubleClickCallback(doubleClickCallback: () => void) {
    this.boundingBoxDoubleClickCallback = doubleClickCallback;
    const redlineBounding = this;
    const { boundingBox } = this;
    if (redlineBounding && boundingBox) {
      boundingBox.ondblclick = (ev) => {
        redlineBounding.boundingBoxDoubleClickCallback();
      };
    }
  }

  setBoundingBoxMouseWheelCallback(mouseWheelCallback:(ev: WheelEvent) => void) {
    this.boundingBoxMouseWheelCallback = mouseWheelCallback;
  }

  setBoundingBoxMouseMoveCallback(mouseMoveCallback:(ev) => void) {
    this.boundingBoxMouseMoveCallback = mouseMoveCallback;
  }

  setDeselectCallback(delselectCallback: (point: Communicator.Point2, isCtrlDown: boolean) => void) {
    this.deselectCallback = delselectCallback;
  }

  setBoundingBoxPointerEvent(isEnable: boolean) {
    if (this.boundingBox) {
      if (isEnable) {
        this.boundingBox.style.pointerEvents = 'auto';
      } else {
        this.boundingBox.style.pointerEvents = 'none';
      }
    }
  }

  getMousePostion(elem: HTMLElement, event: MouseEvent) {
    const rect = elem.getBoundingClientRect();
    const left = rect.x + window.pageXOffset;
    const top = rect.y + window.pageYOffset;

    return new Communicator.Point2(event.clientX - left, event.clientY - top);
  }

  setPosition(point: Communicator.Point2, isTextBox: boolean = false, isPolygon: boolean = false) {
    this.boundingBox.style.left = `${point.x}px`;
    this.boundingBox.style.top = `${point.y}px`;
    this.currentPossition = point;
    if (isTextBox) {
      this.updateGripPointsTextBox(isPolygon);
    } else {
      this.updateGripPoints(isPolygon);
    }
  }

  getPosition() : Communicator.Point2 {
    return this.currentPossition.copy();
  }

  setSize(size: Communicator.Point2, isTextBox: boolean = false, isPolygon: boolean = false) {
    this.boundingBox.style.width = `${size.x}px`;
    this.boundingBox.style.height = `${size.y}px`;
    this.boundingBox.width = size.x;
    this.boundingBox.height = size.y;

    this.currentSize = size;
    if (isTextBox) {
      this.updateGripPointsTextBox(isPolygon);
    } else {
      this.updateGripPoints(isPolygon);
    }
  }

  setPolygon(listPoint: Communicator.Point2[]) {
    this.polygonPoint = [];
    if (listPoint.length > 0) {
      const pmin = new Communicator.Point2(listPoint[0].x, listPoint[0].y);
      const pmax = pmin.copy();
      listPoint.forEach((p) => {
        if (pmin.x > p.x) pmin.x = p.x;
        else if (pmax.x < p.x) pmax.x = p.x;

        if (pmin.y > p.y) pmin.y = p.y;
        else if (pmax.y < p.y) pmax.y = p.y;
      });
      listPoint.forEach((p) => {
        this.polygonPoint.push(new Communicator.Point2(p.x - pmin.x, p.y - pmin.y));
      });
      this.setPosition(pmin, false, true);
      this.setSize(new Communicator.Point2(pmax.x - pmin.x, pmax.y - pmin.y), false, true);
    }
  }

  getSize() : Communicator.Point2 {
    return this.currentSize.copy();
  }

  updateGripPoints(isPolygon = false) {
    if (this.currentPossition && this.currentSize) {
      if (isPolygon) {
        const listKey = [];
        this.polygonPoint.forEach((p, i) => {
          const key = `${i}`;
          let obj = this.mapGripPoints.get(key);
          if (!obj) {
            const gripPoint = this.createHtmlElementGripPoint(this, -1);
            const newobj: IHtmlElementGripPoint = {
              name: key,
              index: i,
              htmlElement: gripPoint,
            };
            obj = newobj;
            this.mapGripPoints.set(key, obj);
          }
          listKey.push(key);
          this.setGripPointPosition(obj.htmlElement, new Communicator.Point2(p.x + this.currentPossition.x, p.y + this.currentPossition.y));
          this.setGripPointDisplay(obj.htmlElement, true);
          this.mapGripPoints.forEach((obj1, key1) => {
            if (!listKey.includes(key1)) this.setGripPointDisplay(obj1.htmlElement, false);
          });
        });
      } else {
        for (let i = 0; i < 8; i++) {
          const key = `${i}`;
          let obj = this.mapGripPoints.get(key);
          if (!obj) {
            const gripPoint = this.createHtmlElementGripPoint(this, i);
            const newobj: IHtmlElementGripPoint = {
              name: key,
              index: i,
              htmlElement: gripPoint,
            };
            obj = newobj;
            this.mapGripPoints.set(key, obj);
          }
          const gripPoint = obj && obj.htmlElement;
          if (gripPoint) {
            switch (i) {
              case GripPoint.top:
                this.setGripPointPosition(gripPoint,
                  new Communicator.Point2(this.currentPossition.x + this.currentSize.x / 2, this.currentPossition.y));
                this.setStyleCursor(gripPoint, GripPoint.top);
                break;
              case GripPoint.topRight:
                this.setGripPointPosition(gripPoint,
                  new Communicator.Point2(this.currentPossition.x + this.currentSize.x, this.currentPossition.y));
                this.setStyleCursor(gripPoint, GripPoint.topRight);
                break;
              case GripPoint.right:
                this.setGripPointPosition(gripPoint,
                  new Communicator.Point2(this.currentPossition.x + this.currentSize.x, this.currentPossition.y + this.currentSize.y / 2));
                this.setStyleCursor(gripPoint, GripPoint.right);
                break;
              case GripPoint.bottomRight:
                this.setGripPointPosition(gripPoint,
                  new Communicator.Point2(this.currentPossition.x + this.currentSize.x, this.currentPossition.y + this.currentSize.y));
                this.setStyleCursor(gripPoint, GripPoint.bottomRight);
                break;
              case GripPoint.bottom:
                this.setGripPointPosition(gripPoint,
                  new Communicator.Point2(this.currentPossition.x + this.currentSize.x / 2, this.currentPossition.y + this.currentSize.y));
                this.setStyleCursor(gripPoint, GripPoint.bottom);
                break;
              case GripPoint.bottomLeft:
                this.setGripPointPosition(gripPoint,
                  new Communicator.Point2(this.currentPossition.x, this.currentPossition.y + this.currentSize.y));
                this.setStyleCursor(gripPoint, GripPoint.bottomLeft);
                break;
              case GripPoint.left:
                this.setGripPointPosition(gripPoint,
                  new Communicator.Point2(this.currentPossition.x, this.currentPossition.y + this.currentSize.y / 2));
                this.setStyleCursor(gripPoint, GripPoint.left);
                break;
              case GripPoint.topLeft:
                this.setGripPointPosition(gripPoint,
                  this.currentPossition);
                this.setStyleCursor(gripPoint, GripPoint.topLeft);
                break;
              default:
                break;
            }
          }
        }
      }
    }
  }

  setCenterPoint(firstPoint: Communicator.Point2, secondPoint: Communicator.Point2) {
    if (!firstPoint || !secondPoint) return;

    this.centerPoint = new Communicator.Point2(
      (firstPoint.x + secondPoint.x) / 2, (firstPoint.y + secondPoint.y) / 2,
    );
  }

  getCenterPoint() {
    return this.centerPoint;
  }

  updatePosition(position: Communicator.Point2) {
    const { x, y } = position;
    const { x: cx, y: cy } = this.centerPoint;
    const radians = (Math.PI / -180) * (this.rotate);
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);
    const nx = (cos * (x - cx)) + (sin * (y - cy)) + cx;
    const ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
    return new Communicator.Point2(nx, ny);
  }

  updatePoint(position: Communicator.Point2) {
    const { x, y } = position;
    const { x: cx, y: cy } = this.centerPoint;
    const radians = (Math.PI / 180) * (this.rotate);
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);
    const nx = (cos * (x - cx)) + (sin * (y - cy)) + cx;
    const ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
    return new Communicator.Point2(nx, ny);
  }

  updateGripPointsTextBox(isPolygon = false) {
    if (this.currentPossition && this.currentSize) {
      if (isPolygon) {
        const listKey = [];
        this.polygonPoint.forEach((p, i) => {
          const key = `${i}`;
          let obj = this.mapGripPoints.get(key);
          if (!obj) {
            const gripPoint = this.createHtmlElementGripPoint(this, -1);
            const newobj: IHtmlElementGripPoint = {
              name: key,
              index: i,
              htmlElement: gripPoint,
            };
            obj = newobj;
            this.mapGripPoints.set(key, obj);
          }
          listKey.push(key);
          this.setGripPointPosition(obj.htmlElement, new Communicator.Point2(p.x + this.currentPossition.x, p.y + this.currentPossition.y));
          this.setGripPointDisplay(obj.htmlElement, true);
          this.mapGripPoints.forEach((obj1, key1) => {
            if (!listKey.includes(key1)) this.setGripPointDisplay(obj1.htmlElement, false);
          });
        });
      } else {
        for (let i = 0; i < 8; i++) {
          const key = `${i}`;
          let obj = this.mapGripPoints.get(key);
          if (!obj) {
            const gripPoint = this.createHtmlElementGripPoint(this, i);
            const newobj: IHtmlElementGripPoint = {
              name: key,
              index: i,
              htmlElement: gripPoint,
            };
            obj = newobj;
            this.mapGripPoints.set(key, obj);
          }
          const gripPoint = obj && obj.htmlElement;
          if (gripPoint) {
            switch (i) {
              case GripPoint.top:
                this.setGripPointPosition(gripPoint,
                  this.updatePosition(new Communicator.Point2(this.currentPossition.x + this.currentSize.x / 2,
                    this.currentPossition.y)));
                this.setStyleCursor(gripPoint, GripPoint.top);
                break;
              case GripPoint.topRight:
                this.setGripPointPosition(gripPoint,
                  this.updatePosition(new Communicator.Point2(this.currentPossition.x + this.currentSize.x,
                    this.currentPossition.y)));
                this.setStyleCursor(gripPoint, GripPoint.topRight);
                break;
              case GripPoint.right:
                this.setGripPointPosition(gripPoint,
                  this.updatePosition(new Communicator.Point2(this.currentPossition.x + this.currentSize.x,
                    this.currentPossition.y + this.currentSize.y / 2)));
                this.setStyleCursor(gripPoint, GripPoint.right);
                break;
              case GripPoint.bottomRight:
                this.setGripPointPosition(gripPoint,
                  this.updatePosition(new Communicator.Point2(this.currentPossition.x + this.currentSize.x,
                    this.currentPossition.y + this.currentSize.y)));
                this.setStyleCursor(gripPoint, GripPoint.bottomRight);
                break;
              case GripPoint.bottom:
                this.setGripPointPosition(gripPoint,
                  this.updatePosition(new Communicator.Point2(this.currentPossition.x + this.currentSize.x / 2,
                    this.currentPossition.y + this.currentSize.y)));
                this.setStyleCursor(gripPoint, GripPoint.bottom);
                break;
              case GripPoint.bottomLeft:
                this.setGripPointPosition(gripPoint,
                  this.updatePosition(new Communicator.Point2(this.currentPossition.x,
                    this.currentPossition.y + this.currentSize.y)));
                this.setStyleCursor(gripPoint, GripPoint.bottomLeft);
                break;
              case GripPoint.left:
                this.setGripPointPosition(gripPoint,
                  this.updatePosition(new Communicator.Point2(this.currentPossition.x,
                    this.currentPossition.y + this.currentSize.y / 2)));
                this.setStyleCursor(gripPoint, GripPoint.left);
                break;
              case GripPoint.topLeft:
                this.setGripPointPosition(gripPoint,
                  this.updatePosition(this.currentPossition));
                this.setStyleCursor(gripPoint, GripPoint.topLeft);
                break;
              default:
                break;
            }
          }
        }
      }
    }
  }

  setStyleCursor(gripPoint: HTMLElement, type:number) {
    const element = gripPoint as HTMLElement;
    if (!element) return;
    const deltaAngle = 45;
    const angleCircle = 360;
    let x = this.rotate + type * deltaAngle;
    if (x > angleCircle) x -= angleCircle;
    const direction = ['ne-resize', 'e-resize', 'se-resize', 's-resize', 'sw-resize', 'w-resize', 'nw-resize', 'n-resize'];
    const count = direction.length;
    for (let i = 0; i < count; i++) {
      // eslint-disable-next-line max-len
      if (x > ((angleCircle / count) * (i + 1) - deltaAngle / 2) && x < ((angleCircle / count) * (i + 2) - deltaAngle / 2)) { element.style.cursor = `${direction[i]}`; }
      // eslint-disable-next-line max-len
      // eslint-disable-next-line no-mixed-operators, max-len
      if ((i === (count - 1)) && x > ((angleCircle / count) * (i + 1) - deltaAngle / 2) || x < ((angleCircle / count) - deltaAngle / 2)) { element.style.cursor = `${direction[i]}`; }
    }
  }

  setGripPointCursor(gripPoint: HTMLDivElement, type: number) {
    if (!gripPoint) return;
    switch (type) {
      case GripPoint.top:
        gripPoint.style.cursor = 'n-resize';
        break;
      case GripPoint.topRight:
        gripPoint.style.cursor = 'ne-resize';
        break;
      case GripPoint.right:
        gripPoint.style.cursor = 'e-resize';
        break;
      case GripPoint.bottomRight:
        gripPoint.style.cursor = 'se-resize';
        break;
      case GripPoint.bottom:
        gripPoint.style.cursor = 's-resize';
        break;
      case GripPoint.bottomLeft:
        gripPoint.style.cursor = 'sw-resize';
        break;
      case GripPoint.left:
        gripPoint.style.cursor = 'w-resize';
        break;
      case GripPoint.topLeft:
        gripPoint.style.cursor = 'nw-resize';
        break;
      default:
        break;
    }
  }

  setGripPointPosition(gripPoint: HTMLDivElement, position: Communicator.Point2) {
    if (!gripPoint || !position) return;
    gripPoint.style.left = `${position.x - 4}px`;
    gripPoint.style.top = `${position.y - 4}px`;
    gripPoint.style.transform = `rotate(${this.rotate}deg)`;
  }

  setGripPointDisplay(gripPoint: HTMLDivElement, isShow: boolean) {
    if (!gripPoint) return;

    if (isShow) gripPoint.style.display = '';
    else gripPoint.style.display = 'none';
  }

  getBoundingElement() : HTMLElement[] {
    const rets: HTMLElement[] = [];
    rets.push(this.boundingBox);
    this.mapGripPoints.forEach((value: IHtmlElementGripPoint, key: string) => {
      if (value && value.htmlElement) rets.push(value.htmlElement);
    });
    this.customGrippoints && this.customGrippoints.forEach((value) => {
      if (value) rets.push(value);
    });
    return rets;
  }

  setRotate(rotate: number) {
    this.rotate = rotate;
    this.boundingBox.style.transform = `rotate(${this.rotate}deg)`;
  }

  getGripPointPosition(gridPoint: HTMLDivElement) {
    if (gridPoint) return { x: parseFloat(gridPoint.style.left), y: parseFloat(gridPoint.style.top) };
    return { x: 0, y: 0 };
  }

  draw() {
    if (this.polygonPoint.length > 0) {
      const ctx = this.boundingBox.getContext('2d');
      const p0 = this.polygonPoint[0];
      ctx.clearRect(0, 0, this.boundingBox.width, this.boundingBox.height);
      ctx.beginPath();
      ctx.lineWidth = 2;
      ctx.strokeStyle = '#5aa';

      ctx.moveTo(p0.x, p0.y);
      this.polygonPoint.forEach((p) => {
        ctx.lineTo(p.x, p.y);
      });
      ctx.lineTo(p0.x, p0.y);
      ctx.closePath();
      ctx.stroke();
    }
  }

  getGripElement(type: number) {
    const boundingElement = this.getBoundingElement();
    boundingElement.shift();
    return boundingElement[type];
  }

  setChildBounding(isChild: boolean = false) {
    this.isChildBounding = isChild;
  }

  setCursorBounding(value: string = 'default') {
    this.boundingBox.style.cursor = value;
  }

  setOutlineVisible(isVisible: boolean = true) {
    this.boundingBox.style.outline = isVisible ? '1px solid #5aa' : 'none';
  }

  getBoundingBoxDoubleClickCallback() {
    return this.boundingBoxDoubleClickCallback;
  }

  count = 0;

  curentPoint: Communicator.Point2 = null;

  timer = 0;

  detectDoubleClick(point: Communicator.Point2) {
    if (this.curentPoint && this.curentPoint.equals(point)) {
      this.count += 1;
      if (this.count === 2) {
        // DoubleClick
      }
    } else {
      this.count = 1;
      this.curentPoint = point.copy();
    }
    if (this.count === 1) {
      this.timer && clearTimeout(this.timer);
      this.timer = window.setTimeout(() => {
        if (this.count < 2 && !this.isDoubleClick) {
          // Util.delay(100).then(() => {
          //   this.multiSelectService.clearSelected();
          // });
          this.multiSelectService.clearSelected();
        }
        this.curentPoint = null;
        this.count = 0;
        this.isDoubleClick = false;
      }, 300);
    }
  }

  setTitle(title: string) {
    this.boundingBox.title = title;
  }

  getGripPoints() {
    return this.mapGripPoints;
  }
}
