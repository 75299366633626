import {
  Component, Inject, OnDestroy, OnInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, fromEvent } from 'rxjs';
import { KeyCode } from 'src/app/core/enums/keycode';

@Component({
  selector: 'app-dialog-waiting',
  templateUrl: './dialog-waiting.component.html',
  styleUrls: ['./dialog-waiting.component.scss'],
})
export class DialogWaitingComponent implements OnInit, OnDestroy {
  public activeFileData: any;

  subscription = Subscription.EMPTY;

  constructor(
    public dialogRef: MatDialogRef<DialogWaitingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.activeFileData = this.data.activeFileData;
    this.subscription = fromEvent(document, 'keyup').subscribe((event: KeyboardEvent) => {
      if (event.key === KeyCode.escape) {
        this.dialogRef.close(-1); // normal === undefine
      }
    });
  }
}
