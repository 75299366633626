import { PdfCommunicator } from 'src/app/pdf/service/PdfCommunicator';
import { FileInfo } from './main-viewer-common';

export enum StatusNotify {
    success = 1,
    warning = 2,
    error = 3,
    copy = 4,
}

export interface NotifiedData {
    message: string;
    status: StatusNotify;
}

export interface PdfInfoPrintResult {
  pdfController: PdfCommunicator;
  isGrayscale: boolean;
  path: string;
  name: string;
}

export interface PrintResult {
  listDiv: string[];
  listPdfInfo: PdfInfoPrintResult[];
  size?: any; // su dung cho current view
}

export interface PrintFileCapture {
  fileInfo: FileInfo,
  printResult: PrintResult,
  timeStart: number,
}

export enum ModeUpdate {
  EntitiesActive = 1,
  EntitiesDisable,
  EntitiesSelected,
  GroupActive,
  GroupSelected,
  DeSelectGroup,
  DeSelectEntities,
  EntitiesRotate
}
