/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OnDestroyService } from '../interfaces/interfaces';
import { CommonConstant } from '../constant/constant';
import { ViewSectionService } from '../../dashboard/service/view-section.service';
@Injectable({
  providedIn: 'root',
})
export class WindowService implements OnDestroyService {
  listAction = [];

  constructor(
    public router: Router,
    private viewSectionService: ViewSectionService,
  ) { }

  initEventStorage() {
    window.addEventListener('storage', this.eventStorage);
    window.addEventListener('beforeunload', () => {
      this.listAction = [1];
    });
    window.addEventListener('focus', () => {
      if (this.listAction.length < 5) this.listAction.push(2);
    });
    window.addEventListener('unload', () => {
      // reload: beforeunload, focus, unload
      // close: beforeunload, unload
      if (!(this.listAction.length === 2 && this.listAction[0] === 1 && this.listAction[1] === 2)) {
        // this.viewSectionService.deleteCurrentViewSection();
      }
    });
  }

  eventStorage = (ev: StorageEvent) => {
    if (ev.key === CommonConstant.LoginSuccess) {
      if (ev.oldValue === 'true' && ev.newValue === 'false') {
        this.router.navigateByUrl('login').then(() => console.log(`Log out from ${ev.url}`));
      }
    }
  }

  onDestroyService(): void {
    window.removeEventListener('storage', this.eventStorage);
  }
}
