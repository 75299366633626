/* eslint-disable max-classes-per-file */
import {
  ViewChild, ElementRef, Inject, Component, OnInit, OnDestroy,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-dialog-input-password',
  templateUrl: './dialog-input-password.component.html',
  styleUrls: ['./dialog-input-password.component.scss'],
})
export class DialogInputPasswordComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  password: string = '';

  public hidePassword: boolean = true;

  @ViewChild('box') box: ElementRef<HTMLInputElement>;

  constructor(
    private dialogRef: MatDialogRef<DialogInputPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }

  private init() {
    this.subscription = fromEvent(document, 'keyup').subscribe((event: KeyboardEvent) => {
      if ((event.keyCode === 27 || event.key === 'Escape')) {
        event.stopPropagation();
        this.cancelAction();
      } else if ((event.keyCode === 27 || event.key === 'Enter')) {
        event.stopPropagation();
        this.okAction();
      }
    });
  }

  cancelAction() {
    // this.dataDialog.setShow = false;
    this.dialogRef.close(null);
  }

  okAction() {
    this.password = this.box.nativeElement.value;
    this.dialogRef.close(this.password);
  }
}
