/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-return-assign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable no-tabs */
/* eslint-disable no-useless-escape */
import { ValidatorFn, AbstractControl } from '@angular/forms';
import {
  Subscription, Observable, Subject, defer, BehaviorSubject,
} from 'rxjs';
import { ɵɵdirectiveInject, NgZone } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  etypeSignoff, ISignoff, ISignoffInfo, MarkupType, ViewState,
} from 'src/app/shared/class/global';
import { OptionData } from 'src/app/shared/common/shared.common';
import { tap, finalize } from 'rxjs/operators';
import { BaseRedlineItem } from 'src/app/viewer3d/markup/base-redline-item';
import { toUpper } from 'lodash';
import ADMeasurePointPointMarkupItem from 'src/app/viewer3d/markup/measure/ad-measure-point-point-markup-item';
import {
  FileIdRev,
  FileInfo, FormatViewer, UserInfo, ViewActive,
} from '../common/main-viewer-common';
import { SystemConstants } from '../common/system.constants';
import { Permission, PermissionExtend, CheckPermissionType } from '../common/permission';
import { MarkupEntity } from '../common/markups';
import { ModelInfo } from '../common/ModelInfo';
import Util from './util';
import { CommonConstant } from '../constant/constant';

const lstRestrictCharacter = ['\\', '/', ':', '*', '?', '"', '<', '>', '|', ';', '!', '#', '$', '%', '&', '(', ')', '@', '+', '^', '='];
const lstRestrictCharacterInEditTitle = ['\\', '*', '?', '"', '<', '>', '|', ';', '!', '#', '$', '%', '&', '(', ')', '@', '+', '^', '='];

export function generateRestrictCharacterRegex(lstCharacters) {
  const len = lstCharacters.length;
  let result = '^((?!';
  for (let i = 0; i < len - 1; i++) {
    result += `\\${lstCharacters[i]}|`;
  }
  result += `\\${lstCharacters[len - 1]}).)*$`;
  return result;
}

export function ExistTextValidator(arrText: string[], curentName?: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const arr = [];
    const tempcurentName = toUpper(curentName);
    arrText.forEach((item) => {
      const temp = toUpper(item);
      if (tempcurentName !== temp) {
        arr.push(temp);
      }
    });
    const existText = arr.includes(toUpper(control.value));
    return existText ? { existText: true } : null;
  };
}

/**
 * ^ Password Validator
 */
export function PasswordValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const regexPass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/g;
    const isValid = regexPass.test(control.value);
    return !isValid ? { passwordValidate: true } : null;
  };
}

export function InputStandardCharaters(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const regex = generateRestrictCharacterRegex(lstRestrictCharacter);
    const result = control.value?.match(regex);
    let isValid = false;
    if (result !== null) {
      isValid = true;
    }
    return !isValid ? { wrongStandard: true } : null;
  };
}

export function InputStandardCharatersInMarkupObjectTilte(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const regex = generateRestrictCharacterRegex(lstRestrictCharacterInEditTitle);
    const result = control.value.match(regex);
    let isValid = false;
    if (result !== null) {
      isValid = true;
    }
    return !isValid ? { wrongStandard: true } : null;
  };
}

export function FirstIsCharacter(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control?.value?.length) return null;
    const regex = /^\S/g;
    const isValid = regex.test(control.value);
    return !isValid ? { isCharacter: true } : null;
  };
}

/**
 * Daterange Validator
 */
export function DateRangeValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const content: string = control.value;
    if (!content) return null;
    const regex = /-*[0-9]/g;
    const isValid = regex.test(content);
    if (isValid) return null;
    const dates = content.split('-');
    if (!dates && dates.length !== 2) return null;
    const startDate = Date.parse(dates[0]);
    const endDate = Date.parse(dates[1]);
    if (!startDate || !endDate) return null;

    return { isDateRange: true };
  };
}

/**
 * Convernt Date now to String "YYYY-MM-DD h:m:s"
 */
export function ConvertDateNowToString(): string {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const date = now.getDate();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const second = now.getSeconds();
  return `${year}-${month + 1}-${date} ${hours}:${minutes}:${second}`;
}

export function ConvertDateNowToStringExt(): string {
  const now = new Date();
  const year = now.getFullYear();
  let month: any = now.getMonth() + 1;
  let date: any = now.getDate();
  let hours: any = now.getHours();
  let minutes: any = now.getMinutes();
  let second: any = now.getSeconds();
  if (month.toString().length === 1) {
    month = `0${month}`;
  }
  if (date.toString().length === 1) {
    date = `0${date}`;
  }
  if (hours.toString().length === 1) {
    hours = `0${hours}`;
  }
  if (minutes.toString().length === 1) {
    minutes = `0${minutes}`;
  }
  if (second.toString().length === 1) {
    second = `0${second}`;
  }
  return `${year}/${month}/${date}, ${hours}:${minutes}:${second} ${(hours > 0 && hours < 12) ? 'AM' : 'PM'}`;
}

export function RgbToCommunicatorColor(rgbText: string): Communicator.Color {
  const regex = /^rgb?.\((\d+),(\d+),(\d+)/g;
  const cal = regex.exec(rgbText);
  if (cal && cal.length === 4) {
    return new Communicator.Color(+cal[1], +cal[2], +cal[3]);
  }
  return null;
}

export function CommunicatorColorToRGBAString(color: Communicator.Color, opacity: number = 0) {
  if (!color) return null;

  return `rgba(${color.r}, ${color.g}, ${color.b}, ${opacity})`;
}

export enum LogType {
  Destroy = 'Destroy',
  Init = 'Init'
}

export enum LogTypeClass {
  Component = 'Component',
  Service = 'Service',
}

export class UtilExtend {
  public static arrExtensions2D = ['dgn'];

  public static getExtensionFile(fileName: string): string {
    const regex = /\.(\w+)(\s)*$/g;
    const re = regex.exec(fileName);
    if (re && re.length > 1) {
      return re[1];
    }
    return null;
  }

  public static getFileNameWithoutExtention(fileName: string): string {
    return fileName?.substr(0, fileName?.lastIndexOf('.')) ?? null;
  }

  public static checkExtension2D(fileName: string): boolean {
    const extention = UtilExtend.getExtensionFile(fileName);
    return UtilExtend.arrExtensions2D.includes(extention);
  }

  public static Log(logType: LogType, name: string, logTypeClass: LogTypeClass) {
    if (!environment.disableLog) {
      const mesg = `${logType} ${name} ${logTypeClass}`;
      console.log(mesg);
    }
  }

  public static getFormatViewer(fileInfo: FileInfo): FormatViewer {
    if (fileInfo && fileInfo.converter) {
      let re: FormatViewer;
      switch (fileInfo.converter) {
        case SystemConstants.VIEWER_MODE.HOOP:
          re = FormatViewer.Viewer3d;
          break;
        case SystemConstants.VIEWER_MODE.PDF:
          re = FormatViewer.Pdf;
          break;
        case SystemConstants.VIEWER_MODE.ZIP:
          re = FormatViewer.Zip;
          break;
        default:
          re = null;
          break;
      }
      // if (fileInfo.viewIdChildren || fileInfo.viewIdParent) {
      //   re = FormatViewer.Merger;
      // }
      return re;
    }
    return null;
  }

  public static getFormatViewerFromViewActive(viewActive: ViewActive): FormatViewer {
    if (viewActive && viewActive.format) {
      return viewActive.format;
    }
    return null;
  }

  public static onDestroy(source: Subject<any>) {
    if (source) {
      source.next(undefined);
      source.complete();
      source = null;
    }
  }

  public static getValuePermission(arrPermissions: PermissionExtend[], permission: Permission, isBoolean = true) {
    const perm = arrPermissions.find((item) => item.name === permission);
    if (perm) {
      if (isBoolean) {
        return !!perm.value;
      }
      return perm.value;
    }
    return null;
  }

  public static hasPermissions(
    arrPermissions: PermissionExtend[],
    permission: Permission[] | Permission,
    type: CheckPermissionType = 'all',
  ): boolean {
    const permissionInput = Array.isArray(permission) ? permission : [permission];
    let re = true;
    if (type === 'all') {
      const notHasPermission = permissionInput.some((item) => !!UtilExtend.getValuePermission(arrPermissions, item) === false);
      re = !notHasPermission;
    } else {
      const hasSomePermission = permissionInput.some((item) => !!UtilExtend.getValuePermission(arrPermissions, item) === true);
      re = hasSomePermission;
    }
    return re;
  }

  public static mapValuePermission(obj): PermissionExtend[] {
    const permiss: PermissionExtend[] = [];
    for (const i in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, i)) {
        const type = UtilExtend.mapTypePermission(i);
        if (type && type !== '') {
          permiss.push({
            name: Permission[type],
            value: obj[i],
            disable: false,
          });
        }
      }
    }
    return permiss;
  }

  public static mapTypePermission(type: string): string {
    for (const i in Permission) {
      if (Permission[i] === type) {
        return i;
      }
    }
    return '';
  }

  public static scrollToElementById(elementId: string) {
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView();
      }
    }, 100);
  }

  public static setSaveDataForPdf(markupEntities: MarkupEntity[]) {
    markupEntities.forEach((p) => {
      if (p?.originData.opacity === 0) {
        p.originData.opacity = 1;
      }
    });
  }

  public static offsetWindow(el: HTMLElement) {
    const rect = el.getBoundingClientRect();
    return {
      top: rect.top + window.pageYOffset,
      left: rect.left + window.pageXOffset,
    };
  }

  public static mapAuthorToOptionFilter(author: UserInfo): OptionData<string> {
    return {
      name: author.userName,
      value: author.id,
    };
  }

  public static mapOptionFilterToAuthor(option: OptionData<string>): UserInfo {
    return {
      userName: option.name,
      id: option.value,
    };
  }

  public static activeOperatorByType(type: MarkupType, modelInfo: ModelInfo, canEdit: boolean | number = true) {
    if (canEdit) {
      switch (type) {
        case MarkupType.Freehand:
          modelInfo.operatorService.RedlineFreeHandOperator();
          break;
        case MarkupType.Oval:
          modelInfo.operatorService.RedlineCircleOperator();
          break;
        case MarkupType.Square:
          modelInfo.operatorService.RedlineRectangleOperator();
          break;
        case MarkupType.Text:
          modelInfo.operatorService.RedlineTextOperator();
          break;
        case MarkupType.CallOut:
          modelInfo.operatorService.CallOutOperator();
          break;
        case MarkupType.NotePin:
          modelInfo.operatorService.NotePinsOperator();
          break;
        default:
          break;
      }
    } else {
      switch (type) {
        case MarkupType.Freehand:
        case MarkupType.Oval:
        case MarkupType.Square:
        case MarkupType.Text:
          modelInfo.operatorService.MarkupViewOperator();
          break;
        case MarkupType.CallOut:
        case MarkupType.NotePin:
        default:
          modelInfo.operatorService.DefaultOperator();
          break;
      }
    }
  }

  public static getMarkupItemFromMarkupViewId(entityId: string, markupViewId: string, webViewer: Communicator.WebViewer) {
    const markupView = webViewer.markupManager.getMarkupView(markupViewId);
    if (markupView) {
      return markupView.getMarkup()?.find((m: any) => m._uniqueId === entityId);
    }
    return null;
  }

  public static clearUserStorage(fullClear = true) {
    const key = `${Util.getValueLocalStorage(CommonConstant.UserNameKey)}_${CommonConstant.NameUserSetting}`.toUpperCase();
    Util.removeItemLocalStorage(key);
    Util.removeItemLocalStorage(CommonConstant.UserNameKey);
    Util.removeItemLocalStorage(CommonConstant.SettingData);
    Util.removeItemLocalStorage(CommonConstant.NameUserSetting);
    fullClear && Util.removeItemLocalStorage(CommonConstant.UrlInit);
    Util.setLocalStorage(CommonConstant.LoginSuccess, false);
  }

  public static nameSheet(fileName: string, originName: string): string {
    const originNameWithoutExtension = UtilExtend.getFileNameWithoutExtention(originName);
    let fileNameWithoutExtension = UtilExtend.getFileNameWithoutExtention(fileName);
    if (originNameWithoutExtension) {
      const fileNameWithourLowerCase = fileNameWithoutExtension?.toLowerCase();
      const originNameWithoutExtensionLowerCase = originNameWithoutExtension.toLowerCase();
      if (fileNameWithourLowerCase.includes(originNameWithoutExtensionLowerCase)) {
        if (fileNameWithourLowerCase === originNameWithoutExtensionLowerCase) {
          return fileNameWithoutExtension;
        }
        fileNameWithoutExtension = fileNameWithourLowerCase.substring(originNameWithoutExtensionLowerCase.length);
        if (fileNameWithoutExtension.charAt(0) === '_') fileNameWithoutExtension = fileNameWithoutExtension.substring(1);
        // const regex = /_(.*)\.\w+\s*$/g;
        // const re = regex.exec(fileName);
        // if (re && re.length === 2) {
        //   return re[1];
        // }
      }
      return fileNameWithoutExtension;
    }
    return '';
  }

  public static deleteMap(mapInput: Map<string, BehaviorSubject<unknown>>, viewId: string) {
    // const obser$ = mapInput.get(viewId);
    // if (obser$) {
    //   const val = obser$.value;
    //   let newVal;
    //   if (Array.isArray(val)) {
    //     newVal = [];
    //   } else if (typeof val === 'boolean') {
    //     newVal = false;
    //   } else if (typeof val === 'string') {
    //     newVal = '';
    //   }
    //   // obser$.next(newVal);
    // }
    mapInput.delete(viewId);
  }

  public static findFirstItemWithCondition<T>(list: T[], func: (item: T, index?: number) => boolean) {
    let re: { item: T, index: number };
    list.some((item, index) => {
      if (func(item, index)) {
        re = { item, index };
        return true;
      }
      return false;
    });
    return re ?? null;
  }

  public static deleteItemInListWithCondition<T>(list: T[], func: (item: T) => boolean) {
    const { length } = list;
    for (let i = 0; i < length; i++) {
      if (list[i]) {
        if (func(list[i])) {
          list.splice(i, 1);
          i--;
        }
      }
    }
    return true;
  }

  public static checkOutsideViewer(point: Communicator.Point2, viewer: Communicator.WebViewer): boolean {
    if (!point) return true;
    const elem = viewer.getViewElement();
    let width = 0;
    let height = 0;
    if (elem) {
      width = elem.clientWidth;
      height = elem.clientHeight;
    }

    return point.x > width || point.y > height;
  }

  public static getMarkupsMinPosition(markups: (BaseRedlineItem | ADMeasurePointPointMarkupItem)[]): Communicator.Point2 {
    if (!markups || !markups.length) return null;
    const ret = markups[0].get2dPosition();
    if (!ret) return null;

    markups.forEach((markup) => {
      if (markup) {
        const pos = markup.get2dPosition();
        if (pos) {
          if (pos.x < ret.x) ret.x = pos.x;
          if (pos.y < ret.y) ret.y = pos.y;
        }
      }
    });

    return ret;
  }

  public static getMarkupsMaxPosition(markups: (BaseRedlineItem | ADMeasurePointPointMarkupItem)[]): Communicator.Point2 {
    if (!markups || !markups.length) return null;
    const ret = markups[0].get2dPosition();
    if (!ret) return null;

    markups.forEach((markup) => {
      if (markup) {
        const pos = markup.get2dPosition();
        if (pos) {
          if (pos.x > ret.x) ret.x = pos.x;
          if (pos.y > ret.y) ret.y = pos.y;
        }
      }
    });

    return ret;
  }

  static sameFileIdRev(file1, file2) {
    const b = file1.baseFileId === file2.baseFileId
      && file1.baseMajorRev === file2.baseMajorRev
      && file1.baseMinorRev === file2.baseMinorRev;
    return b;
  }

  static getIdFileIdRev(file: any) {
    if (!file) return '';
    return `${file.baseFileId}.${file.baseMajorRev}.${file.baseMinorRev}`;
  }

  static getFileIdRev(file: any): FileIdRev {
    if (!file) {
      return {
        baseFileId: '',
        baseMajorRev: 0,
        baseMinorRev: 0,
      };
    }

    return {
      baseFileId: file.baseFileId,
      baseMajorRev: file.baseMajorRev,
      baseMinorRev: file.baseMinorRev,
    };
  }

  static groupBy(xs, keys) {
    return xs.reduce((rv, x) => {
      let gId = '';
      keys.forEach((field) => {
        if (gId === '') gId = `${x[field]}`;
        else gId += `.${x[field]}`;
      });
      (rv[gId] = rv[gId] || []).push(x);
      return rv;
    }, {});
  }

  static getSignOfData(history: string) {
    let temp: ISignoffInfo;
    if (history) {
      const nameList = history.split(/\r\n/i);
      const listHistory: ISignoff[] = [];
      nameList.forEach((s) => {
        const d = Util.stringToSignoff(s);
        if (d) {
          listHistory.push(d);
        }
      });
      // const d = Util.stringToSignoff(content);
      if (listHistory.length > 0) {
        temp = {
          current: listHistory[listHistory.length - 1],
          create: listHistory[0],
          history: listHistory,
        };
      }
    }
    if (!temp) {
      // Make a temporary history, example: 'Created By: System Administrator, On: 2022/12/16, 03:59:21 AM EST\r\n';
      const infoSignOff: ISignoff = {
        type: etypeSignoff.create,
        by: 'Anonymous',
        on: ConvertDateNowToStringExt(),
      };
      temp = {
        current: infoSignOff,
        create: infoSignOff,
        history: [],
      };
    }
    return temp;
  }

  static Camera2String(camera: Communicator.Camera) {
    const pos = camera.getPosition();
    const target = camera.getTarget();
    const up = camera.getUp();
    const proj = camera.getProjection() === 0 ? 'orthographic' : 'perspective';

    // eslint-disable-next-line max-len
    const camStr = `camera_input_data,${pos.x},${pos.y},${pos.z},${target.x},${target.y},${target.z},${up.x},${up.y},${up.z},${camera.getWidth()},${camera.getHeight()},${proj}`;
    // camera_input_data: day la keyword cho dxtool
    return camStr;
  }

  static CheckAndUpdateViewState(viewStateBS: BehaviorSubject<ViewState>, vs: ViewState) {
    const viewState = viewStateBS.value;
    if (!viewState) {
      viewStateBS.next(vs);
    } else {
      const strCur = JSON.stringify(viewState);
      const strNew = JSON.stringify(vs);
      if (strCur !== strNew) {
        viewStateBS.next(vs);
      }
    }
  }

}

export function outsideZone<T>() {
  return (source: Observable<T>) => new Observable((observer) => {
    let sub: Subscription;
    ɵɵdirectiveInject(NgZone).runOutsideAngular(() => {
      sub = source.subscribe(observer);
    });
    return sub;
  });
}

export function finalizeWithValue<T>(callback: (value: T) => void) {
  return (source: Observable<T>) => defer(() => {
    let lastValue: T;
    return source.pipe(
      tap((value) => lastValue = value),
      finalize(() => callback(lastValue)),
    );
  });
}
