/* eslint-disable no-param-reassign */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IAlert } from 'src/app/core/interfaces/interfaces';
import { ADUserSettings, FileIdRev } from 'src/app/core/common/main-viewer-common';
import { BimService } from 'src/app/core/services/api/bim.service';
import { LanguageManagerService } from 'src/app/main-viewer/services/language-manager.service';
import {
  IExtEntity, IMarkupGroup, MarkupEntity, MarkupView, ResponseMarkups,
} from 'src/app/core/common/markups';
import { SystemConstants } from 'src/app/core/common/system.constants';
import { FilelistService } from 'src/app/shared/services/filelist.service';
import { HttpClient } from '@angular/common/http';
import { IPrintOption } from 'src/app/shared/class/global';
import { AdeptInfor } from '../../core/common/AdeptInfor';
import { ConversionService } from '../../core/services/api/conversion.service';
import Util from '../../core/utils/util';
import { PermissionService } from '../../core/services/permission.service';
import { AdeptService } from '../../core/services/api/adept.service';

@Injectable({
  providedIn: 'root',
})

export class ViewSectionService {
  public currentViewSection: AdeptInfor[];

  public currentViewSection$ = new BehaviorSubject<AdeptInfor[]>(null);

  public id: string;

  public isCanSaveAdept:boolean = false;

  alertMessage = new BehaviorSubject<IAlert>(null);

  alertMessage$ = this.alertMessage.asObservable();

  mapDataUri = new Map<string, string>(); // temporary

  constructor(
    private conversionService: ConversionService,
    private permissionService: PermissionService,
    private bimService: BimService,
    private adept: AdeptService,
    private languageManagerService: LanguageManagerService,
    private filelistService: FilelistService,
    public http: HttpClient,
  ) { }

  showAlertMessage(data: IAlert) {
    this.alertMessage.next(data);
  }

  async createViewSection(data) {
    const idSession = await this.conversionService.createViewSection(data).toPromise();
    return idSession;
  }

  async getDataViewSection(id: string) {
    const result = await this.conversionService.getViewSection(id).toPromise();
    return result;
  }

  async setCurrentViewSection(viewIdData, callback) {
    this.id = viewIdData.VIEWID;
    const currentViewSection: AdeptInfor[] = await this.adept.getCurrentViewSection(viewIdData).toPromise() as AdeptInfor[];
    // sort currentViewSection by filename
    this.currentViewSection = currentViewSection.sort((n1, n2) => {
      if (n1.fileName > n2.fileName) {
        return 1;
      }

      if (n1.fileName < n2.fileName) {
        return -1;
      }

      return 0;
    });
    // console.log('CurrentViewSection: ', viewIdData.VIEWID, this.currentViewSection);
    if (this.currentViewSection) {
      let language = this.languageManagerService.curLanguage.code;
      for (let i = 0; i < this.currentViewSection.length; i += 1) {
        const temp = this.currentViewSection[i].userSettings.language;
        if (temp) {
          language = temp;
          break;
        }
      }
      this.languageManagerService.changeLanguage(language);
    }
    this.permissionService.addPermissionFromAdept(this.currentViewSection);
    for (let index = 0; index < this.currentViewSection.length; index++) {
      this.checkImportRDL(this.currentViewSection[index], () => {
        if (index === this.currentViewSection.length - 1 && callback) {
          callback && callback(this.currentViewSection);
        }
      });
    }
    this.currentViewSection$.next(this.currentViewSection);
    return this.currentViewSection;
  }

  async fixExtModelFileIdInformation(currentMarkup: ResponseMarkups, fileIdRev: FileIdRev, fileName: string, isCopy: boolean) {
    const modelFiles = await this.bimService.getAllModel(fileIdRev).toPromise(); // Get modelFiles
    if (currentMarkup.markupEntities) {
      currentMarkup.markupEntities.forEach((v) => {
        if (v && v.ModelFileId && v.extModelFileId) {
          const ext: IExtEntity = {
            extModelFileId: v.extModelFileId,
            extBaseFileId: v.extBaseFileId,
            extBaseMajorRev: v.extBaseMajorRev,
            extBaseMinorRev: v.extBaseMinorRev,
          };
          const file = this.filelistService.getFileInfoFromAddModelFileId(ext, modelFiles, fileName, isCopy);
          if (file) v.ModelFileId = file.modelFileId ?? file.id; // This file mabybe FileInfo or ResponseModelFiles
        }
      });
    }
    if (currentMarkup.listGroups) {
      currentMarkup.listGroups.forEach((v) => {
        if (v && v.ModelFileId && v.extModelFileId) {
          const ext: IExtEntity = {
            extModelFileId: v.extModelFileId,
            extBaseFileId: v.extBaseFileId,
            extBaseMajorRev: v.extBaseMajorRev,
            extBaseMinorRev: v.extBaseMinorRev,
          };
          const file = this.filelistService.getFileInfoFromAddModelFileId(ext, modelFiles, fileName, isCopy);
          if (file) v.ModelFileId = file.modelFileId ?? file.id;
        }
      });
    }
    if (currentMarkup.markupViews) {
      currentMarkup.markupViews.forEach((v) => {
        if (v && v.ModelFileId && v.extModelFileId) {
          const ext: IExtEntity = {
            extModelFileId: v.extModelFileId,
            extBaseFileId: v.extBaseFileId,
            extBaseMajorRev: v.extBaseMajorRev,
            extBaseMinorRev: v.extBaseMinorRev,
          };
          const file = this.filelistService.getFileInfoFromAddModelFileId(ext, modelFiles, fileName, isCopy);
          if (file) v.ModelFileId = file.modelFileId ?? file.id;
        }
      });
    }
    if (currentMarkup.modelViews) {
      currentMarkup.modelViews.forEach((v) => {
        if (v && v.ModelFileId && v.extModelFileId) {
          const ext: IExtEntity = {
            extModelFileId: v.extModelFileId,
            extBaseFileId: v.extBaseFileId,
            extBaseMajorRev: v.extBaseMajorRev,
            extBaseMinorRev: v.extBaseMinorRev,
          };
          const file = this.filelistService.getFileInfoFromAddModelFileId(ext, modelFiles, fileName, isCopy);
          if (file) v.ModelFileId = file.modelFileId ?? file.id;
        }
      });
    }
  }

  getDataUriImage(url): Promise<string> {
    return new Promise((resolve) => {
      let dataUri = this.mapDataUri.get(url);
      if (dataUri) resolve(dataUri);
      else {
        //
        this.http.get(url, { responseType: 'blob' }).subscribe(
          (blob: Blob) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              dataUri = reader.result as string;
              this.mapDataUri.set(url, dataUri);
              resolve(dataUri);
            };
            reader.onerror = () => {
              // reject('Failed to convert image to Data URI');
              resolve(null);
            };
            reader.readAsDataURL(blob);
          },
          (error) => {
            // reject('Failed to fetch image from the provided URL');
            resolve(null);
          },
        );
      }
    });
  }

  async AddInformationDataURI(listMarkups: MarkupEntity[]) {
    if (listMarkups && listMarkups.length > 0) {
      for (let i = 0; i < listMarkups.length; i++) {
        const v = listMarkups[i];
        const symbolsData = v?.originData?.symbolsData;
        if (symbolsData) {
          if (symbolsData.Url && !symbolsData.DataUri) {
            // eslint-disable-next-line no-await-in-loop
            symbolsData.DataUri = await this.getDataUriImage(symbolsData.Url);
          }
        }
      }
    }
  }

  // Electron: add corresponding markups for each view.
  // Because getAllMarkupMerge function also adds markups to each view on the backend.
  async convertData(markupData: string, fileIdRev: FileIdRev, fileName: string, isCopy: boolean) {
    if (!markupData || !Util.parseJson(markupData)) return null;
    const markupDataExt: ResponseMarkups = Util.parseJson(markupData);
    const { markupEntities, markupViews } = markupDataExt;
    await this.AddInformationDataURI(markupEntities);
    const markupViewsTmp = markupViews.map((view: MarkupView) => {
      const listMarkupEntities = markupEntities.filter((entity) => entity.uniqueViewId === view?.uniqueId);
      // eslint-disable-next-line no-param-reassign
      view.markup = listMarkupEntities.map((markup) => markup.originData);
      return view;
    });
    markupDataExt.markupViews = markupViewsTmp;
    await this.fixExtModelFileIdInformation(markupDataExt, fileIdRev, fileName, isCopy);
    return markupDataExt;
  }

  adjustMarkupFromAdept(markupData: string, fileIdRev: FileIdRev, fileName: string, isCopy: boolean = true) {
    Util.logPrint('adjustMarkupFromAdept');
    return new Observable<ResponseMarkups>((oberver) => {
      this.convertData(markupData, fileIdRev, fileName, isCopy).then((data) => {
        oberver.next(data);
        oberver.complete();
      });
    });
  }

  checkImportRDL(fileSection: AdeptInfor, callback) {
    const fileIdRev = {
      baseFileId: fileSection.baseFileId,
      baseMajorRev: fileSection.baseMajorRev,
      baseMinorRev: fileSection.baseMinorRev,
    };
    let reqDefault: Observable<ResponseMarkups> = null;

    if (fileSection?.isDev) { // local sysem runing
      SystemConstants.HOST_DEV = 'DEV';
      reqDefault = this.bimService.getAllMarkupMerger(fileIdRev);
    } else { // adept system call
      SystemConstants.HOST_DEV = '';
      reqDefault = this.adjustMarkupFromAdept(
        fileSection.viewItemParameters.markupData,
        fileIdRev,
        fileSection.fileName,
        fileSection.isCopy,
      );
    }

    reqDefault.subscribe((data) => {
      // eslint-disable-next-line no-param-reassign
      fileSection.viewItemParameters.markupData = JSON.stringify(data);
      // if (fileSection.isDev) fileSection.viewItemParameters.markupData = JSON.stringify(data);
      if (fileSection && fileSection.viewItemParameters.autovueRedlineConversionData) {
        if (!data || (!!data.markupEntities && data.markupEntities.length <= 0)) {
          this.conversionService.importRDL(
            JSON.stringify(
              {
                baseFileId: fileSection.baseFileId,
                baseMajorRev: fileSection.baseMajorRev,
                baseMinorRev: fileSection.baseMinorRev,
                dataRdl: fileSection.viewItemParameters.autovueRedlineConversionData,
              },
            ), true, // force
          ).subscribe(() => {
            this.isCanSaveAdept = true;
            callback && callback();
          });
        } else callback && callback();
      } else {
        callback && callback();
      }
    });
  }

  deleteViewSection(id: string) {
    this.conversionService.deleteViewSection(id).subscribe();
  }

  deleteCurrentViewSection() {
    this.conversionService.deleteViewSection(this.id).subscribe();
  }

  updateUserSettings(data: ADUserSettings) {
    let settings = this.currentViewSection.find((x) => x.userSettings)?.userSettings;
    settings = {
      ...settings,
      settings: JSON.stringify(data.settings),
    };
    this.adept.updateUserSettings(settings);
  }

  updatePublish(data: IPrintOption) {
    let settings = this.currentViewSection.find((x) => x.userSettings)?.userSettings;
    settings = {
      ...settings,
      publish: JSON.stringify(data),
    };
    this.adept.updateUserSettings(settings);
  }

  getIsCanSaveAdept() {
    return this.isCanSaveAdept;
  }

  setIsCanSaveAdept(status) {
    this.isCanSaveAdept = status;
  }
}
