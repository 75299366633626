import { Injectable } from '@angular/core';
// This still has to be declared
declare let gtag: (...[]) => void;
@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsEventsService {
  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null,
  ) {
    gtag('event', eventName, {
      eventCategory,
      eventLabel,
      eventAction,
      eventValue,
    });
  }

  public emitEvent(
    eventName: string,
    value: any,
  ) {
    gtag('event', eventName, value);
  }

  public setConfig(
    eventName: string,
    value: any,
  ) {
    gtag('set', eventName, value);
  }

  public sendConfig(
    eventName: string,
  ) {
    gtag('send', eventName);
  }
}
