import {
  Component, OnInit, Inject, HostListener,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Util from 'src/app/core/utils/util';
import { Point, eScaling } from 'src/app/shared/class/global';

export interface dataScalingDialog {
  dataInput?: eScaling;
  isOk?: boolean;
  canEdit?: boolean;
  location?: Point;
  dataOutput?: eScaling;
}

@Component({
  selector: 'app-dialog-scaling-symbols',
  templateUrl: './dialog-scaling-symbols.component.html',
  styleUrls: ['./dialog-scaling-symbols.component.scss'],
})
export class DialogScalingSymbolsComponent implements OnInit {
  eScaling = eScaling;

  selectedScaling = eScaling.ISOTROPIC;

  canEdit: boolean = true;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeDialog(false);
  }

  constructor(
    public dialogRef: MatDialogRef<DialogScalingSymbolsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: dataScalingDialog,
  ) { }

  ngOnInit(): void {
    this.selectedScaling = this.data.dataInput;
    this.updatePosition();
  }

  updatePosition() {
    const left = this.data?.location?.x + 20;
    const top = this.data?.location?.y + 20;
    const width = 400;
    const height = 215;
    const temp = {
      left, top, width, height,
    };
    const { dialogPosition } = Util.newSafePositionDialog(temp);
    this.dialogRef.updatePosition(dialogPosition);
  }

  closeDialog(status: boolean) {
    let isOk = status;
    if (this.data.dataInput === this.selectedScaling) {
      isOk = false;
    }
    this.dialogRef.close({
      isOk,
      dataOutput: this.selectedScaling,
    });
  }
}
