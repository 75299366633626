/* eslint-disable no-mixed-operators */
/* eslint-disable no-restricted-properties */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import { AnnotRect, PdfPage, PdfViewer } from 'src/@pdf_typings/foxit';
import { Boundary, CircleData, Point } from 'src/app/shared/class/global';

export class MathHelper {
  public static getAnnotByPointPassing(currentPoint: Point, pageAnnots: any[]) {
    const pageAnnotsLen = pageAnnots.length;
    const annotMatches = [];
    for (let i = 0; i < pageAnnotsLen; i++) {
      if (this.isPointOnRect(currentPoint, pageAnnots[i].rect)) {
        annotMatches.push(pageAnnots[i]);
      }
    }
    return annotMatches;
  }

  public static combineRects(...rects: AnnotRect[]): AnnotRect {
    const left = Math.min(...rects.map((rect) => rect.left));
    const top = Math.min(...rects.map((rect) => rect.top));
    const right = Math.max(...rects.map((rect) => rect.right));
    const bottom = Math.max(...rects.map((rect) => rect.bottom));
    return {
      left, top, right, bottom,
    };
  }

  public static getMinConbineRects(...rects: AnnotRect[]): AnnotRect {
    const left = Math.max(...rects.map((rect) => rect.left));
    const top = Math.max(...rects.map((rect) => rect.top));
    const right = Math.min(...rects.map((rect) => rect.right));
    const bottom = Math.min(...rects.map((rect) => rect.bottom));
    return {
      left, top, right, bottom,
    };
  }

  public static getSquare(rect: AnnotRect) {
    return Math.abs((rect.right - rect.left) * (rect.bottom - rect.top));
  }

  public static getCurrentTimeString(time: Date) {
    const yyyy = time.getFullYear();
    const mm = time.getMonth() + 1;
    const dd = time.getDate();
    const hh = time.getHours();
    const min = time.getMinutes();
    const ss = time.getSeconds();
    return [yyyy, (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd, hh, min, ss].join('');
  }

  public static getOffsetUTCTime(time: Date) {
    const offsetTime = -1 * time.getTimezoneOffset();
    const offsetHour = Math.floor(offsetTime / 60);
    const offsetMin = offsetTime - offsetHour * 60;
    return [MathHelper.toLocalString(offsetHour, 2), MathHelper.toLocalString(offsetMin, 2)].join('\'');
  }

  public static toLocalString(num: number, digits: number) {
    return num.toLocaleString('en-US', { minimumIntegerDigits: digits, useGrouping: false });
  }

  public static isPointOnRect(srcPoint: Point, rect: AnnotRect) {
    return (
      srcPoint.x >= rect.left
      && srcPoint.y >= rect.top
      && srcPoint.x <= rect.right
      && srcPoint.y <= rect.bottom
    );
  }

  public static getPointRefToRectangles(outRect: DOMRect, pointConverted: Point, inWidth: number, inHeight: number, angle: number): Point {
    const angleRadian = angle * Math.PI / 180;
    let a0: Point = pointConverted;
    if (angle >= 0 && angle <= 90) {
      const x0 = inHeight * Math.sin(angleRadian);
      a0 = { x: x0, y: 0 };
    } else if (angle > 90 && angle <= 180) {
      const alpha = Math.PI - angleRadian;
      const y0 = inHeight * Math.cos(alpha);
      a0 = { x: outRect.width, y: y0 };
    } else if (angle > 180 && angle <= 270) {
      const alpha = 3 * Math.PI / 2 - angleRadian;
      const x0 = inWidth * Math.sin(alpha);
      a0 = { x: x0, y: outRect.height };
    } else {
      const alpha = 2 * Math.PI - angleRadian;
      const y0 = inWidth * Math.sin(alpha);
      a0 = { x: 0, y: y0 };
    }
    const vectorAX = { x: pointConverted.x - a0.x, y: pointConverted.y - a0.y };
    const xRes = vectorAX.x * Math.cos(-angleRadian) - vectorAX.y * Math.sin(-angleRadian);
    const yRes = vectorAX.y * Math.cos(-angleRadian) + vectorAX.x * Math.sin(-angleRadian);
    return { x: xRes, y: yRes };
  }

  public static getBoundaryOffset(rect: AnnotRect, offset: number): AnnotRect {
    return {
      left: rect.left - offset, top: rect.top - offset, right: rect.right + offset, bottom: rect.bottom + offset,
    };
  }

  public static findCircle(point1: Point, point2: Point, point3: Point): CircleData {
    const x12 = point1.x - point2.x;
    const x13 = point1.x - point3.x;

    const y12 = point1.y - point2.y;
    const y13 = point1.y - point3.y;

    const y31 = point3.y - point1.y;
    const y21 = point2.y - point1.y;

    const x31 = point3.x - point1.x;
    const x21 = point2.x - point1.x;

    // x1^2 - x3^2
    const sx13 = Math.pow(point1.x, 2) - Math.pow(point3.x, 2);

    // y1^2 - y3^2
    const sy13 = Math.pow(point1.y, 2) - Math.pow(point3.y, 2);

    const sx21 = Math.pow(point2.x, 2) - Math.pow(point1.x, 2);
    const sy21 = Math.pow(point2.y, 2) - Math.pow(point1.y, 2);

    const f = ((sx13) * (x12)
               + (sy13) * (x12)
               + (sx21) * (x13)
               + (sy21) * (x13))
              / (2 * ((y31) * (x12) - (y21) * (x13)));
    const g = ((sx13) * (y12)
               + (sy13) * (y12)
               + (sx21) * (y13)
               + (sy21) * (y13))
              / (2 * ((x31) * (y12) - (x21) * (y13)));

    const c = -Math.pow(point1.x, 2) - Math.pow(point1.y, 2) - 2 * g * point1.x - 2 * f * point1.y;

    // eqn of circle be x^2 + y^2 + 2*g*x + 2*f*y + c = 0
    // where centre is (h = -g, k = -f) and radius r
    // as r^2 = h^2 + k^2 - c
    const h = -g;
    const k = -f;
    const sqr_of_r = h * h + k * k - c;

    // r is the radius
    const r = Math.sqrt(sqr_of_r);
    return { center: { x: h, y: k }, radius: r };
  }

  public static getTrueAngle(point1: Point, point2: Point) {
    const dy = point2.y - point1.y;
    const dx = point2.x - point1.x;
    let theta = Math.atan2(dy, dx); // range (-PI, PI]
    theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
    if (theta < 0) theta = 360 + theta; // range [0, 360)
    return theta;
  }

  public static calcCirclePath(point1: Point, point2: Point, point3: Point) {
    const distPoint3_2 = MathHelper.dist(point3, point2);
    const distPoint2_1 = MathHelper.dist(point2, point1);
    const distPoint1_3 = MathHelper.dist(point1, point3);

    const angle = Math.acos((distPoint3_2 * distPoint3_2 + distPoint2_1 * distPoint2_1 - distPoint1_3 * distPoint1_3) / (2 * distPoint3_2 * distPoint2_1));

    // calc radius of circle
    const K = 0.5 * distPoint3_2 * distPoint2_1 * Math.sin(angle);
    let radius = distPoint3_2 * distPoint2_1 * distPoint1_3 / 4 / K;
    radius = Math.round(radius * 1000) / 1000;

    const largeFlag = +(Math.PI / 2 > angle);

    const sweepFlag = +((point3.x - point1.x) * (point2.y - point1.y) - (point3.y - point1.y) * (point2.x - point1.x) < 0);

    return ['M', point1.x, point1.y, 'A', radius, radius, 0, largeFlag, sweepFlag, point3.x, point3.y].join(' ');
  }

  public static dist(point1: Point, point2: Point) {
    return Math.sqrt(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2));
  }

  public static isPointOnAnyRects(srcPoint: Point, ...rects: AnnotRect[]) {
    const len = rects.length;
    for (let i = 0; i < len; i++) {
      if (MathHelper.isPointOnRect(srcPoint, rects[i])) return true;
    }
    return false;
  }

  public static getCenterPointOfRect(rect: AnnotRect): Point {
    return { x: (rect.left + rect.right) / 2, y: (rect.top + rect.bottom) / 2 };
  }

  public static getClosestMatch(targetAnnots: any[]) {
    return targetAnnots.reduce((prev, curr) => (this.getSquare(curr.rect) < this.getSquare(prev.rect) ? curr : prev));
  }

  public static async sizeRatio(pdfViewer: PdfViewer, currentPage: number) {
    const pageRender = pdfViewer.getPDFPageRender(currentPage);
    const pdfPage = await pageRender.getPDFPage();
    const a4Witdth = 210;
    const a4Height = 297;
    const pdfWidth = pdfPage.getPxWidth();
    const pdfHeight = pdfPage.getPxHeight();
    const ratioWidth = pdfWidth / a4Witdth;
    const ratioHeight = pdfHeight / a4Height;
    const resRatio = ratioWidth > ratioHeight ? ratioWidth : ratioHeight;
    const refRatio = 3.8857142857142857;
    return resRatio / refRatio;
  }

  public static getScrollPosible(refRect: Boundary, targetRect: Boundary, pageRect: Boundary) {
    const targetCenter = this.getCenterPointOfRect(targetRect);
    const refPoint = { x: (refRect.right - refRect.left) / 2, y: (refRect.bottom - refRect.top) / 2 };
    let deltaX = targetCenter.x - refPoint.x;
    let deltaY = targetCenter.y - refPoint.y;
    const diffLeft = pageRect.left - refRect.left;
    const diffRight = pageRect.right - refRect.right;
    const diffTop = pageRect.top - refRect.top;
    const diffBottom = pageRect.bottom - refRect.bottom - 10;
    deltaX = refPoint.x > targetCenter.x ? Math.max(deltaX, diffLeft) : Math.min(deltaX, diffRight);
    deltaY = refPoint.y > targetCenter.y ? Math.max(deltaY, diffTop) : Math.min(deltaY, diffBottom);
    return { deltaX, deltaY };
  }

  public static getDevicePoint(point: number[], scale: number, pdfPage: PdfPage) {
    return pdfPage.getDevicePoint(point, scale);
  }

  public static async getDeviceRect(pdfViewer: PdfViewer, pageIndex: number, rect: AnnotRect): Promise<AnnotRect> {
    const rectRes = await MathHelper.transformRectsArray(pdfViewer, pageIndex, [rect]);
    return rectRes[0];
  }

  public static getReverseDeviceRect(rect: AnnotRect, scale: number, pdfPage: PdfPage): AnnotRect {
    return pdfPage.reverseDeviceRect(rect, scale, 0);
  }

  public static getReverseDevicePoint(point: Point, scale: number, pdfPage: PdfPage): Point {
    const pointTranslate = [point.x, point.y];
    const pointArr = pdfPage.reverseDevicePoint(pointTranslate, scale, 0);
    return { x: pointArr[0], y: pointArr[1] };
  }

  public static async transformRectsArray(pdfViewer: PdfViewer, pageIndex: number, rects: AnnotRect[]): Promise<AnnotRect[]> {
    const pageRender = pdfViewer.getPDFPageRender(pageIndex);
    const page = await pageRender.getPDFPage();
    return pageRender.transformRectArray(page, rects);
  }

  public static parseColor(color: string) {
    if (color === null) return 0;
    try {
      return Number(color.replace('#', '0xff'));
    } catch (error) {
      return 0;
    }
  }

  public static getDecimalFromColor(color: string): number {
    if (!color) return 0;
    if (color && color.includes('#')) return parseInt(color.substring(1, color.length), 16);
    const hexColor = this.convertRGBStr2Hex(color);
    return parseInt(hexColor.substring(1, hexColor.length), 16);
  }

  public static convertRGBStr2Hex(rgbStr: string) {
    const regex = /^rgb?.\((\d+),(\d+),(\d+)/g;
    const cal = regex.exec(rgbStr);
    if (cal && cal.length === 4) {
      return MathHelper.rgbToHex(+cal[1], +cal[2], +cal[3]);
    }
    return null;
  }

  public static hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    } : null;
  }

  public static rgbToHex(r: number, g: number, b: number) {
    return `#${this.componentToHex(r)}${this.componentToHex(g)}${this.componentToHex(b)}`;
  }

  public static componentToHex(c: number) {
    const hex = c.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  }

  public static decimalToHex(c: number) {
    if (!c) return null;
    let hex = c.toString(16);
    const { length } = hex;
    const numZero = 6 - length;
    for (let i = 0; i < numZero; i += 1) {
      hex = `0${hex}`;
    }
    if (hex.length > 6 && hex.length === 8) {
      hex = hex.substring(2, 8);
    }
    return hex;
  }
}
