import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnChanges, OnInit, SimpleChanges, ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileInfo } from 'src/app/core/common/main-viewer-common';
import { ConversionService } from 'src/app/core/services/api/conversion.service';
import Util from 'src/app/core/utils/util';
import { IAttachData, Point, openAttachType } from 'src/app/shared/class/global';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StatusNotify } from 'src/app/core/common/global';
import { LanguageManagerService } from 'src/app/main-viewer/services/language-manager.service';
import { NotifyComponent } from '../../common/notify/notify.component';

export interface dataAttachmentDialog {
  fileInfo?: FileInfo;
  dataInput?: IAttachData;
  isOk?: boolean;
  isCreate?: boolean;
  canEdit?: boolean;
  location?: Point;
  dataOutput?: IAttachData;
}

@Component({
  selector: 'app-dialog-attachment',
  templateUrl: './dialog-attachment.component.html',
  styleUrls: ['./dialog-attachment.component.scss'],
})
export class DialogAttachmentComponent implements OnInit, OnChanges {
  @ViewChild('fileSelect') fileSelect: ElementRef<HTMLInputElement>;

  isWaiting = false;

  openAttachType = openAttachType;

  openIn = [
    {
      value: openAttachType.newWindow,
      display: 'DIALOG.ATTACHMENT.A_NEW_AUTOVUE_WINDOW',
    },
    {
      value: openAttachType.currentWindow,
      display: 'DIALOG.ATTACHMENT.CURRENT_AUTOVUE_WINDOW',
    },
    {
      value: openAttachType.associatedApplication,
      display: 'DIALOG.ATTACHMENT.ASSOCIATED_APPLICATION',
    },
  ]

  selected = openAttachType.associatedApplication;

  linkName = '';

  linkUrl = '';

  fileName = '';

  description = '';

  @ViewChild('fileInput') fileInput = null;

  dataInject: dataAttachmentDialog = {};

  file: File | null = null;

  requiredName: boolean;

  requiredUrl: boolean;

  isInputName: boolean = false;

  isChooseFile: boolean = false;

  canEdit = true;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeDialog(false);
  }

  constructor(
    public dialogRef: MatDialogRef<DialogAttachmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: dataAttachmentDialog,
    private cdRef: ChangeDetectorRef,
    private conversionService: ConversionService,
    private languageManagerService: LanguageManagerService,
    private snackBar: MatSnackBar,
  ) {}

  updateDetectChange() {
    this.cdRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  closeDialog(status: boolean) {
    if (status) {
      this.isInputName = true;
      this.isChooseFile = true;
      this.checkRequired();
      if (this.requiredName || this.requiredUrl) return;
    } else {
      this.dialogRef.close(this.dataInject);
      return;
    }
    this.isWaiting = true;
    const pipe = new DatePipe('en-US');
    const todayString : string = pipe.transform(new Date(), 'MM/dd/yy-HH:mm:ss');
    const date = this.data.dataInput.date ? this.data.dataInput.date : todayString;
    this.dataInject.dataOutput = {
      linkName: this.linkName,
      description: this.description,
      URL: this.linkUrl,
      openIn: this.selected,
      author: this.data.dataInput.author,
      date: this.dataInject.dataInput.date ? this.dataInject.dataInput.date : date,
      fileName: this.file ? this.file.name : this.data.dataInput.fileName,
      from: this.file ? this.file.name : this.data.dataInput.fileName,
    };
    const dataInput = JSON.stringify(this.dataInject.dataInput);
    const dataOutput = JSON.stringify(this.dataInject.dataOutput);
    if (dataInput !== dataOutput) {
      this.dataInject.isOk = true;
      if (!status) this.dataInject.isOk = false;
    } else {
      this.dataInject.isOk = false;
    }
    if (this.dataInject.isOk) {
      if (this.file) {
        const reader = new FileReader();
        reader.onload = async () => {
          const blob = new Blob([reader.result], { type: this.file.type });
          let subdir = '';
          if (this.data.fileInfo) {
            subdir = `${this.data.fileInfo.baseFileId}.${this.data.fileInfo.baseMajorRev}.${this.data.fileInfo.baseMinorRev}`;
          }
          const result = this.conversionService.UpLoadAttachment(blob, subdir, this.file.name);
          result && result.subscribe((v) => {
            this.dataInject.dataOutput.URL = v;
            this.isWaiting = false;
            this.dialogRef.close(this.dataInject);
          }, (error) => {
            const translations = this.languageManagerService.getCurentTranslations();
            this.isWaiting = false;
            this.snackBar.openFromComponent(NotifyComponent, {
              panelClass: 'custom-container-notify',
              data: {
                message: translations.DIALOG.ATTACHMENT.ERROR_UPLOAD,
                status: StatusNotify.warning,
              },
              duration: 5 * 1000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
            });
          });
        };
        reader.readAsArrayBuffer(this.file);
      } else {
        this.dialogRef.close(this.dataInject);
      }
    } else {
      this.dialogRef.close(this.dataInject);
    }
  }

  ngOnInit(): void {
    this.dataInject = this.data;
    if (this.data?.dataInput) {
      this.linkName = this.data.dataInput.linkName;
      this.description = this.data.dataInput.description;
      this.linkUrl = this.data.dataInput.URL;
      this.fileName = this.data.dataInput.fileName;
      this.selected = this.data.dataInput.openIn;
      this.canEdit = this.data.canEdit;
    }
    // this.conversionService.DownloadAttachment(this.linkUrl).subscribe((res) => {
    // }, (error) => {
    //   this.data.isCreate = true;
    //   this.linkUrl = '';
    //   this.fileName = '';
    // });
    if (!this.linkUrl) {
      this.data.isCreate = true;
    }
    this.checkRequired();
    this.updatePosition();
  }

  updatePosition() {
    const left = this.data?.location?.x + 20;
    const top = this.data?.location?.y + 20;
    const width = 400;
    const height = 420;
    const temp = {
      left, top, width, height,
    };
    const { dialogPosition } = Util.newSafePositionDialog(temp);
    this.dialogRef.updatePosition(dialogPosition);
  }

  async onClickFileInputButton(): Promise<void> {
    this.fileSelect.nativeElement.onchange = () => {
      // eslint-disable-next-line prefer-destructuring
      this.file = this.fileSelect.nativeElement.files[0];
      this.linkUrl = `upload://${this.file.name}`;
      this.fileName = `upload://${this.file.name}`;
      this.checkRequired();
    };
    this.fileSelect.nativeElement.click();
  }

  checkRequired() {
    this.requiredName = this.linkName === '' || this.linkName === undefined;
    this.requiredUrl = this.linkUrl === '' || this.linkUrl === undefined;
    this.updateDetectChange();
  }

  changeName($event) {
    this.isInputName = true;
    const textBox = $event.target as HTMLInputElement;
    if (textBox) {
      this.linkName = textBox.value;
    }
    this.checkRequired();
  }

  changeDescription($event) {
    const textBox = $event.target as HTMLTextAreaElement;
    if (textBox) {
      this.description = textBox.value;
    }
  }

  changeURL($event) {
    const textBox = $event.target as HTMLInputElement;
    if (textBox) {
      this.linkUrl = textBox.value;
    }
    this.checkRequired();
  }
}
