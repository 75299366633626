import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  PermissionExtend,
  Permission,
  CheckPermissionType,
  WaterMarkConfig,
} from '../common/permission';
import { UtilExtend } from '../utils/util-extend';
import {
  AdeptInfor,
  createAdeptInfo,
  UserSettings,
} from '../common/AdeptInfor';
import { FileIdRev, FileInfo, ViewActive } from '../common/main-viewer-common';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  public defaultPermission: PermissionExtend[];

  public listPermission: PermissionExtend[];

  public listUser: Map<string, AdeptInfor> = new Map<string, AdeptInfor>();

  public listPermissionOfFile: Map<string, PermissionExtend[]> = new Map<
    string,
    PermissionExtend[]
  >();

  public permission$ = new BehaviorSubject<PermissionExtend[]>(null);

  public listWaterMarkOfFile: Map<string, WaterMarkConfig> = new Map<
    string,
    WaterMarkConfig
  >();

  public listMarkupAutoVue: Map<string, string> = new Map<string, string>();

  public listMarkupData: Map<string, string> = new Map<string, string>();

  public watermarkHeaderConfig = new BehaviorSubject<WaterMarkConfig>(
    new WaterMarkConfig()
  );

  // John Lee _ 09/04/2024 _ Variable save value header&footer and watermark for Print PDF
  public watermarkHeaderConfigPrint = new BehaviorSubject<WaterMarkConfig>(
    new WaterMarkConfig()
  );

  public fileActive: FileInfo;

  constructor() {}

  public init() {
    this.defaultPermission = [
      {
        name: Permission.CAN_CONVERT,
        value: true,
      },
      {
        name: Permission.CAN_PRINT,
        value: true,
      },
      {
        name: Permission.CAN_REDLINE,
        value: true,
      },
      {
        name: Permission.LOAD_FIT,
        value: true,
      },
      {
        name: Permission.EDIT_MARKUP,
        value: true,
      },
      {
        name: Permission.SAVE_EXISTING_MARKUP,
        value: true,
      },
      {
        name: Permission.SAVE_NEW_MARKUP,
        value: true,
      },
      {
        name: Permission.OPEN_MARKUP,
        value: true,
      },
      {
        name: Permission.AUTO_OPEN_MARKUP,
        value: true,
      },
      {
        name: Permission.DELETE_MARKUP,
        value: true,
      },
      {
        name: Permission.RENDERING_COLOR,
        value: true,
      },
      {
        name: Permission.SAVE_TO_PDF,
        value: true,
      },
      {
        name: Permission.CAN_COMPARE,
        value: true,
      },
    ];
    this.listPermission = this.defaultPermission.map((x) => ({ ...x }));
    this.updateListPermission(this.listPermission);
  }

  registerActiveView(viewActive: ViewActive) {
    this.fileActive = viewActive.data;
    this.updateListPermission(this.getPermissionOfFile(UtilExtend.getIdFileIdRev(viewActive)));
    this.updateWatermarkHeaderConfig(
      this.getWaterMarkFileBy(UtilExtend.getFileIdRev(viewActive))
    );
  }

  setPermissionOfFile(key: string, list: PermissionExtend[]) {
    this.listPermissionOfFile.set(key, list);
  }

  setAutoVueOfFile(key: string, data: string) {
    this.listMarkupAutoVue.set(key, data);
  }

  setMarkupData(key: string, data: string) {
    this.listMarkupData.set(key, data);
  }

  setUser(key: string, data: AdeptInfor) {
    this.listUser.set(key, data);
  }

  getUser(key: string) {
    return this.listUser.get(key);
  }

  getPermissionOfFile(key): PermissionExtend[] {
    const result = this.listPermissionOfFile.get(key);
    return result || this.defaultPermission.map((x) => ({ ...x }));
  }

  getAllPermissionOfFiles(
    lst: FileInfo[],
    userName = '',
    userSettings: UserSettings = null
  ): AdeptInfor[] {
    const listFileAdept: AdeptInfor[] = [];
    lst.forEach((file) => {
      const fileId = UtilExtend.getIdFileIdRev(file);
      const permiss = this.listPermissionOfFile.get(fileId);
      const waterMark = this.listWaterMarkOfFile.get(fileId);
      const markupAutoVue = this.listMarkupAutoVue.get(fileId);
      const markupData = this.listMarkupData.get(fileId);
      if (permiss && waterMark) {
        listFileAdept.push(
          createAdeptInfo(
            file,
            permiss,
            waterMark,
            markupAutoVue,
            markupData,
            userName,
            userSettings
          )
        );
      } else {
        listFileAdept.push(
          createAdeptInfo(
            file,
            this.defaultPermission,
            new WaterMarkConfig(),
            markupAutoVue,
            markupData,
            userName,
            userSettings
          )
        );
      }
    });
    return listFileAdept;
  }

  setPermissionToFile(fileIdRev: FileIdRev) {
    const fileId = UtilExtend.getIdFileIdRev(fileIdRev);
    this.setPermissionOfFile(fileId, this.listPermission);
  }

  addPermissionFromAdept(listFileAdept: AdeptInfor[]) {
    let addSuccess = false;
    listFileAdept.forEach((file) => {
      let result: PermissionExtend[] = [];
      const fileId = UtilExtend.getIdFileIdRev(file);
      this.setUser(fileId, file);
      if (file.viewItemParameters) {
        result = UtilExtend.mapValuePermission(file.viewItemParameters);
        if (
          file.viewItemParameters.permissions &&
          file.viewItemParameters.waterMark &&
          file.viewItemParameters.printHeader
        ) {
          result = result.concat(
            UtilExtend.mapValuePermission(file.viewItemParameters.permissions)
          );
          const waterMark: WaterMarkConfig = new WaterMarkConfig();
          waterMark.Init(file.viewItemParameters);
          this.setPermissionOfFile(fileId, result);
          this.setWaterMarkFile(file, waterMark);
          this.setAutoVueOfFile(
            fileId,
            file.viewItemParameters.autovueRedlineConversionData
          );
          addSuccess = true;
        }
      }
    });
    return addSuccess;
  }

  updateListPermission(value: PermissionExtend[]) {
    if (value) {
      this.permission$.next(value);
    }
  }

  private updatePermission({
    name: namePermission,
    value,
    disable = false,
  }: PermissionExtend) {
    const originListPermission = this.permission$.value;
    const result = originListPermission.map((item) => {
      let temp = item;
      if (item.name === namePermission) {
        temp = {
          name: namePermission,
          value,
          disable,
        };
      }
      return temp;
    });

    this.listPermission = result;
  }

  getValuePermission(
    permission: Permission,
    isBoolean = true
  ): boolean | number {
    return UtilExtend.getValuePermission(
      this.permission$.value,
      permission,
      isBoolean
    );
  }

  getValuePermissionFromFile(
    fileIdRev: FileIdRev,
    permission: Permission,
    isBoolean = true,
  ): boolean | number {
    const fileId = UtilExtend.getIdFileIdRev(fileIdRev);
    return UtilExtend.getValuePermission(
      this.getPermissionOfFile(fileId),
      permission,
      isBoolean
    );
  }

  setPermission(permission: PermissionExtend) {
    this.updatePermission(permission);
  }

  hasPermissions(
    permission: Permission[] | Permission,
    type: CheckPermissionType = 'all'
  ): boolean {
    return UtilExtend.hasPermissions(this.permission$.value, permission, type);
  }

  setWaterMarkFile(fileIdRev: FileIdRev, data: WaterMarkConfig) {
    const fileId = UtilExtend.getIdFileIdRev(fileIdRev);
    this.listWaterMarkOfFile.set(fileId, data);
  }

  getWaterMarkFileBy(fileIdRev: FileIdRev) {
    const fileId = UtilExtend.getIdFileIdRev(fileIdRev);
    return this.listWaterMarkOfFile.get(fileId);
  }

  updateWatermarkHeaderConfig(value: WaterMarkConfig) {
    if (value) {
      this.watermarkHeaderConfig.next(value);
    }
  }

  updateWatermarkHeaderConfigPrint(value: WaterMarkConfig) {
    if (value) {
      this.watermarkHeaderConfigPrint.next(value);
    }
  }

  getWaterMarkFile() {
    return this.watermarkHeaderConfig.value;
  }

  getWaterMarkFilePrint() {
    return this.watermarkHeaderConfigPrint.value || this.watermarkHeaderConfig.value;
  }

  getStringWaterMarkFile() {
    return JSON.stringify(this.watermarkHeaderConfig.value, null, 2);
  }

  getStringWaterMarkFileById(fileIdRev: FileIdRev) {
    const value = this.getWaterMarkFileBy(fileIdRev);
    return JSON.stringify(value || new WaterMarkConfig(), null, 2);
  }
}
