/* eslint-disable no-lone-blocks */
/* eslint-disable no-console */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import StateMultiViewer from 'src/app/core/class/multi-viewer/state-viewer';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { DialogExtendService } from 'src/app/shared/services/dialog-extend.service';
import { PdfViewerManager } from 'src/app/pdf/service/PdfViewerManager';
import { ChangeDetectionService } from 'src/app/main-viewer/services/change-detection.service';
import { FilesStatic } from 'src/app/core/class/FilesStatic';
import { DataFileList } from 'src/app/shared/services/filelist.service';
import { TypeSelectLayoutMenu } from 'src/app/shared/components/main-viewer/layout-menu/layout-menu-common';
import { CadViewAction, OpenFileType, PrintCreatorParam, ViewState } from 'src/app/shared/class/global';
import { LanguageManagerService } from '../../main-viewer/services/language-manager.service';
import { ViewerDisplayService } from './viewer-display.service';
import { MultiLayoutService } from './multi-layout.service';
import {
  FileInfo,
  ViewActive,
  TypeLayout,
  ModeChangeDetection,
  SheetData,
  FileIdRev,
  LayerData,
  FormatViewer,
  CadViewData,
} from '../common/main-viewer-common';
import { ConnectMultiviewService } from './connect-multiview.service';

import { UtilExtend, LogType, LogTypeClass } from '../utils/util-extend';
import { OnDestroyService } from '../interfaces/interfaces';
import { Connect3dviewerService } from './connect-3dviewer.service';
import { MarkupsService } from './markups.service';
import { SystemConstants } from '../common/system.constants';
import { AdeptService } from './api/adept.service';
import { PermissionService } from './permission.service';
import Util from '../utils/util';
import { MarkupView, ResponseMarkups, ViewerInfo } from '../common/markups';
import { NotifiedData, StatusNotify } from '../common/global';
import { ConversionService } from './api/conversion.service';
import { StreamService } from './api/stream.service';

@Injectable({
  providedIn: 'root',
})
export class MultiViewService implements OnDestroyService, OnDestroy {

  private closeFile: BehaviorSubject<FileInfo> = new BehaviorSubject<FileInfo>(null);

  closeFile$ = this.closeFile.asObservable();

  private waiting: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  waiting$ = this.waiting.asObservable();

  setWaiting(value: boolean) {
    this.waiting.next(value);
  }

  showFloatBox: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public listItemsView: FileInfo[] = [];

  public viewActive: ViewActive = {
    index: null,
    baseFileId: null,
    baseMajorRev: 0,
    baseMinorRev: 0,
    data: null,
    isBlank: true,
    viewId: null,
  };

  public viewActive$ = this.viewerDisplayService.viewActive$;

  public initItems = new BehaviorSubject<FileInfo[]>([]);

  public initItems$ = this.initItems.asObservable();

  public stateMultiViewer: StateMultiViewer;

  private subs = new Subscription();

  public mapMergeParentChild: Map<string, string[]>;

  public markupsService: MarkupsService;

  printCreatorParam: PrintCreatorParam = null; // option using in printing

  // [20241113][ADV-7805][ADV-7308][phuong_td] Subject to give signals to start acting Print
  subjectPdfConverter: Subject<boolean> = new Subject();
  // heightOfViewer = new BehaviorSubject<number>(0);

  private heightOfViewer = new BehaviorSubject<number>(0);

  heightOfViewer$ = this.heightOfViewer.asObservable();

  subscribeCheckClose: Subscription = null;

  curViewClick: {
    index: number;
    fileInfo: FileInfo;
  } = {
    index: 0,
    fileInfo: null,
  };

  setCurViewClick(view: {
    index: number;
    fileInfo: FileInfo;
  }) {
    this.curViewClick = view;
  }

  private onDestroy$ = new Subject<any>();

  setHeightOfViewer(height: number) {
    if (height !== 0) {
      this.heightOfViewer.next(height);
    }
  }

  getheightOfViewer() {
    return this.heightOfViewer.value;
  }

  public isDeleteView = new BehaviorSubject<boolean>(false);

  public isDirect = false;

  public listViewerInfo: ViewerInfo[] = [];

  public mapViewState$ = new Map<string, BehaviorSubject<ViewState>>();

  // Map giữ các Subscription của ViewState
  public mapViewStateSubs = new Map<string, Subscription>();

  // Map giữ viewCustom ID được chọn cho từng ViewActive
  public mapViewSelect = new Map<string, string|number>();

  // Map giữ viewCustom ID để đặt trạng thái Eye cho view trong View Panel => Saved Views
  public mapViewDisplay = new Map<string, string|number>();

  public isComparisonMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  mapLayerDataWithView = new Map<string, LayerData[]>();

  hasChangeLayer = new Map<string, boolean>();

  listHideNodeByContextMenu = new Map<string, number[]>();

  listHideNodeByLayer = new Map<string, number[]>();

  isActiveLayer$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  fileInfo: FileInfo;

  isSelectFull: TypeLayout;

  // [18/11/2024][ADV-7694][phuong_td] Hold CadViewID Active of each separate view
  public mapCadViewIDWithView = new Map<string, BehaviorSubject<CadViewAction>>();

  // [30/11/2024][ADV-7952][John Lee] use set layer when active DocumentView (ViewCad)
  isActiveDocumentView: boolean = false;

  getCadViewIDWithView(id: string): BehaviorSubject<CadViewAction> {
    const CadViewIDWithView = this.mapCadViewIDWithView.get(id);
    if (CadViewIDWithView) return CadViewIDWithView;
    const obser = new BehaviorSubject<CadViewAction>(null);
    this.mapCadViewIDWithView.set(id, obser);
    return obser;
  }

  setCadViewIDWithView(viewid: string, data: CadViewAction) {
    let obser = this.mapCadViewIDWithView.get(viewid);
    if (obser) obser.next(data);
    else {
      obser = new BehaviorSubject<CadViewAction>(data);
      this.mapCadViewIDWithView.set(viewid, obser);
    }
  }

  setIsActiveLayer(value: boolean) {
    this.isActiveLayer$.next(value);
  }

  setMapLayerDataWithView(viewId: string, data: LayerData[]) {
    if (data) {
      this.mapLayerDataWithView.set(viewId, data);
      this.setIsActiveLayer(!!data.length);
    }
  }

  getChildNodeAllLayer(type: 'show' | 'hide' | 'all', _viewId?: string) {
    const nodeIds: number[] = [];
    const listLayer = this.mapLayerDataWithView.get(_viewId || this.viewActive.viewId);
    listLayer && listLayer.forEach((layer) => {
      let isGet = true;
      switch (type) {
        case 'show':
          isGet = layer.isShow;
          break;
        case 'hide':
          isGet = !layer.isShow;
          break;
        default:
          break;
      }
      if (layer && layer.nodeChildrenIds?.length && isGet) {
        nodeIds.push(...layer.nodeChildrenIds);
      }
    });
    return nodeIds;
  }

  getDisableLayer(viewId: string) {
    const listLayer = this.mapLayerDataWithView.get(viewId);
    // John Lee _ March 15 _ fix update ADV-6901 -> Show/hide layer is related to pdf export
    const s = listLayer && listLayer.filter((lay) => (!(lay.isPublishPdf && lay.isShow) ? lay : null));
    return s;
  }

  updateStatusLayer(status: boolean, fileInfo: FileInfo) {
    const layers = this.mapLayerDataWithView.get(this.viewActive.viewId);
    const allLayers = this.mapLayerDataWithView.get(fileInfo.baseFileId);
    // John Lee _ 09/07/2024 _ check null all layer
    if (!allLayers) {
      layers && layers.forEach((layer) => {
        layer.isShow = status;
      });
      this.setMapLayerDataWithView(this.viewActive.viewId, layers);
      return layers;
    }
    layers && layers.forEach((layer) => {
      layer.isShow = status;
    });
    allLayers && allLayers.forEach((layer) => {
      layer.isShow = status;
    });
    this.setMapLayerDataWithView(this.viewActive.viewId, layers);
    this.setMapLayerDataWithView(fileInfo.baseFileId, allLayers);
    return allLayers;
  }

  updateAllStatusLayerByViewId(status: boolean, _viewId: string) {
    const layers = this.mapLayerDataWithView.get(_viewId);
    layers && layers.forEach((layer) => {
      layer.isShow = status;
    });
    return layers;
  }

  public isModelReset = new BehaviorSubject<boolean>(false);

  setIsModelReset() {
    this.isModelReset.next(true);
  }

  constructor(
    public multiLayoutService: MultiLayoutService,
    public viewerDisplayService: ViewerDisplayService,
    private dialogExtendService: DialogExtendService,
    public connectAllViewService: ConnectMultiviewService,
    public connect3dViewerService: Connect3dviewerService,
    private changeDetectionService: ChangeDetectionService,
    private adeptService: AdeptService,
    private permission: PermissionService,
    private languageManagerService: LanguageManagerService,
    private conversionService: ConversionService,
    private streamService: StreamService,
  ) {
    UtilExtend.Log(LogType.Init, 'multi view', LogTypeClass.Service);
    this.init();
  }

  ngOnDestroy(): void {
    UtilExtend.onDestroy(this.onDestroy$);
  }

  // [18/11/2024][ADV-7694][phuong_td] Keep cadViewidwithview subscription of the current view
  private currentCadViewIDWithViewSub: Subscription;

  init() {
    this.subscribeListViewDisplay();
    this.stateMultiViewer = new StateMultiViewer(this.listItemsView);
    // [18/11/2024][ADV-7694][phuong_td] Register to track CadViewID when viewactive changes
    this.viewActive$.pipe(takeUntil(this.onDestroy$)).subscribe((view) => {
      if (view && view.viewId) {
        if (this.currentCadViewIDWithViewSub) {
          // Cancellation of subscribers before new registration
          this.currentCadViewIDWithViewSub.unsubscribe();
        }
        const CadViewIDWithView = this.getCadViewIDWithView(view.viewId);
        this.currentCadViewIDWithViewSub = CadViewIDWithView.pipe(takeUntil(this.onDestroy$)).subscribe((cadViewAction) => {
          // [19/11/2024][ADV-7694][phuong_td] Do not update the layer status when changing the cadView for other DGN files
          const ext = UtilExtend.getExtensionFile(this.viewActive.data.originalFile);
          if (cadViewAction && ext === 'dgn') this.ApplyLayerStateCadView(view.data, cadViewAction); // Update status for layer
        });
      }
    });
  }

  updateListViewDisplay(
    list: FileInfo[] = this.listItemsView,
    listHide: FileInfo[] = null
  ) {
    this.viewerDisplayService.updateListViewDisplay(list, listHide);
  }

  getListViewDisplay() {
    return this.viewerDisplayService.getListViewDisplay();
  }

  emitBlankItem() {
    const blankView = this.createBlankView();
    this.initItems.next([blankView]);
  }

  subscribeListViewDisplay() {
    const sub = this.viewerDisplayService.listViewDisplay$
      .pipe(filter((value) => !!value && value.length > 0))
      .subscribe((listview) => {
        this.listItemsView = listview;
      });
    this.subs.add(sub);
  }

  private setViewActive(newViewActive: ViewActive) {
    this.markupsService.mapListMarkupSelectWithView(this.viewActive);
    this.markupsService.checkSwitchToView(newViewActive).then((v) => {
      if (v) {
        this.viewActive = newViewActive;
        this.viewActive.format = UtilExtend.getFormatViewer(newViewActive.data);
        this.viewerDisplayService.setViewActive(this.viewActive);
        this.deleteFileHidden();
        try {
          const modelInfo = this.getModelInfo(newViewActive.viewId);
          const sheetId = modelInfo?.webviewer?.sheetManager?.getActiveSheetId();
          const parentViewId = this.markupsService.getParentViewId(newViewActive.viewId);
          this.updateViewStateHoop(parentViewId, newViewActive.viewId, sheetId, modelInfo?.webviewer?.view?.getCamera());
        } catch {
          //
        }
      }
    });
  }

  deleteFileHidden() {
    this.listItemsView.map((view) => {
      if (view?.startViewTime === null || view?.startViewTime === undefined) {
        view.startViewTime = Date.now();
      }
      return view;
    });
    const listHide = this.listItemsView.filter((view) => view.isHide && !view.isBlank && !view.multiStream,);
    const listHideMerge = this.listItemsView.filter((view) => view.isHide && view.multiStream && view.viewIdChildren,);
    const listViewDisplay = this.viewerDisplayService.getListViewDisplay();
    const newListHideMerge = listHideMerge.filter((view) => !listViewDisplay.some((v) => v.baseFileId === view.baseFileId && !v.isHide));
    const listHideItem = [...listHide, ...newListHideMerge].sort((a, b) => b.startViewTime - a.startViewTime);
    if (listHideItem.length > SystemConstants.MAX_STREAMER_INACTIVE) {
      for (let k = SystemConstants.MAX_STREAMER_INACTIVE; k < listHideItem.length; k++) {
        const item = listHideItem[k];
        const index = listViewDisplay.findIndex((i) => i.viewId === item.viewId);
        item.startViewTime = null;
        if (index >= 0) this.closeItemHidden(item, index, null, false, false);
      }
    }
  }

  setActiveView(indexTarget: number, item: FileInfo) {
    if (item) {
      const viewActive: ViewActive = {
        index: indexTarget,
        baseFileId: item.baseFileId,
        baseMajorRev: item.baseMajorRev,
        baseMinorRev: item.baseMinorRev,
        viewId: item.viewId,
        name: item.filename,
        data: item,
        isBlank: item.isBlank ?? false,
      };
      this.setViewActive(viewActive);
    }
  }

  resetActiveView() {
    const reFindFirstItemVisibility = this.getFirstItemFileListWithCondition(
      (item) => !item.isHide
    );
    let result: { item: FileInfo; index: number };
    if (reFindFirstItemVisibility) {
      result = reFindFirstItemVisibility;
    } else {
      const [firstItem, ...temp] = this.listItemsView;
      result = { item: firstItem, index: 0 };
    }
    result.item.isHide = false;
    this.viewActive = {
      index: result.index,
      baseFileId: result.item.baseFileId ?? null,
      baseMajorRev: result.item.baseMajorRev ?? 0,
      baseMinorRev: result.item.baseMinorRev ?? 0,
      viewId: result.item.viewId ?? null,
      name: result.item.filename ?? null,
      data: result.item,
      isBlank: result.item.isBlank ?? null,
    };
    this.setViewActive(this.viewActive);
  }

  getIndexFileSelected(file: FileInfo): number | undefined {
    return this.listItemsView.findIndex((v) => v.viewId === file.viewId);
  }

  /** Addition drag drop */
  changeAnimation(elementTarget: HTMLElement) {
    const { top, left } = elementTarget.getBoundingClientRect();
    const elementPreview = document.getElementsByClassName(
      'cdk-drag-preview'
    )[0] as HTMLElement;
    setTimeout(() => {
      elementPreview.style.setProperty(
        'transform',
        `translate(${left}px, ${top}px)`,
        'important'
      );
    }, 100);
  }

  /**
   * Map list item file view
   * @param items
   */
  mapItems(items: FileInfo[]): FileInfo[] {
    // log
    const re = items.map((item) => {
      const uniqueId = Communicator.UUID.create();
      const newId = `new-${uniqueId}`;
      item.viewId = newId;
      item.isBlank !== true && (item.isBlank = false);
      item.isHide = false;
      this.stateMultiViewer.setViewerState(newId);
      return item;
    });
    return re;
  }

  loadFileIntoBlankView(filelist: FileInfo[]): number {
    const mapBlankView = this.listItemsView.reduce(
      (re: number[], curr, index) => {
        // loc them cac blank dang bi an, nhung loai nay nen xoa di
        curr.isBlank && !curr.isHide && re.push(index);
        return re;
      },
      []
    );
    const lengthBlankView = mapBlankView.length;
    const lengthFileList = filelist.length;
    const newFileInfo: FileInfo[] = [];
    if (lengthBlankView > 0 && lengthFileList > 0) {
      let blankIndex = 0;
      for (let i = 0; i < filelist.length; i += 1) {
        const temp: FileInfo = filelist[i];
        const Sheets = temp?.extraConvert?.Sheets as {
          id: string;
          name: string;
          isActive: boolean;
          is2D: boolean;
        }[];
        const sheet = Sheets?.find((s) => s.isActive);
        let status = false;
        if (temp) {
          status = true;
        }
        if (sheet) {
          status = temp.filename === sheet.id;
        }
        if (status) {
          const newFileInfoTemp = this.replaceFileView(
            mapBlankView[blankIndex],
            filelist[i],
            false,
          );
          newFileInfo.push(newFileInfoTemp);
          const blankView = this.listItemsView[mapBlankView[blankIndex]];
          if (blankView) blankView.isHide = true;
          blankIndex += 1;
        }
        if (blankIndex > lengthBlankView) {
          break;
        }
      }
      setTimeout(() => {
        this.changeDetectionService.emitChangeDetection(
          ModeChangeDetection.ContentPanel
        );
        this.setActiveView(0, newFileInfo[0]);
      }, 100);
    }
    return lengthFileList;
  }

  numberFileExist(fileInfo: FileInfo): number {
    return this.listItemsView.reduce((pre, curr) => {
      if (UtilExtend.sameFileIdRev(curr, fileInfo)) {
        pre += 1;
      }
      return pre;
    }, 0);
  }

  getNewFileInfo(fileInfo: FileInfo): FileInfo {
    return fileInfo;
  }

  replaceFileView(
    index: number,
    fileInfo: FileInfo,
    setViewActive: boolean = true
  ): FileInfo {
    const prevFileInfo = this.listItemsView[index];
    prevFileInfo && this.disposeViewer(prevFileInfo.viewId);
    fileInfo.isHide = false;
    const newFileInfo = this.getNewFileInfo(fileInfo);
    setTimeout(() => {
      this.listItemsView.splice(index, 1, newFileInfo);
      prevFileInfo && this.deleteViewHiddenMerger(prevFileInfo, this.listItemsView);
      this.stateMultiViewer.setViewerState(newFileInfo.viewId);
      this.updateListViewDisplay(this.listItemsView);
      setViewActive && this.setActiveView(index, newFileInfo);
    }, SystemConstants.TIME_DELAY_DIALOG_CLOSE);
    return fileInfo;
  }

  replaceFileByHidden(
    index: number,
    fileInfo: FileInfo,
    hideItem: FileInfo = null
  ) {
    const prevFileInfo = this.listItemsView[index];
    prevFileInfo.isHide = true;
    const mapViewIdList = this.listItemsView.map((v) => v.viewId);
    fileInfo.isHide = false;
    if (mapViewIdList.includes(fileInfo.viewId)) {
      const indexOld = this.listItemsView.findIndex(
        (v) => v.viewId === fileInfo.viewId
      );
      moveItemInArray(this.listItemsView, indexOld, index);
    } else {
      this.listItemsView.splice(index, 0, fileInfo);
      this.replaceFileOriginViewOnly(prevFileInfo);
    }
    this.updateListViewDisplay(this.listItemsView, [hideItem]);
    this.connectAllViewService.refreshViewerCanvas();
    this.setActiveView(index, fileInfo);
    this.setSelectMode(fileInfo);
  }

  activeParentSheet(parentId: string, childId: string) {
    const { index: indexParent, item: fileInfoParent } =
      this.getFirstItemFileListWithCondition((f) => f.viewId === parentId);
    const { index: indexChild, item: fileInfoChild } =
      this.getFirstItemFileListWithCondition((f) => f.viewId === childId);
    fileInfoChild.isHide = true;
    fileInfoParent.isHide = false;
    moveItemInArray(this.listItemsView, indexParent, indexChild);
    if (this.viewActive.viewId === fileInfoChild.viewId) {
      this.setActiveView(indexChild, fileInfoParent);
    }
    this.updateListViewDisplay(this.listItemsView);
  }

  setSelectMode(fileInfo: FileInfo) {
    const { viewId } = fileInfo;
    const pdfComponent = PdfViewerManager.getViewer(viewId);
    if (pdfComponent) {
      // pdfComponent.markupManager.drawOperator.selectTextAnnotation();
    }
    const modelInfor = this.connect3dViewerService.getModelInfo(viewId);
    if (modelInfor) {
      // modelInfor.operatorService.DefaultOperator();
    }
  }

  replaceFileOriginViewOnly(originFile: FileInfo) {
    const func = (f: FileInfo) =>
      !f.isHide &&
      !!f.viewOnlyModeOriginViewId &&
      f.viewOnlyModeOriginViewId === originFile.viewId;
    const firstFileViewOnly = this.getFirstItemFileListWithCondition(func);
    if (firstFileViewOnly) {
      this.listItemsView.splice(firstFileViewOnly.index, 1);
      const indexOriginFile = this.listItemsView.findIndex(
        (f) => f.viewId === originFile.viewId
      );
      moveItemInArray(
        this.listItemsView,
        indexOriginFile,
        firstFileViewOnly.index
      );
      originFile.isHide = false;
    }
  }

  checkModeMarkup(viewId: string): boolean {
    const undoManager = this.markupsService.getUndoManager(viewId);
    if (undoManager) return undoManager.canSave();
    return false;
    // return this.connectAllViewService.checkMarkupStatus(viewId);
  }

  setModeMarkupNochange(viewId: string) {
    const undoManager = this.markupsService.getUndoManager(viewId);
    if (undoManager) undoManager.setSaveIndex();
    // return this.connectAllViewService.checkMarkupStatus(viewId);
  }

  checkAndSaveMarkup(item: DataFileList, checkSave: Subject<boolean> = null) {
    this.fileInfo = item.data;
    const fileName = Util.getSourceFileNameFromFileInfo(item?.data);
    this.checkAndSaveMarkupById(item.viewId, fileName, checkSave);
    // if (this.checkModeMarkup(item.viewId)) {
    //   const dialog = this.dialogExtendService.closeWarning(item.name);
    //   dialog.afterClosed().subscribe((value) => {
    //     if (value && value === 1) {
    //       setTimeout(() => {
    //         const baseViewId = this.markupsService.fileListsService.getViewId(item.viewId);
    //         this.saveMarkup(baseViewId).then(() => {
    //           if (checkSave) checkSave.next(true);
    //         });
    //       }, SystemConstants.TIME_DELAY_DIALOG_CLOSE);
    //     } else if (checkSave) checkSave.next(false);
    //   });
    // } else if (checkSave) checkSave.next(true);
  }

  async checkAndSaveMarkupById(
    viewId: string,
    name: string,
    checkSave?: Subject<boolean>,
  ) {
    if (this.checkModeMarkup(viewId)) {
      const baseViewId = this.markupsService.fileListsService.getViewId(viewId);
      const exsitFileSource = this.markupsService.fileListsService.getFileInfo(baseViewId);
      const index = this.getIndexFileSelected(exsitFileSource);
      if (index !== null && index !== undefined) {
        this.setActiveView(index, exsitFileSource);
      }
      const dialog = this.dialogExtendService.closeWarning(name);
      dialog.afterClosed().subscribe(async (value) => {
        if (value && value === 1) {
          setTimeout(() => {
            // const baseViewId =
            //   this.markupsService.fileListsService.getViewId(viewId);
            this.saveMarkup(baseViewId).then(() => {
              if (checkSave) checkSave.next(true);
            });
          }, SystemConstants.TIME_DELAY_DIALOG_CLOSE);
        } else if (value && value === 2) {
          // [Jacob_Hao][ADV-7791][05/11/2024] __ Revert the view to the last save and reload markup & close the current file
          if (checkSave) {
            const checkRevert = new Subject<boolean>();
            const obserCheckRevert = checkRevert.asObservable();
            obserCheckRevert.subscribe((v) => {
              if (v) {
                if (index !== null && index !== undefined) {
                  this.markupsService.multiViewService.setActiveView(index, exsitFileSource);
                }
                checkSave.next(true);
              }
            });
            this.markupsService.revertLastSaveMarkup(true, checkRevert, undefined, true);
          }
        } else if (checkSave) checkSave.next(false);
      });
    } else if (checkSave) checkSave.next(true);
    // check push
  }

  addExtModelFileIdInformation(currentMarkup: ResponseMarkups) {
    if (currentMarkup.markupEntities) {
      currentMarkup.markupEntities.forEach((v) => {
        if (v && v.ModelFileId) {
          const file = this.markupsService.fileListsService.getFileInfoFromModelFileId(v.ModelFileId);
          v.extModelFileId = file.filename;
          v.extBaseFileId = file.baseFileId;
          v.extBaseMajorRev = file.baseMajorRev;
          v.extBaseMinorRev = file.baseMinorRev;
        }
      });
    }
    if (currentMarkup.listGroups) {
      currentMarkup.listGroups.forEach((v) => {
        if (v && v.ModelFileId) {
          const file = this.markupsService.fileListsService.getFileInfoFromModelFileId(v.ModelFileId);
          v.extModelFileId = file.filename;
          v.extBaseFileId = file.baseFileId;
          v.extBaseMajorRev = file.baseMajorRev;
          v.extBaseMinorRev = file.baseMinorRev;
        }
      });
    }
    if (currentMarkup.markupViews) {
      currentMarkup.markupViews.forEach((v) => {
        if (v && v.ModelFileId) {
          const file = this.markupsService.fileListsService.getFileInfoFromModelFileId(v.ModelFileId);
          v.extModelFileId = file.filename;
          v.extBaseFileId = file.baseFileId;
          v.extBaseMajorRev = file.baseMajorRev;
          v.extBaseMinorRev = file.baseMinorRev;
        }
      });
    }
    if (currentMarkup.modelViews) {
      currentMarkup.modelViews.forEach((v) => {
        if (v && v.ModelFileId) {
          const file = this.markupsService.fileListsService.getFileInfoFromModelFileId(v.ModelFileId);
          v.extModelFileId = file.filename;
          v.extBaseFileId = file.baseFileId;
          v.extBaseMajorRev = file.baseMajorRev;
          v.extBaseMinorRev = file.baseMinorRev;
        }
      });
    }
  }

  async saveAdept(file: FileInfo[]) {
    function isDiffByJSON(src: any, des: any) {
      try {
        const srcJson = JSON.stringify(src);
        const desJson = JSON.stringify(des);
        return srcJson !== desJson;
      } catch (error) {
        return true;
      }
    }
    const data = file.map((fileItem) => {
      const fileId = UtilExtend.getIdFileIdRev(fileItem);
      const originMarkup = this.markupsService.mapOriginMarkupResponse.get(
        fileId,
      );
      const currentMarkup = this.markupsService.mapMarkupResponse.get(
        fileId,
      );
      currentMarkup.markupViews = this.markupsService.getListMarkupView(
        fileItem.viewId,
      );
      currentMarkup.modelViews = this.markupsService.getListModelView();
      // nghia_td edit ADV-6284
      currentMarkup.listGroups = this.markupsService.listMarkupGroups$.value;
      currentMarkup.markupEntities = this.markupsService.convertAnnotVisible(currentMarkup.markupEntities);
      this.addExtModelFileIdInformation(currentMarkup); // bo sung addModelFileId for detect model
      // this.addViewInformation(currentMarkup); // bo sung thong tin view
      const strMarkupdata = JSON.stringify(currentMarkup);
      // for test loadmarkup that save to adept
      // localStorage.setItem('save - adept', strMarkupdata);

      const isDiff = isDiffByJSON(originMarkup, currentMarkup);
      if (isDiff) {
        this.markupsService.mapOriginMarkupResponse.set(
          fileId,
          currentMarkup,
        );
      }

      const adeptData = {
        markupData: strMarkupdata,
        userId: this.permission.getUser(fileId)?.userDisplayName,
        baseFileId: fileItem.baseFileId,
        baseMajorRev: fileItem.baseMajorRev,
        baseMinorRev: fileItem.baseMinorRev,
        markupIsDirty: isDiff,
        viewId: '',
      };
      try {
        const adeptInfor = this.markupsService.permissionService.getUser(fileId); // get session infor
        adeptInfor.viewItemParameters.markupData = strMarkupdata; // update lai data
      } catch {
        //
      }
      return adeptData;
    });
    Util.logMarkup('saveAdept: ', data);
    // eslint-disable-next-line no-return-await
    return await this.adeptService.saveMarkupData(data);
  }

  saveMarkup(baseViewId: string) {
    const pr = new Promise((resolve, reject) => {
      const checkSave = new Subject<boolean>();
      const obserCheckSave = checkSave.asObservable();
      obserCheckSave.subscribe(() => {
        resolve(true);
      });
      const data = this.markupsService.saveAllMarkups(
        true,
        baseViewId,
        checkSave
      );
    });
    return pr;
  }

  closeItem(
    item: FileInfo,
    index: number,
    checkClose: Subject<boolean> = null,
    isClearCache = false
  ) {
    if (item.isBlank === false) {
      if (this.checkModeMarkup(item.viewId)) {
        this.fileInfo = item;
        const index = this.getIndexFileSelected(this.fileInfo);
        this.setActiveView(index, this.fileInfo);
        const fileName = Util.getSourceFileNameFromFileInfo(item);
        const dialog = this.dialogExtendService.closeWarning(fileName);
        const sub = dialog.afterClosed().subscribe(async (value) => {
          if (value && value === 1) {
            // this.setModeMarkupNochange(item.viewId);
            setTimeout(() => {
              const baseViewId = this.markupsService.fileListsService.getViewId(
                item.viewId
              );
              this.saveMarkup(baseViewId).then(() => {
                this.closeItemEx(item, index, checkClose, false, isClearCache);
              });
            }, SystemConstants.TIME_DELAY_DIALOG_CLOSE);
          } else if (value && value === 2) {
          // [John_Lee][ADV-7791][17/10/2024] __ Revert the view to the last save markup and close the current file
            let undoManager;
            if (item.viewId) {
              undoManager = this.markupsService.getUndoManager(item.viewId);
            }
            await this.markupsService.revertLastSaveMarkup(true, undefined, undoManager, true)
            Util.delay(200);
            this.closeItemEx(item, index, checkClose, false, isClearCache);
          } else if (checkClose) checkClose.next(false);
          sub.unsubscribe();
        });
      } else {
        this.closeItemEx(item, index, checkClose, false, isClearCache);
      }
    } else {
      this.closeItemEx(item, index, checkClose, false, isClearCache);
    }
  }

  closeItemHidden(
    item: FileInfo,
    index: number,
    checkClose: Subject<boolean> = null,
    isFile: boolean = false,
    isClearCache = false
  ) {
    let blankView: FileInfo;
    const listItemsHiding = this.listItemsView.filter((v) => v.isHide);
    if (listItemsHiding && listItemsHiding.length > 0) {
      const viewParentId = UtilExtend.getIdFileIdRev(item);
      const listViewClose = [viewParentId];
      if (viewParentId && !item.viewOnlyModeOriginViewId) {
        listItemsHiding.forEach((fileInfor: FileInfo) => {
          const fileId = UtilExtend.getIdFileIdRev(fileInfor);
          if (
            viewParentId === fileId && !!fileInfor.viewOnlyModeOriginViewId
          ) {
            listViewClose.push(fileId);
          }
        });
      }
      if (listViewClose.length === listItemsHiding.length && !isFile)
        blankView = this.createBlankView();
    }
    this.isDeleteView.next(true);
    this.deleteViewHidden(this.listItemsView, item, index, true, blankView);
    this.markupsService.clearMapViewWithDrawMode(item.viewId);
    this.markupsService.clearListMarkupHide(item.viewId);
    this.markupsService.clearMapListMarkupSelectWithView(UtilExtend.getIdFileIdRev(item));
    this.markupsService.mapViewWithNotifications.set(item.viewId, false);
    if (isClearCache) {
      this.initItems.next(this.listItemsView);
      this.viewerDisplayService.updateListViewDisplay(
        this.listItemsView,
        this.viewerDisplayService.listHideItem
      );
    }
    if (checkClose) checkClose.next(true);
  }

  closeItemEx(
    item: FileInfo,
    index: number,
    checkClose: Subject<boolean> = null,
    isFile: boolean = false,
    isClearCache = false,
    state? : boolean
  ) {
    this.closeFile.next(item);
    let blankView: FileInfo;
    const listItemsShowing = this.listItemsView.filter((v) => !v.isHide);
    if (listItemsShowing && listItemsShowing.length > 0) {
      //  || !item.isBlank
      const viewParentId = UtilExtend.getIdFileIdRev(item); // Defect #5876
      const listViewClose = [viewParentId];
      if (viewParentId && !item.viewOnlyModeOriginViewId) {
        listItemsShowing.forEach((fileInfor: FileInfo) => {
          const fileId = UtilExtend.getIdFileIdRev(fileInfor);
          if (
            viewParentId === fileId &&
            !!fileInfor.viewOnlyModeOriginViewId
          ) {
            listViewClose.push(fileId);
          }
        });
      }
      if (listViewClose.length === listItemsShowing.length && !isFile) {
        blankView = this.createBlankView();
        if (listItemsShowing[0].baseFileId !== item.baseFileId && listItemsShowing[0].converter === 'Zip'
         && listViewClose.length === 1
        ) {
          blankView = null;
        }
      }
    }
    this.isDeleteView.next(true);
    this.deleteView(this.listItemsView, item, index, true, blankView);
    this.markupsService.clearMapViewWithDrawMode(item.viewId);
    this.markupsService.clearListMarkupHide(item.viewId);
    this.markupsService.clearMapListMarkupSelectWithView(UtilExtend.getIdFileIdRev(item));
    this.markupsService.mapViewWithNotifications.set(item.viewId, false);
    if (item.modelFileId) {
      this.listViewerInfo = this.listViewerInfo.filter((v) => v.ModelFileId !== item.modelFileId);
    }
    if (isClearCache) {
      this.initItems.next(this.listItemsView);
      this.viewerDisplayService.updateListViewDisplay(
        this.listItemsView,
        this.viewerDisplayService.listHideItem
      );
    }
    if (checkClose) checkClose.next(true);
  }

  closeItemExt(item: FileInfo, isFile: boolean = false) {
    const checkClose = new Subject<boolean>();
    this.subscribeCheckClose && this.subscribeCheckClose.unsubscribe();
    const obserCheckClose = checkClose.asObservable();
    this.subscribeCheckClose = obserCheckClose.subscribe((v) => {
      if (v) {
        this.markupsService.removeViewMap(item.viewId);
        this.subscribeCheckClose.unsubscribe();
      }
    });
    const listViewDisplay = this.viewerDisplayService.getListViewDisplay();
    const index = listViewDisplay.findIndex(
      (i) =>
        (i.viewIdChildren && i.viewIdChildren.includes(item.viewId)) ||
        i.viewId === item.viewId ||
        UtilExtend.sameFileIdRev(i, item),
    );
    this.adeptService.unlockMarkups([item]);
    if (index >= 0) this.closeItem(item, index, checkClose, true);
  }

  disposeViewer(viewId: string) {
    this.connectAllViewService.disposeViewer(viewId);
  }

  updateStatusPanel(
    viewIdActive: string = null,
    isFrist: boolean = false
  ): boolean {
    const viewId =
      viewIdActive || this.viewActive?.viewId || this.listItemsView[0]?.viewId;
    const currentLayout = this.multiLayoutService.getCurrentLayout();
    const re = this.connectAllViewService.updateStatusPanel(
      viewId,
      currentLayout
    );
    !isFrist && this.connectAllViewService.refreshViewerCanvas();
    return re;
  }

  createBlankView(): FileInfo {
    const uniqueId = Communicator.UUID.create();
    const viewId = `blank-${uniqueId}`;
    const blankView: FileInfo = {
      viewId,
      isBlank: true,
      isHide: false,
      baseFileId: null,
      baseMajorRev: 0,
      baseMinorRev: 0,
      filename: 'No Document Displayed',
    };
    return blankView;
  }

  getAllCopyFile(fileIdRev: FileIdRev) {
    return this.listItemsView.filter((view) => UtilExtend.sameFileIdRev(view, fileIdRev));
  }

  checkMarkupModeFileList(newListViewId: string[]): boolean {
    return newListViewId.some((viewId) => this.checkModeMarkup(viewId));
  }

  private deleteView(
    currentList: FileInfo[],
    item: FileInfo,
    index: number,
    isUpdate: boolean = true,
    blankView: FileInfo = null,
  ) {
    const state = this.isComparisonMode$.value;
    if (blankView) {
      currentList.splice(index, 1, blankView);
    } else {
      currentList.splice(index, 1);
    }
    if(item.isComparisonResult === false) {
      this.deleteViewHiddenMerger(item, currentList);
      if (item && item.viewId && !item.viewId.includes('view-only'))
        this.deleteAllViewOnlyViewWhenClose(item, currentList);
    }
    if (!state) {
      this.deleteViewHiddenMerger(item, currentList);
      if (item && item.viewId && !item.viewId.includes('view-only')) {
        this.deleteAllViewOnlyViewWhenClose(item, currentList);
      }
    }
    isUpdate && this.updateStatusPanel();
    index === this.viewActive.index && this.resetActiveView();
    isUpdate && this.updateListViewDisplay(currentList, [item]);
    this.setViewActive(this.viewActive);
    this.disposeViewer(item.viewId);
    this.stateMultiViewer.deleteViewerState(item.viewId);
    this.multiLayoutService.refreshLayout();
  }

  private deleteViewHidden(
    currentList: FileInfo[],
    item: FileInfo,
    index: number,
    isUpdate: boolean = true,
    blankView: FileInfo = null
  ) {
    if (blankView) {
      currentList.splice(index, 1, blankView);
    } else {
      currentList.splice(index, 1);
    }
    this.deleteViewHiddenMerger(item, currentList);
    if (item && item.viewId && !item.viewId.includes('view-only'))
      this.deleteAllViewOnlyViewWhenClose(item, currentList);
    index === this.viewActive.index && this.resetActiveView();
    isUpdate && this.updateListViewDisplay(currentList, [item]);
    this.disposeViewer(item.viewId);
    this.stateMultiViewer.deleteViewerState(item.viewId);
  }

  private deleteViewHiddenMerger(item: FileInfo, currentList: FileInfo[]) {
    if (item.viewIdParent && !item.viewOnlyModeOriginViewId) {
      const viewIdChild = this.mapMergeParentChild.get(item.viewIdParent);
      if (viewIdChild) {
        const lengthList = currentList.length;
        for (let i = 0; i < lengthList; i++) {
          if (currentList[i]) {
            if (viewIdChild.includes(currentList[i].viewId)) {
              this.disposeViewer(currentList[i].viewId);
              currentList[i].isHide = false;
              currentList.splice(i, 1);
              i--;
            }
          }
        }
      }
    }
    let blankView: FileInfo;
    if (currentList.length === 0) {
      blankView = this.createBlankView();
      currentList.push(blankView);
    }
  }

  private deleteAllViewOnlyViewWhenClose(item: FileInfo, list: FileInfo[]) {
    // get all item co cung parent
    const func = (itemInput: FileInfo) => {
      if (UtilExtend.sameFileIdRev(itemInput, item)) {
        // cung parent
        const viewIdOrigin = itemInput.viewOnlyModeOriginViewId;
        return !!viewIdOrigin; // nhung viewonly có cung parent voi file vua dc dong
      }
      return false;
      // const viewIdOrigin = itemInput.viewOnlyModeOriginViewId;
      // return !!viewIdOrigin && viewIdOrigin === item.viewId;
    };
    UtilExtend.deleteItemInListWithCondition(list, func);
  }

  private deleteViewExtend(oldList: FileInfo[], index: number) {
    const { viewId } = oldList[index];
    oldList.splice(index, 1);
    this.disposeViewer(viewId);
    this.stateMultiViewer.deleteViewerState(viewId);
  }

  private setNewList(
    oldList: FileInfo[],
    newList: FileInfo[],
    lengthOldList: number,
    mapViewIdIndex: Map<string, number>
  ) {
    // remove item no exist
    for (let i = 0; i < lengthOldList; i++) {
      if (oldList[i]) {
        if (!mapViewIdIndex.has(oldList[i].viewId)) {
          // oldList.splice(i, 1);
          this.deleteViewExtend(oldList, i);
          i--;
        }
      }
    }
    // push new item to old list
    newList.forEach((f) => {
      if (!mapViewIdIndex.has(f.viewId)) {
        oldList.push(f);
        this.stateMultiViewer.setViewerState(f.viewId);
      }
    });
    // set old index
    mapViewIdIndex.forEach((index, viewId) => {
      const currentIndex = oldList.findIndex((f) => f.viewId === viewId);
      moveItemInArray(oldList, currentIndex, index);
    });
    this.updateListViewDisplay(oldList);
    if (!oldList.map((v) => v.viewId).includes(this.viewActive.viewId)) {
      this.resetActiveView();
    }
  }

  private comparedFileList(
    oldList: FileInfo[],
    newList: FileInfo[],
    arrViewIdNewList: string[],
    lengthOldList: number
  ) {
    const tempCompared = oldList.some(
      (f) => !arrViewIdNewList.includes(f.viewId)
    );
    if (lengthOldList === newList.length && !tempCompared) {
      return true;
    }
    return false;
  }

  setListView(newList: FileInfo[]): boolean {
    const arrViewIdNewList = newList.map((v) => v.viewId);
    const arrViewIdNewListMerger = newList.reduce((prev, curr) => {
      if (curr.viewIdChildren) {
        prev = [...curr.viewIdChildren, ...prev];
      } else {
        prev.push(curr.viewId);
      }
      return prev;
    }, []);
    const oldList = this.listItemsView;
    const lengthOldList = oldList.length;

    if (
      this.comparedFileList(oldList, newList, arrViewIdNewList, lengthOldList)
    ) {
      return true;
    }
    const mapViewIdRemove = [];
    const mapViewIdIndex = new Map<string, number>();

    // get map index item exist
    oldList.forEach((file, index) => {
      if (arrViewIdNewListMerger.includes(file.viewId) || file.isHide) {
        mapViewIdIndex.set(file.viewId, index);
      } else {
        mapViewIdRemove.push(file.viewId);
      }
    });

    // check markup mode
    if (this.checkMarkupModeFileList(mapViewIdRemove)) {
      const translations = this.languageManagerService.getCurentTranslations();
      const msg = translations.DIALOG.MARKUP_MODEFILE_LIST.MESSAGE;
      const dialog = this.dialogExtendService.openDialogConfirm(msg);
      dialog
        .afterClosed()
        .pipe(take(1))
        .subscribe((v) => {
          if (v) {
            this.setNewList(oldList, newList, lengthOldList, mapViewIdIndex);
            return true;
          }
          return false;
        });
    } else {
      this.setNewList(oldList, newList, lengthOldList, mapViewIdIndex);
    }
    return true;
  }

  setListViewByHidden(newList: FileInfo[]) {
    this.deleteAllViewOnlyView();
    this.deleteAllBlankView();
    const newListId = newList.map((v) => v.viewId);
    const mapCurrentId = this.listItemsView.map((v) => v.viewId);
    this.listItemsView.forEach((v) => {
      const currViewId = v.viewId; // v.viewIdParent ?? v.viewId;
      if (newListId.includes(currViewId)) {
        if (v.viewIdChildren) {
          const listFileInfoMerger = this.listItemsView.filter((f) =>
            v.viewIdChildren.includes(f.viewId)
          );
          if (listFileInfoMerger.length > 0) {
            const isNotHide = listFileInfoMerger.some((f) => !f.isHide);
            if (!isNotHide) {
              v.isHide = false;
            }
          }
        } else {
          v.isHide = false;
        }
      } else {
        v.isHide = true;
      }
    });
    newList.forEach((newItem) => {
      if (!mapCurrentId.includes(newItem.viewId)) {
        newItem.isHide = false;
        this.listItemsView.push(newItem);
      }
    });
    // check xem ViewActive đa bi xoa di chua (No Document Displayed dang la active)
    const checkActiveView = this.listItemsView.some(
      (v) => v.viewId === this.viewActive.viewId
    );

    if (!checkActiveView || this.viewActive.data.isHide) {
      let indexFirstItemShow;
      let fileInfoFirstItemShow;
      this.listItemsView.some((item, i) => {
        if (!item.isHide) {
          indexFirstItemShow = i;
          fileInfoFirstItemShow = item;
          return true;
        }
        return false;
      });
      fileInfoFirstItemShow &&
        this.setActiveView(indexFirstItemShow, fileInfoFirstItemShow);
    }
    const listHide = this.listItemsView.filter((v) => v.isHide);
    this.updateListViewDisplay(this.listItemsView, listHide);
    this.connectAllViewService.refreshViewerCanvas();
    return true;
  }

  deleteAllBlankView() {
    UtilExtend.deleteItemInListWithCondition(
      this.listItemsView,
      (item) => item.isBlank
    );
  }

  deleteAllViewOnlyView() {
    const func = (item: FileInfo) => !!item.viewOnlyModeOriginViewId;
    UtilExtend.deleteItemInListWithCondition(this.listItemsView, func);
  }

  onDestroyService(): void {
    UtilExtend.Log(LogType.Destroy, 'multi view', LogTypeClass.Service);
    this.subs && this.subs.unsubscribe();
    this.connectAllViewService.onDestroyService();
  }

  /**
   * load subfile to viewer
   * @param fileTarget file exist cached
   * @param listFileMerger
   */
  loadFileMerged(fileTarget: FileInfo, listFileMerger: FileInfo[], isChangeActive: boolean = true) {
    const mapIdListFileMerger = listFileMerger.map((m) => m.viewId);
    const target = this.getFirstItemFileListWithCondition(
      (f) => f.viewId === fileTarget.viewId,
    );
    const source = this.getFirstItemFileListWithCondition(
      (f) => mapIdListFileMerger.includes(f.viewId) && f.isHide === false,
    );
    let activeIndex = -1;
    let activeItem: FileInfo = null;
    if (!target) {
      if (source) {
        source.item.isHide = true;
        this.listItemsView.splice(source.index, 0, fileTarget);
        activeIndex = source.index;
        activeItem = fileTarget;
      }
    } else if (source) {
      source.item.isHide = true;
      target.item.isHide = false;
      moveItemInArray(this.listItemsView, target.index, source.index);
      PdfViewerManager.redrawPdf(target.item.viewId, true);
      this.updateListViewDisplay();
      this.connectAllViewService.connect3DViewerService.eventResize$.emit();
      activeIndex = source.index;
      activeItem = target.item;
    }
    if (isChangeActive) {
      if (activeIndex >= 0 && activeItem) {
        // đoi item nay load xong thi moi active
        const obs = this.connectAllViewService.mapModelLoadSuccess.get(
          activeItem.viewId,
        );
        if (obs) {
          // file này da dc load roi, xem ham 'setModelInfo in 3dviewer.component'
          activeItem.isHide = false; // hien lai file da bi an truoc khi active
          this.setActiveView(activeIndex, activeItem);
        } else {
          this.connectAllViewService.eventModelLoadSuccess$
            .pipe(take(1))
            .subscribe((f) => {
              if (f.viewId === activeItem.viewId) {
                this.setActiveView(activeIndex, activeItem);
              }
            });
        }
      }
    }
  }

  activeViewByBaseFileId(
    fileInfo: FileIdRev,
    indexPre: number,
    fileInfoNew: FileInfo,
  ) {
    const reItem = this.getFirstItemFileListWithCondition(
      (f) => UtilExtend.sameFileIdRev(f, fileInfo) && !f.isHide,
    );
    if (reItem) {
      this.setActiveView(reItem.index, reItem.item);
    } else {
      this.replaceFileByHidden(indexPre, fileInfoNew);
    }
  }

  /**
   * this.listItemsView
   */
  getFirstItemFileListWithCondition(
    func: (item: FileInfo, index?: number) => boolean
  ) {
    return UtilExtend.findFirstItemWithCondition(this.listItemsView, func);
  }

  createViewOnly(
    originFileInfo: FileInfo,
    isDrag: boolean,
    viewIdTarget: string = '',
    firstBlankView: { item: FileInfo; index: number } = null,
    indexTarget?: number
  ) {
    const newViewId = Communicator.UUID.create();
    const viewOnlyFileInfo: FileInfo = {
      ...originFileInfo,
      viewId: `view-only-${newViewId}`,
      viewOnlyModeOriginViewId: originFileInfo.viewId,
    };
    const originTargetIndex = this.listItemsView.findIndex(
      (f) => f.viewId === originFileInfo.viewId
    );
    if (!isDrag) {
      if (firstBlankView) {
        this.listItemsView.splice(firstBlankView.index, 1, viewOnlyFileInfo);
      } else {
        this.listItemsView.splice(originTargetIndex + 1, 0, viewOnlyFileInfo);
      }
    } else {
      const fileBlank = this.getFirstItemFileListWithCondition(
        (item) => item.isBlank && !item.isHide
      );
      if (fileBlank) {
        if (indexTarget) fileBlank.index = indexTarget;
        this.listItemsView.splice(fileBlank.index, 1, viewOnlyFileInfo);
      } else {
        const data = this.getFirstItemFileListWithCondition(
          (item) => item.viewId === viewIdTarget
        );
        // let { index: indexFileTarget, item: fileTarget } = data;
        let indexFileTarget = indexTarget;
        let fileTarget = null;
        if (data) {
          indexFileTarget = data.index;
          fileTarget = data.item;
          fileTarget.isHide = true;
        }
        if (this.viewActive.viewId === fileTarget?.viewId) {
          this.setActiveView(originTargetIndex, originFileInfo);
        }
        this.listItemsView.splice(indexFileTarget, 0, viewOnlyFileInfo);
      }
    }
    this.updateListViewDisplay(this.listItemsView);
    this.connectAllViewService.refreshViewerCanvas();
    return viewOnlyFileInfo;
  }

  moveItemToIndex(
    item: FileInfo,
    indexTarget: number,
    hideItem: FileInfo,
    list: FileInfo[] = this.listItemsView
  ) {
    const itemIndex = list.findIndex((f) => f.viewId === item.viewId);
    moveItemInArray(list, itemIndex, indexTarget);
    // Neu la file ViewOnly thi xoa khoi listItemsView
    if (hideItem?.viewOnlyModeOriginViewId) {
      const itemViewOnlyIndex = list.findIndex(
        (f) => f.viewId === hideItem.viewId && hideItem.viewOnlyModeOriginViewId
      );
      this.listItemsView.splice(itemViewOnlyIndex, 1);
    }
    const arr = hideItem ? [hideItem] : [];
    this.updateListViewDisplay(list, arr);
    this.connectAllViewService.refreshViewerCanvas();
    this.setActiveView(indexTarget, item);
  }

  getListItemViewOnly(list: FileInfo[]): FileInfo[] | null {
    const mapTempListId = list.map((m) => m.viewId);
    const re = this.listItemsView.filter((f) =>
      mapTempListId.includes(f.viewOnlyModeOriginViewId)
    );
    return re.length > 0 ? re : null;
  }

  createRootViewerCombine(fileInfo: FileInfo) {
    let re: boolean;
    const indexFileTarget = this.listItemsView.findIndex(
      (f) => f.viewId === fileInfo.viewId
    );
    if (indexFileTarget !== -1) {
      const indexViewActive = this.listItemsView.findIndex(
        (f) => f.viewId === this.viewActive.viewId
      );
      moveItemInArray(this.listItemsView, indexFileTarget, indexViewActive);
      re = true;
    } else {
      this.listItemsView.push(fileInfo);
      re = false;
    }
    this.changeLayoutToFull(fileInfo);
    const indexFileTargetLast = this.listItemsView.findIndex(
      (f) => f.viewId === fileInfo.viewId
    );
    this.setActiveView(indexFileTargetLast, fileInfo);
    this.updateListViewDisplay(this.listItemsView);
    this.connectAllViewService.refreshViewerCanvas();
    return re;
  }

  changeLayoutToFull(fileInfo: FileInfo) {
    this.listItemsView.forEach((f) => {
      f.isHide = true;
    });
    fileInfo.isHide = false;
    this.multiLayoutService.setCurrentLayout(TypeLayout.Full);
  }

  activeFile(fileId: string): boolean {
    const selectedFile = this.listViewShow.filter(
      (f) => !f.viewOnlyModeOriginViewId && f.viewId === fileId
    );
    if (this.listViewShow.map((f) => f.viewId).includes(fileId)) {
      if (this.viewActive.viewId !== fileId) {
        // eslint-disable-next-line prefer-const
        let { item, index } = this.getFirstItemFileListWithCondition(
          (f) => f.viewId === fileId
        );
        if (item.viewOnlyModeOriginViewId) {
          // fix bug active viewonly will switch to rootfile
          const func = (itemInput: FileInfo) =>
            itemInput.viewId === item.viewOnlyModeOriginViewId;
          const temp1 = this.getFirstItemFileListWithCondition(func);
          if (temp1) {
            item = temp1.item;
            index = temp1.index;
          }
        }
        this.setActiveView(index, item);
      }
      return true;
    }
    if (selectedFile && selectedFile.length > 0) {
      // check click new view in Content Panel then click active file in Document Panel
      const { item, index } = this.getFirstItemFileListWithCondition(
        (f) => f.viewId === selectedFile[0].viewId
      );
      this.setActiveView(index, item);
      return true;
    }
    return false;
  }

  /**
   * check uncheck to open or hide file if file exist in multiview or exist No Document Displayed
   * @param fileInfo fileinfo checked (unchecked)
   */
  checkAndUnCheckFile(fileInfo: FileInfo) {
    let fileInfoTemp = fileInfo;
    let viewFinded = null;
    if (fileInfoTemp.viewOnlyModeOriginViewId) {
      const func = (itemInput: FileInfo) =>
        itemInput.viewId === fileInfoTemp.viewOnlyModeOriginViewId;
      viewFinded = this.getFirstItemFileListWithCondition(func);
      fileInfoTemp = viewFinded.item;
    } else
      viewFinded = this.getFirstItemFileListWithCondition(
        (f) => f.viewId === fileInfoTemp.viewId
      );

    if (viewFinded) {
      // neu da ton tai file ben view
      // if (this.listViewShow.map((f) => FilesStatic.getViewId(f.viewId)).includes(fileTemp.viewId)) {
      if (
        this.listViewShow.map((f) => f.viewId).includes(fileInfoTemp.viewId)
      ) {
        // eslint-disable-next-line max-len
        const listViewerOnly = this.listItemsView.filter(
          (f) =>
            f.viewOnlyModeOriginViewId &&
            f.viewOnlyModeOriginViewId === fileInfoTemp.viewId &&
            !f.isHide
        );
        if (listViewerOnly && listViewerOnly.length > 0) {
          // Bug #4299
          listViewerOnly.forEach((itemViewOnly) => {
            const ind = this.listItemsView.findIndex(
              (f) => f.viewId === itemViewOnly.viewId
            );
            this.replaceViewByBlank(itemViewOnly, ind, null, false);
          });
        }
        const { item, index } = viewFinded;
        this.replaceViewByBlank(item, index, null, true);
      } else {
        const firstViewBlank = UtilExtend.findFirstItemWithCondition(
          this.listViewShow,
          (f) => f.isBlank
        );
        if (firstViewBlank) {
          const { item } = viewFinded;
          this.replaceBlankByView(
            item,
            firstViewBlank.index,
            firstViewBlank.item
          );
        }
      }
    } else {
      // const firstViewBlankElse = UtilExtend.findFirstItemWithCondition(this.listViewShow, (f) => f.isBlank);
      // if (firstViewBlankElse) {
      //   const { item: itemBlank, index: indexBlank } = firstViewBlankElse;
      //   firstViewBlankElse.item.isHide = true; // #4500
      //   this.listItemsView.splice(indexBlank, 1, fileInfoTemp);
      //   this.updateListViewDisplay();
      //   if (itemBlank.viewId === this.viewActive.viewId) {
      //     this.setActiveView(indexBlank, fileInfoTemp);
      //   }
      // }
      const firstViewBlank = UtilExtend.findFirstItemWithCondition(
        this.listViewShow,
        (f) => f.isBlank
      );
      if (firstViewBlank) {
        // #4500
        this.listItemsView.push(fileInfoTemp);
        this.replaceBlankByView(
          fileInfoTemp,
          firstViewBlank.index,
          firstViewBlank.item
        );
      }
    }
  }

  private replaceViewByBlank(
    file: FileInfo,
    index: number,
    itemHide: FileInfo = null,
    isUpdate: boolean = true
  ) {
    const firstViewBlankHide = UtilExtend.findFirstItemWithCondition(
      this.listViewShow,
      (f) => f.isBlank && f.isHide
    );
    if (!firstViewBlankHide) {
      const blankInfo = this.createBlankView();
      this.listItemsView.splice(index, 0, blankInfo);
      // moveItemInArray(this.listItemsView, index + 1, this.listItemsView.length - 1);
    } else {
      moveItemInArray(this.listItemsView, firstViewBlankHide.index, index);
    }
    file.isHide = true;
    if (isUpdate) this.updateListViewDisplay(this.listItemsView, [itemHide]);
    if (file.viewId === this.viewActive.viewId) {
      const { item } = this.getFirstItemFileListWithCondition(
        (f, i) => i === index
      );
      this.setActiveView(index, item);
    }
    this.connectAllViewService.refreshViewerCanvas();
  }

  private replaceBlankByView(
    file: FileInfo,
    indexBlank: number,
    itemBlank: FileInfo
  ) {
    itemBlank.isHide = true;
    const { index, item } = this.getFirstItemFileListWithCondition(
      (f) => f.viewId === file.viewId
    );
    item.isHide = false;
    moveItemInArray(this.listItemsView, index, indexBlank);
    // moveItemInArray(this.listItemsView, indexBlank + 1, this.listItemsView.length - 1);
    this.updateListViewDisplay();
    if (itemBlank.viewId === this.viewActive.viewId) {
      this.setActiveView(indexBlank, file);
    }
    this.connectAllViewService.refreshViewerCanvas();
  }

  public replaceViewByView(
    file: FileInfo,
    indexOldView: number,
    itemOldView: FileInfo,
    checkReplace: Subject<boolean> = null
  ) {
    const checkSave = new Subject<boolean>();
    const obserCheckSave = checkSave.asObservable();
    obserCheckSave.subscribe((v) => {
      if (v) {
        itemOldView.isHide = true;
        const viewFinder = this.getFirstItemFileListWithCondition(
          (f) => f.viewId === file.viewId
        );
        if (viewFinder) {
          const { index, item } = viewFinder;
          item.isHide = false;
          moveItemInArray(this.listItemsView, index, indexOldView);
          this.setActiveView(indexOldView, file);
        } else {
          const firstViewBlankHide = UtilExtend.findFirstItemWithCondition(
            this.listViewShow,
            (f) => f.isBlank && f.isHide
          );
          if (!firstViewBlankHide) {
            this.listItemsView.push(file);
            moveItemInArray(
              this.listItemsView,
              this.listItemsView.length - 1,
              indexOldView
            );
            this.setActiveView(indexOldView, file);
          } else {
            moveItemInArray(
              this.listItemsView,
              firstViewBlankHide.index,
              indexOldView
            );
            this.setActiveView(indexOldView, file);
          }
        }
      }
      if (checkReplace) checkReplace.next(v);
    });
    if (itemOldView.viewId) {
      this.checkAndSaveMarkupById(
        itemOldView.viewId,
        Util.getSourceFileNameFromFileInfo(itemOldView),
        checkSave,
      );
    }
  }

  public get listViewShow() {
    return this.listItemsView.filter((f) => !f.isHide);
  }

  // check drag
  canDragEnter(fileIdSource: string, fileIdTarget: string): boolean {
    const firstViewTarget = UtilExtend.findFirstItemWithCondition(
      this.listViewShow,
      (f) => f.viewId === fileIdTarget
    );
    if (firstViewTarget && !firstViewTarget.item.isBlank) {
      const firstViewSourceExist = UtilExtend.findFirstItemWithCondition(
        this.listViewShow,
        (f) => f.viewId === fileIdSource
      );
      if (firstViewSourceExist) {
        return false;
      }
    }
    return true;
  }

  public setLayout(dataFileSelected: FileInfo[], type: TypeSelectLayoutMenu) {
    this.isSelectFull = type;
    if (type === TypeLayout.Full) {
      this.setCurViewClick(null);
    }

    if (type !== TypeLayout.ViewOnly) {
      const listRemoveFile = [];
      let listFileInfoNew = [];
      const listNormalView = [];
      const listFileViewOnly = [];
      this.listItemsView.forEach((item) => {
        if (!item.isBlank && !item.isHide) {
          if (!item.viewId.includes('view-only')) {
            listNormalView.push(item); // danh sach view binh thuong
          } else {
            listFileViewOnly.push(item); // danh sach view-viewonly
          }
        }
      });
      if (listFileViewOnly) {
        listFileInfoNew = [...listNormalView, ...listFileViewOnly];
      } else listFileInfoNew = [...listNormalView];
      // them file duoc chon vao danh sach hien thi
      dataFileSelected && dataFileSelected.forEach((file) => {
        const temp = listFileInfoNew.find((f) => f.baseFileId === file.baseFileId);
        if (!temp) {
          listFileInfoNew.push(file);
        }
      });
      let fileActive = this.viewActive?.data;
      // vi tri hien tai cua file active
      const index = listFileInfoNew.findIndex(
        (v) => (v.viewId === fileActive?.viewId
                || (fileActive.viewIdParent && v?.viewIdParent === fileActive.viewIdParent))
                && !fileActive.isHide,
      );
      // thay fileActive neu fileActive la viewOnly
      if (this.viewActive.index !== index) {
        fileActive = listFileInfoNew[index];
      }
      const lengthFileSelected = listFileInfoNew.length;
      const numberTypeLayoutNew = this.multiLayoutService.getNumberOfLayout(type);
      const temp = numberTypeLayoutNew - lengthFileSelected;
      if (temp < 0) {
        // lay ds cac file se bi remove
        for (let i = numberTypeLayoutNew; i < listFileInfoNew.length; i++) {
          const file = listFileInfoNew[i];
          if (file.viewIdParent && fileActive.viewIdParent) {
            if (!file.isBlank && file.viewIdParent !== fileActive.viewIdParent) {
              listRemoveFile.push(file);
            }
          } else if (!file.isBlank && file.viewId !== fileActive.viewId) {
            listRemoveFile.push(file);
          }
        }
      } else if (temp > 0) {
        const fileBlankAdd = [];
        for (let i = 0; i < temp; i++) {
          const tempBlank = this.createBlankView();
          fileBlankAdd.push(tempBlank);
        }
        listFileInfoNew = [...listFileInfoNew, ...fileBlankAdd];
      }
      if (fileActive && !fileActive.isBlank && !fileActive.viewId.includes('view-only')) {
        // #5425: move ActiveFile tới vị trí cuối cùng nếu không còn trong list
        if (index >= numberTypeLayoutNew) {
          listRemoveFile.push(listFileInfoNew[numberTypeLayoutNew - 1]);
          listFileInfoNew.splice(numberTypeLayoutNew - 1, 1, fileActive);
        }
      }
      if (listRemoveFile && listRemoveFile.length > 0) {
        // kiem tra nhung file dong nay co markup thay đổi khong?
        const baseViewIds = listRemoveFile.map(
          (file: FileInfo) => file.viewIdParent ?? file.viewId,
        );
        this.markupsService
          .RequestCloseRightPanel(null, baseViewIds)
          .then((v) => {
            if (v) {
              listFileInfoNew.length = numberTypeLayoutNew;
              this.multiLayoutService.setCurrentLayout(type);
              this.setListViewByHidden(listFileInfoNew);
            }
          });
      } else if (this.viewActive.viewId.includes('view-only') && type === TypeLayout.Full) {
        let ViewNormalFirst = null;
        for (let i = 0; i < this.listItemsView.length; i += 1) {
          const file = this.listItemsView[i];
          if (!file.viewId.includes('view-only') && !file.viewId.includes('blank') && !file.isHide) {
            ViewNormalFirst = {
              fileInfo: file,
              index: i,
            };
            break;
          }
        }
        // block
        this.listItemsView.splice(0, this.listItemsView.length, ViewNormalFirst.fileInfo);
        this.multiLayoutService.setCurrentLayout(type);
        // this.setActiveView(0, ViewNormalFirst.fileInfo);
        this.setListViewByHidden(this.listItemsView);
      } else {
        listFileInfoNew.length = numberTypeLayoutNew;
        this.multiLayoutService.setCurrentLayout(type);
        this.setListViewByHidden(listFileInfoNew);
      }
    } else if (!this.viewActive.data.isInZipFile) {
      const currentTypeItemActive = this.multiLayoutService.getNumberLayout();
      this.setLayoutViewOnlyMode(currentTypeItemActive);
    }
  }

  private setLayoutViewOnlyMode(currentTypeItemActive, indexTarget?: number) {
    if (typeof currentTypeItemActive === 'number') {
      const fileBlank = this.getFirstItemFileListWithCondition(
        (item) => item.isBlank && !item.isHide
      );
      const viewOnlyOrigin = this.viewActive.data;
      const file = this.listItemsView.find((f) => f.viewId === this.viewActive.data.viewOnlyModeOriginViewId);
      this.listItemsView.find((f) => this.viewActive.data.viewIdParent === f.viewId);
      if (currentTypeItemActive < 4) {
        if (!fileBlank) {
          const temp = currentTypeItemActive + 1;
          const [newTypeLayout, ...temp1] =
            this.multiLayoutService.mapTypeLayout.get(temp);
          this.multiLayoutService.setCurrentLayout(newTypeLayout);
        }
        const file = this.createViewOnly(
          viewOnlyOrigin,
          false,
          null,
          fileBlank,
          indexTarget
        );
        file.isHide = false;
      } else if (currentTypeItemActive === 4) {
        if (fileBlank) {
          this.createViewOnly(
            viewOnlyOrigin,
            false,
            null,
            fileBlank,
            indexTarget
          );
        }
      }
    }
  }

  switchViewOnly(fileInfo: FileInfo) {
    const fileBlank = this.getFirstItemFileListWithCondition(
      (item) => item.isBlank && !item.isHide
    );
    let fileSwitch: FileInfo = null;
    let curView = this.listItemsView.find(
      (view) => view.viewId === this.curViewClick?.fileInfo?.viewId
    );
    if (!curView) {
      curView = this.viewActive.data;
    }
    if (curView && curView.modelFileId !== fileInfo.modelFileId) {
      curView.isHide = true;
      const index = this.listItemsView.findIndex(
        (view) => view.viewId === this.curViewClick?.fileInfo?.viewId
      );

      if (this.curViewClick?.fileInfo?.viewId.includes('view-only')) {
        // fileSwitch = this.listItemsView.find((view, i) => view.baseFileId === fileInfo.baseFileId
        // && view.modelFileId === fileInfo.modelFileId
        // && view.viewId.includes('view-only'));
        // if (!fileSwitch)
        fileSwitch = this.createViewOnly(fileInfo, false, null, fileBlank);
        fileSwitch.isHide = false;
        this.setCurViewClick({
          fileInfo : fileSwitch , 
          index: this.curViewClick.index
        });
        this.moveItemToIndex(fileSwitch, index, curView);
        // bo xung them view blank vao vi tri trong
        const layout = this.multiLayoutService.getCurrentLayout();
        let viewNumber = 1;
        switch (layout) {
          case TypeLayout.OneAndOne:
          case TypeLayout.OneOnOne:
            viewNumber = 2;
            break;
          case TypeLayout.OneAndTwo:
          case TypeLayout.TwoAndOne:
            viewNumber = 3;
            break;
          case TypeLayout.TwoAndTwo:
            viewNumber = 4;
            break;
          default:
            break;
        }
        const listItemShow = this.listItemsView.filter((item) => !item.isHide);
        const length = listItemShow?.length;
        if (length < viewNumber) {
          for (let i = 0; i < viewNumber - length; i += 1) {
            const blankView = this.createBlankView();
            this.listItemsView.push(blankView);
          }
        }
        this.connectAllViewService.refreshViewerCanvas();
      }
    }
  }

  asyncActiveSheetViewOnly(curView: FileInfo, fileInfoNew: FileInfo) {
    const fileBlank = this.getFirstItemFileListWithCondition(
      (item) => item.isBlank && !item.isHide
    );
    let fileSwitch: FileInfo = null;
    const indexs = [];
    this.listItemsView.forEach((item, index) => {
      if (
        UtilExtend.sameFileIdRev(item, curView) && item.viewOnlyModeOriginViewId === curView.viewId
      ) {
        item.isHide = true;
        indexs.push(index);
      }
    });
    if (indexs.length > 0) {
      // fileSwitch = this.listItemsView.find((view, i) => view.baseFileId === fileInfo.baseFileId
      // && view.modelFileId === fileInfo.modelFileId
      // && view.viewId.includes('view-only'));
      // if (!fileSwitch)
      indexs.forEach((i) => {
        const temp = this.listItemsView[i];
        fileSwitch = this.createViewOnly(fileInfoNew, false, null, fileBlank);
        fileSwitch.isHide = false;
        this.moveItemToIndex(fileSwitch, i, temp);
      });
      this.connectAllViewService.refreshViewerCanvas();
    }
  }

  getModelInfo(viewId?: string) {
    if (viewId) {
      return this.connect3dViewerService?.getModelInfo(viewId);
    }
    return this.connect3dViewerService?.getModelInfo(this.viewActive.viewId);
  }

  getListItemsView() {
    return this.viewerDisplayService.getListViewDisplay();
  }

  findFileReplace(file: FileInfo) {
    let indexItem = null;
    let item = null;
    for (let i = 0; i < this.listItemsView.length; i += 1) {
      const temp = this.listItemsView[i];
      if (
        temp.viewOnlyModeOriginViewId === file.viewId &&
        temp.isHide === false
      ) {
        indexItem = i;
        item = temp;
        break;
      }
    }
    return { item, indexItem };
  }

  getViewerInfoFromModelFileId(modelFileId: string) {
    return this.listViewerInfo.find((v) => v.ModelFileId === modelFileId);
  }

  updateViewStateHoop(parentViewId: string, viewId: string, sheetId: number, camera: Communicator.Camera) {
    const vs: ViewState = {
      viewId: `${viewId}`,
      sheetId,
      camera,
    };
    if (parentViewId) {
      let subBehavior = this.mapViewState$.get(parentViewId);
      if (!subBehavior) {
        subBehavior = new BehaviorSubject<ViewState>(null);
        this.mapViewState$.set(parentViewId, subBehavior);
      }
      UtilExtend.CheckAndUpdateViewState(subBehavior, vs);
    }
  }

  public setLayoutFull() {
    if (this.isSelectFull === TypeLayout.Full) {
      this.setLayout([], TypeLayout.Full);
    }
  }

  updateViewStatePdf(parentViewId: string, vs: ViewState) {
    if (parentViewId) {
      let subBehavior = this.mapViewState$.get(parentViewId);
      if (!subBehavior) {
        subBehavior = new BehaviorSubject<ViewState>(null);
        this.mapViewState$.set(parentViewId, subBehavior);
      }
      UtilExtend.CheckAndUpdateViewState(subBehavior, vs);
    }
  }

  openFile(file: FileInfo, isCompartsonResult: boolean = false) {
    const firstViewBlank = UtilExtend.findFirstItemWithCondition(
      this.listItemsView,
      (f) => f.isBlank,
    );
    file.isComparisonResult = isCompartsonResult;
    if (firstViewBlank) {
      // #4500
      this.listItemsView.push(file);
      this.replaceBlankByView(
        file,
        firstViewBlank.index,
        firstViewBlank.item,
      );
    }
  }

  notified(msg: NotifiedData) {
    this.dialogExtendService.notified(msg);
  }

  closeCurrentDialog() {
    this.dialogExtendService.closeCurrentDialog();
  }

  dialogNotify(msg, timeShow = 5, status: StatusNotify = StatusNotify.warning) {
    this.dialogExtendService.notified(
      {
        message: msg,
        status,
      },
      timeShow,
    );
  }

  getCurrentLayout() {
    return this.multiLayoutService.getCurrentLayout();
  }

  async checkModelLoad(id?: string, isShowMessage: boolean = true) {
    const formatViewer = UtilExtend.getFormatViewer(this.viewActive.data);
    if (formatViewer === FormatViewer.Pdf) return true;
    const viewId = id || this.viewActive.viewId;
    const modelInfo = this.connect3dViewerService.getModelInfo(viewId);
    if (!modelInfo) {
      return false;
    }
    const connection = this.adeptService.checkConnection();
    const { streamLocation, filename } = this.viewActive.data;
    const filenameRemoveExt = Util.removeExt(filename);
    const checkFileCacheRequest = {
      scs: {
        path: `${streamLocation}/${filenameRemoveExt}.scs`,
      },
      scz: {
        path: `${streamLocation}/${filenameRemoveExt}.scz`,
      },
    };
    const result = await this.conversionService.checkFileCacheExist(checkFileCacheRequest).toPromise();
    let cacheFile = false;
    if (result) {
      const { scs, scz } = result;
      switch (SystemConstants.RENDER) {
        case SystemConstants.RENDER_TYPE.scs:
          if (scs) cacheFile = scs.isExist;
          break;
        case SystemConstants.RENDER_TYPE.csr:
        case SystemConstants.RENDER_TYPE.ssr:
          if (scz) cacheFile = scz.isExist;
          break;
        default:
          break;
      }
    }
    const resutl = !modelInfo.modelLoadFailure && connection && cacheFile;
    if (!resutl && isShowMessage) {
      const { fileListsService } = this.markupsService;
      let dataListFile = null;
      const fileListSubscription = fileListsService.fileList$.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
        if (value.length > 0) {
          dataListFile = this.setData(value);
        }
      });
      let listFiletemp = null;
      if (dataListFile && dataListFile.length === 1) {
        listFiletemp = dataListFile;
      } else {
        listFiletemp = dataListFile && dataListFile.filter((item) => {
          if (this.viewActive.data && this.viewActive.data.viewIdParent) {
            // eslint-disable-next-line no-unused-expressions
            return this.viewActive.data.viewIdParent === item.viewId;
          }
          return this.viewActive.viewId === item.viewId;
        });
      }
      const dialog = await this.markupsService.dialogExtendService.showDialogCloseFileMissCache(modelInfo, dataListFile, fileListsService);
      dialog.afterClosed()
        .pipe(take(1))
        .subscribe((val) => {
          if (!val) {
            fileListsService.removeAllItemAndAllCopy(listFiletemp, null);
          }
        });
      this.onDestroy$.subscribe(() => {
        fileListSubscription.unsubscribe();
      });
    }
    return resutl;
  }

  async checkLoadPdfClearCache() {
    const { pdfController } = PdfViewerManager?.currentActiveViewer || {};
    if (!pdfController) return;
    const { fileInfo } = pdfController;
    if (!fileInfo) return;
    const pdfViewer = pdfController.getViewer();
    if (!pdfViewer) return;
    const ext = UtilExtend.getExtensionFile(fileInfo.filename);
    let isCheck = false;
    if (pdfController.textExtents && pdfController.textExtents.includes(ext)) {
      const url = pdfViewer.getParamQuery(fileInfo, ext);
      isCheck = !(await this.streamService.checkPathExist(url));
    } else {
      const pdfQuery = pdfViewer.getParamQuery(fileInfo, 'pdf');
      const pngQuery = pdfViewer.getParamQuery(fileInfo, 'png');
      const fileType = await pdfViewer.checkFileExists(pdfQuery, pngQuery);
      if (fileType === OpenFileType.BLANK) {
        isCheck = true;
      }
    }
    if (isCheck) {
      await pdfController.ShowMessageFileCannotOpened();
    }
    // eslint-disable-next-line consistent-return
    return isCheck;
  }

  setData(value: any) {
    const dataListFile = value.reduce((pre, curr) => {
      if (curr.isBlank === undefined || curr.isBlank !== true) {
        const re: DataFileList = {
          name: curr.filename,
          baseFileId: curr.baseFileId,
          baseMajorRev: curr.baseMajorRev,
          baseMinorRev: curr.baseMinorRev,
          viewId: curr.viewId,
          thumbnail: this.streamService.getUrlImage(curr),
          data: curr,
          markupCount: 0,
        };
        pre.push(re);
      }
      return pre;
    }, []);
    return dataListFile;
  }

  // [18/11/2024][ADV-7694][phuong_td] Check if the layer is allowed to display
  checkShowLayer(fileInfo: FileInfo, layer: LayerData, cadViews: CadViewData[], checkShowAll?: boolean) {
    // eslint-disable-next-line prefer-destructuring
    // [18/11/2024][ADV-7694][phuong_td] If the layer has Frezee, it is not displayed and Print
    if (layer.isFreeze) {
      return {
        isShow: false,
        isPublishPdf: layer.isPublishPdf,
      };
    }
    const cadViewID = this.getCadViewIdActiveByViewId(fileInfo.viewId);
    let Hidden = false;
    const { listViewOff, isShow } = layer;
    if (listViewOff.length) {
      // Check if the layer is hidden
      const cadViewActive = cadViews.find((c) => c.Id === `${cadViewID}` && fileInfo.modelFileId === c.ModelFileId);
      Hidden = !!layer.listViewOff.includes(cadViewActive?.Name);
    }
    if (checkShowAll) {
      return {
        isShow: layer.isOn ? !Hidden && isShow : false,
        isPublishPdf: layer.isPublishPdf,
      };
    }
    return {
      isShow: layer.isOn ? !Hidden : false,
      isPublishPdf: layer.isPublishPdf,
    };
  }

  // [18/11/2024][ADV-7694][phuong_td] Control the show or hide notes by layer
  handleShowHideLayer(fileInfo: FileInfo, activeListLayer: LayerData[], activeListLayerBase?: LayerData[], cadViewAction?: CadViewAction) {
    const modelInfo = this.getModelInfo(fileInfo.viewId);
    if (modelInfo) {
      // Get a cadView list according to idfileidrev
      const cadViews = this.markupsService.fileListsService.getCadViewsData(UtilExtend.getIdFileIdRev(fileInfo));
      const checkShowAll = !cadViewAction || !cadViewAction?.isSelectedFromPanel;
      activeListLayer.forEach((layer) => {
        // [19/11/2024][ADV-7694][phuong_td] Examining additional layers is allowed to print
        const result = this.checkShowLayer(fileInfo, layer, cadViews, checkShowAll);
        if (!cadViewAction || !cadViewAction?.isSelectedFromPanel) {
          layer.isShow = result.isShow;
          layer.isPublishPdf = result.isPublishPdf;
        }
        // layer.isShow = result.isShow;
        // layer.isPublishPdf = result.isPublishPdf;
        // layer.isPublishPdfAll = result.isPublishPdf;
        modelInfo.contextMenuService.showOrHide(!result.isShow, layer.nodeChildrenIds, false, true);
      });
    }
    if (!cadViewAction || !cadViewAction?.isSelectedFromPanel) {
      this.setMapLayerDataWithView(fileInfo.viewId, activeListLayer);
    }
    // this.setMapLayerDataWithView(fileInfo.baseFileId, activeListLayer);
  }

  // [18/11/2024][ADV-7694][phuong_td] Take cadViewActive according to ViewAtive
  getCadViewIdActiveByViewId(viewId: string) {
    let cadViewData: CadViewAction = null;
    const CadViewDataWithView = this.getCadViewIDWithView(viewId);
    if (!cadViewData) {
      cadViewData = CadViewDataWithView.value;
    }
    // [22/11/2024][ADV-7893][phuong_td] checknull cadViewData
    return cadViewData?.cadViewId ?? '';
  }

  // John Lee _ 11042024 _ reset layer in active sheet when reset model
  resetModelLayer(fileInfo: FileInfo) {
    const initEnum = Util.INIT_LAYER + fileInfo.baseFileId;
    const listLayer = this.mapLayerDataWithView?.get(initEnum);
    if (listLayer && listLayer.length > 0) {
      const initBaseLayer = Util.clone(listLayer);
      const activeListLayer = this.mapLayerDataWithView.get(fileInfo.viewId);
      if (activeListLayer && activeListLayer.length > 0) {
        activeListLayer.map((v) => {
          const layer = initBaseLayer?.find((l) => l.name === v.name);
          if (layer) {
            v.isShow = layer.isShow;
            v.isPublishPdf = layer.isPublishPdf;
          }
          return v;
        });
        // [22/11/2024][ADV-7893][phuong_td] Divide the case to perform resetmodel for DGN and other files
        const ext = UtilExtend.getExtensionFile(fileInfo.originalFile);
        switch (ext) {
          case 'dgn':
            this.handleShowHideLayer(fileInfo, activeListLayer);
            break;
          default: {
            this.setMapLayerDataWithView(fileInfo.viewId, activeListLayer);
            const modelInfo = this.getModelInfo(fileInfo.viewId);
            // [15/11/2024][ADV-7694][phuong_td] checknull modelInfo
            if (modelInfo) {
              activeListLayer.forEach((layer) => {
                modelInfo.contextMenuService.showOrHide(!layer.isShow, layer.nodeChildrenIds, false, true);
              });
            }
          }
        }
      }
    }
  }

  // [15/11/2024][ADV-7694][phuong_td] update the status of nodes according to the current layer information
  ApplyLayerStateCadView(fileInfo: FileInfo, cadViewAction?: CadViewAction) {
    const activeListLayer = this.mapLayerDataWithView.get(fileInfo.viewId);
    const activeListLayerBase = this.mapLayerDataWithView.get(fileInfo.baseFileId);
    if (activeListLayer && activeListLayer.length > 0 && this.isActiveDocumentView) {
      this.handleShowHideLayer(fileInfo, activeListLayer, activeListLayerBase, cadViewAction);
      this.isActiveDocumentView = false;
    }
  }

  // [15/11/2024][ADV-7694][phuong_td] Restore the status of nodes according to the current layer information
  RestoreLayerState(fileInfo: FileInfo) {
    const activeListLayer = this.mapLayerDataWithView.get(fileInfo.viewId);
    const activeListLayerBase = this.mapLayerDataWithView.get(fileInfo.baseFileId);
    if (activeListLayer && activeListLayer.length > 0) {
      const modelInfo = this.getModelInfo(fileInfo.viewId);
      if (modelInfo) {
        // Get a cadView list according to idfileidrev
        activeListLayer.forEach((layer) => {
          modelInfo.contextMenuService.showOrHide(!layer.isShow, layer.nodeChildrenIds, false, true);
        });
      }
      this.setMapLayerDataWithView(fileInfo.viewId, activeListLayer);
    }
  }
}
