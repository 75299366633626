import {
  Component, OnInit, Inject, OnDestroy, HostListener,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription, fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KeyCode } from 'src/app/core/enums/keycode';
import { UtilExtend } from 'src/app/core/utils/util-extend';

export enum DialogResult{
  Yes= 1,
  No = 2,
  Cancel = 0

}
interface dataDialogYesNoCancel {
  title: string;
  notification?:string
  message: string;
  Status?: number;
  hasCloseIcon?: boolean,
  yesBtnContent: string,
  noBtnContent: string
}
@Component({
  selector: 'app-dialog-yes-no-cancel',
  templateUrl: './dialog-yes-no-cancel.component.html',
  styleUrls: ['./dialog-yes-no-cancel.component.scss'],
})
export class DialogYesNoCancelComponent implements OnInit, OnDestroy {
  public Status: DialogResult = DialogResult.Cancel;

  subscription: Subscription;

  private onDestroy$ = new Subject();

  constructor(
    public dialogRef: MatDialogRef<DialogYesNoCancelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: dataDialogYesNoCancel,
  ) {
    switch (data.Status) {
      case 0:
        this.Status = DialogResult.Cancel;
        break;
      case 1:
        this.Status = DialogResult.Yes;
        break;
      case 2:
        this.Status = DialogResult.No;
        break;
      default:
        this.Status = DialogResult.Cancel;
    }
  }

  ngOnInit() {
    this.subscription = fromEvent(document, 'keyup').subscribe((event: KeyboardEvent) => {
      if (event.keyCode === 27 || event.key === 'Escape') {
        event.stopPropagation();
        this.subscription.unsubscribe();
      } else if (event.keyCode === 13 || event.key === 'Enter') {
        event.stopPropagation();
        this.subscription.unsubscribe();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    UtilExtend.onDestroy(this.onDestroy$);
  }

  @HostListener('keydown', ['$event'])
  keydown(event) {
    if (event.key === KeyCode.escape) {
      this.dialogRef.close();
    }
  }
}
