import { MarkupType, AuthorInfo, ContextMenuItem } from 'src/app/shared/class/global';
import { Identity } from 'src/app/core/common/identity';
import {
  ViewType, FileIdRev, UserInfo,
} from './main-viewer-common';

export interface RedlineMarkupBase {
  type: MarkupType;
}
export interface IExtEntity {
  extModelFileId?: string; // using fileName bo sung cho ModelFileId
  extBaseFileId?: string;
  extBaseMajorRev?: number;
  extBaseMinorRev?: number;
}

export interface MarkupEntity extends IExtEntity {
  _id?: string; // id from db
  uniqueId: string;
  title?: string;
  status: MarkupStatus;
  uniqueViewId: string; // Markup view ID in Hoops, Foxit ???
  type: MarkupType;
  createdDate: number;
  modifiedDate?: number;
  createdBy: UserInfo;
  modifiedBy?: UserInfo[];
  uniqueGroupId: string;
  originData?: any;
  modeMarkup: MarkupMode;
  ModelFileId?: string;
  configurationIndex?: number; // Dùng đề phân biệt markup của configurations
  baseFileId?: string;
  baseMajorRev?: number;
  baseMinorRev?: number;
  isHide?: boolean; // using tem
  originId?: string; // Dùng để lưu Id gốc vì khi load Measure thì uniqueId bị thay đổi chưa giữ được
  readonly?: boolean;
}

export enum MarkupMode {
  Mode2d = 0,
  Mode3d,
}
export enum MarkupEntityMenuData {
  Add = 1,
  Remove,
  Edit,
  MoveUp,
  MoveDown,
  Hide,
  Show,
  ShowAll,
  HideAll,
  Delete,
  ZoomSelected,
  ZoomGroup,
  RotateTextbox = 'Rotate Text Box',
  UnRotateTextbox = 'Cancel Rotate Text Box',
  Copy = 'Copy',
  Paste = 'Paste',
  Attachment = 'Attachment',
  ShowXYZ = 'Show XYZ',
  HideXYZ = 'Hide XYZ',
}

export enum MarkupGroupMenuData {
  ZoomGroup = 1,
  Edit,
  Delete
}

export interface IMarkupEntityMenu {
  name: string;
  display: string;
  value: MarkupEntityMenuData;
  isDisable: boolean;
  SubMenu?: ContextMenuItem<any>[];
}

export interface IMarkupDataTranfer {
  position: { x: number; y: number };
  data: MarkupEntity[];
  isAddGroup?: boolean;
  isInGroup?: boolean;
  markupId?: string; // Id of markup is hide
}
export interface IGroupDataTranfer {
  position: { x: number; y: number };
  data?: IMarkupGroup;
}
export enum MarkupStatus {
  Open = 1,
  Approved,
  Rejected,
  Archived,
}

export enum MarkupAttachType {
  None = 1,
  Face = 2,
  Vertex = 3,
  Edge = 4,
}

export enum MarkupFillType {
  None = 1,
  Opaque = 2,
}

export interface IMarkupGroup extends IExtEntity {
  _id?: string; // id from db
  uniqueId: string;
  title: string;
  createdDate: number;
  modifiedDate: number;
  createdBy: UserInfo;
  description: string;
  comment?: any[];
  status: MarkupStatus;
  tags?: Tag[];
  ModelFileId?: string;
  baseFileId?: string;
  baseMajorRev?: number;
  baseMinorRev?: number;
  isVisible: boolean;
  readonly?: boolean;
}

export interface iDataMarkupGroup {
  ModelFileId: string;
  fileName: string;
  page?: number;
  sheetId?: number;
  listMarkup: MarkupEntity[];
  isSelectAll?: boolean;
  isExtend?: boolean;
  isHideAll?: boolean;

  isShow?: boolean;
  configurationIndex?: number; // Dùng đề phân biệt markup của configurations.
  toolTip?: string;
}

export interface ResponseMarkups {
  markupEntities: MarkupEntity[];
  listGroups: IMarkupGroup[];
  markupViews: MarkupView[];
  modelViews: MarkupView[];
  fileIdRev?: FileIdRev;
}

export interface MarkupView extends IExtEntity{
  uniqueId?: string;
  viewType: ViewType;
  ModelFileId?: string;
  markup?: any;
}

export interface ViewerInfo { // modelView
  _id?: string;
  viewType: ViewType;
  ModelFileId?: string;
  extModelFileId?: string; // fileName or sheetId
  minPoint?: any; // bounding root node
  maxPoint?: any;
  camera?: any; // camera root node
  minPointMarkup?: any; // bounding root node & markup
  maxPointMarkup?: any;
  cameraMarkup?: any; // camera root node & markup
  scaleUnit?: number; // model unit
  measureUnitModel?: number; // file unit
}

/** Tag */
export interface TagColor {
  color: string;
  backgroundColor: string;
  borderColor: string;
}
export enum TagColor1 {
  color = '#FAAD14',
  backgroundColor = '#FFF7E6',
  borderColor = '#FFD591',
}
export enum TagColor2 {
  color = '#13C2C2',
  backgroundColor = '#E6FFFB',
  borderColor = '#87E8DE',
}
export enum TagColor3 {
  color = '#722ED1',
  backgroundColor = '#F9F0FF',
  borderColor = '#D3ADF7',
}

export interface Tag {
  name: string;
  color: TagColor;
}

export enum ModeFilterMarkup {
  Entities = 1,
  Groups,
  None
}

export enum DateFilterType{
  dateModified = 1,
  dateCreated = 2,
}

export interface ResultFilterMarkup {
  author: UserInfo;
  dateFilterType: DateFilterType;
  dateRange: { startDate: number, endDate: number };
  mode: ModeFilterMarkup;
  isReverse?: boolean;
}

export interface DataSendFilterDialog {
  authors: UserInfo[];
  previousFilter: ResultFilterMarkup;
  top: number;
  left: number;
  mode: ModeFilterMarkup;
}

export enum PriorityIssue {
  Low = 1,
  Normal,
  High,
  Urgent
}
export interface MarkupGroupIssue {
  uniqueGroupId?: string;
  priority?: PriorityIssue;
  assignedTo?: Identity;
  dueDate?: number;
}
export interface IFormatNotePinSetting {
  fonttext?: string;
  fontColor?: Communicator.Color;
  fontSize?: number;
}
export enum NoteTextElementID {
  buttonSetColor = 'setColorPin',
  buttonTrash = 'trash_button',
  TextArea = 'noteTextArea',
}

export interface iRightPanelStatus {
  status?: boolean;
  markupSelected?: any;
}
export function isMarkupEntity(input: any): input is MarkupEntity {
  const temp = input as MarkupEntity;
  if (temp.modeMarkup !== undefined && temp.originData !== undefined) {
    return true;
  }
  return false;
}

export function isType<T, K extends keyof T>(input: any, ...keys: K[]): input is T {
  if (!input || !Array.isArray(keys)) {
    return false;
  }
  const implementKeys = keys.reduce((impl, key) => impl && key in input, true);
  return implementKeys;
}

export type TypeExtend = 'Fit' | 'Both' | 'Right' | 'Left' | 'Top' | 'Bottom';

export interface PointScale {
  index?: number;
  position?: number;
  point: Communicator.Point3;
  scale: number;
}
