import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { NotifiedData, StatusNotify } from 'src/app/core/common/global';

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss'],
})
export class NotifyComponent implements OnInit {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: NotifiedData,
  ) { }

  ngOnInit(): void {
    this.notifiedData = this.data ?? this.defaultNotify;
  }

  private readonly defaultNotify: NotifiedData = {
    message: 'Something went wrong !',
    status: StatusNotify.warning,
  };

  public notifiedData: NotifiedData;
}
